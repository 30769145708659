$modal-backdrop__background-color: rgba(0, 0, 0, 0.4) !default;
$modal__template-areas:
  "disclaimer disclaimer disclaimer disclaimer disclaimer"
  "logo title title title title"
  "logo updated updated updated updated"
  "logo expired expired expired expired"
  "code-wrap code-wrap code-wrap code-wrap code-wrap"
  "shop-link shop-link shop-link shop-link shop-link"
  "desc desc desc desc desc"
  "toggle toggle . . ." !default;
$modal__template-areas--mobile:
  "disclaimer"
  "logo"
  "title"
  "updated"
  "expired"
  "code-wrap"
  "shop-link"
  "desc"
  "toggle" !default;
$modal-link__icon: $icon-arrow-green !default;
$modal-link__icon--hover: $icon-arrow-pink !default;
$modal-copy-btn__icon--copied: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='13' fill='none'%3E%3Cpath fill='%23000  ' d='M7.36 0a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13Zm0 12.2c-3.137 0-5.688-2.564-5.688-5.7A5.694 5.694 0 0 1 7.359.812 5.694 5.694 0 0 1 13.047 6.5c0 3.136-2.552 5.7-5.688 5.7Zm2.593-8.078L6.14 7.959 4.422 6.242a.406.406 0 0 0-.574.574l2.01 2.011a.407.407 0 0 0 .623-.059l4.047-4.072a.407.407 0 0 0-.575-.574Z'/%3E%3C/svg%3E") !default;
$modal__template-columns: 90px repeat(4, 1fr) !default;
$modal__template-columns--mobile: auto !default;
$modal__padding: 40px 70px !default;
$modal__padding--mobile: 40px 15px !default;
$modal__background: $color-white !default;
$modal-close-btn__bg-color: unset !default;
$modal-close-btn__top: 24px !default;
$modal-close-btn__right: 24px !default;
$modal-close-btn__padding: 0 !default;
$modal-close-btn__color: $color-black !default;
$modal-close-btn__font-size: 10px !default;
$modal-logo__bg: transparent !default;
$modal-logo__margin: 0 0 10px !default;
$modal-logo__margin--mobile: 0 0 5px !default;
$modal-logo__border: none !default;
$modal-logo__esdo--color: $color-storm-blue !default;
$modal-end-time__font-size: 11px !default;
$modal-updated__font-size: 14px !default;
$modal-updated__color: $color-black !default;
$modal-title__margin: 16px 10px 5px !default;
$modal-title__margin--mobile: 0 0 18px !default;
$modal-wrap__margin: 0 !default;
$modal-wrap__margin--mobile: 0 !default;
$modal-wrap__padding: 0 !default;
$modal-wrap__padding--mobile: 0 !default;
$modal-wrap__bg-color: transparent !default;
$modal-code__margin: 0 !default;
$modal-code__padding: 0 !default;
$modal-code__bg-color: transparent !default;
$modal-code__color: $color-black !default;
$modal-code__font-size: 16px !default;
$modal-code__line-height: 19px !default;
$modal-code__border: none !default;
$modal-code__border-radius: 0 !default;
$modal-code-empty__color: $color-black !default;
$modal-code-empty__font-size: 16px !default;
$modal-code-empty__bg-color: $color-white !default;
$modal-code-empty__text-transform: uppercase !default;
$modal-copy-btn__padding: 19px 26px 17px !default;
$modal-copy-btn__bg-color: $color-su-bright-green !default;
$modal-copy-btn__bg-color--hover: $color-su-pink !default;
$modal-copy-btn__color: $color-white !default;
$modal-copy-btn__font-weight: 700 !default;
$modal-copy-btn__font-size: 16px !default;
$modal-copy-btn__font-size--mobile: 16px !default;
$modal-copy-btn__text-transform: uppercase !default;
$modal-copy-btn__border-radius: 8px !default;
$modal-copy-text__color: #666 !default;
$modal-copy-text__font-weight: 400 !default;
$modal-copy-text__font-size: 14px !default;
$modal-link-wrp__width: auto !default;
$modal-link-wrp__height: auto !default;
$modal-link-wrp__margin: 10px 0 !default;
$modal-link-wrp__margin--mobile: 20px auto !default;
$modal-link-wrp__padding: 0 !default;
$modal-link-wrp__padding--mobile: 0 !default;
$modal-link-wrp__background-color: transparent !default;
$modal-link__padding: 8px 0 7px !default;
$modal-link__padding--mobile: $modal-link__padding;
$modal-link__color: $color-black !default;
$modal-link__color--hover: $color-su-saturated-green !default;
$modal-link__text-decoration: none !default;
$modal-link__text-decoration--hover: underline !default;
$modal-link__font-size: 14px !default;
$modal-link__font-size--mobile: 14px !default;
$modal-link__font-weight: 700 !default;
$modal-link__text-transform: capitalize !default;
$modal-link__border-radius: 0 !default;
$modal-link__border-bottom: none !default;
$modal-link__margin: 0 !default;
$modal-toggle__width: fit-content !default;
$modal-toggle__margin: 0 !default;
$modal-toggle__margin--mobile: 10px 0 0 !default;
$modal-toggle__padding: 0 !default;
$modal-toggle__padding--mobile: 0 !default;
$modal-toggle__color: $color-black !default;
$modal-toggle__font-size: 14px !default;
$modal-toggle__bg-color: unset !default;
$modal-description__margin: 10px 0 !default;
$modal-description__margin--mobile: 0 !default;
$modal-description__padding: 0 !default;
$modal-description__padding--mobile: 0 !default;
$modal-description__color: $color-black !default;
$modal-description__font-size: 14px !default;
$modal-table__font-size: 14px !default;
$modal-table__font-size--mobile: 14px !default;
$modal-table-key__text-transform: capitalize !default;
$modal-table__grid-template-columns: 2fr 4fr !default;
$modal-table__grid-template-columns--mobile: auto 1fr !default;
$modal-vote__display: flex !default;
$modal-vote__margin: 0 -24px !default;
$modal-vote__margin--mobile: 0 -16px !default;
$modal-vote__padding: 10px !default;
$modal-vote__bg-color: $color-white !default;
$modal-vote-title__margin: 0 0 10px !default;
$modal-vote-title__color: $color-black !default;
$modal-vote-title__font-weight: 600 !default;
$modal-vote-title__font-size: 16px !default;
$modal-vote-title__line-height: 19px !default;
$modal-vote-title-link__color: $color-black !default;
$modal-vote-title-link__text-decoration: underline !default;
$modal-vote-btn__font-size: 0 !default;
$modal-vote-btn__bg-color: transparent !default;
$modal-vote-btn__border: none !default;
$modal-vote-btn-icon__width: 36px !default;
$modal-vote-btn-icon__height: 34px !default;
$modal-vote-btn-icon__margin: 0 0 0 3px !default;
$modal-vote-btn-icon__font-size: 28px !default;
$modal-vote-btn-icon-positive__content: "\e90d" !default;
$modal-vote-btn-icon-negative__content: "\e90c" !default;
$modal-esdo-input__border: 0.5px solid $color-dark-grey !default;
$modal-esdo-wrp__width: 70% !default;
$modal-esdo-wrp__width--mobile: 85% !default;
$modal-esdo-wrp__margin: 0 auto !default;
$modal-esdo-email__font-size: 14px !default;
$modal-esdo-btn__margin: 20px 0 0 !default;
$modal-esdo-btn__color: inherit !default;
$modal-esdo-btn__background-color: transparent !default;
$modal-esdo-btn__font-size: inherit !default;
$modal-esdo-btn__border-radius: unset !default;
$modal-esdo-btn__padding: 0 !default;
$modal-esdo-btn__text-align: left !default;
$modal-esdo-message__font-size: 14px !default;
$modal-esdo-desc__font-size: 14px !default;
$modal-esdo-checkout__font-size: 14px !default;
$modal-esdo-sent-code__font-size: 14px !default;
$modal-esdo-text__font-size: 12px !default;

#my-modal {
  .modal__dialog {
    max-height: 88%;
    overflow-y: auto;

    @media #{$md} {
      margin: 120px 8px;
    }
  }

  .modal__close-btn {
    position: absolute;
    top: $modal-close-btn__top;
    right: $modal-close-btn__right;
    width: 32px;
    height: 32px;
    padding: $modal-close-btn__padding;
    color: $modal-close-btn__color;
    background-color: $modal-close-btn__bg-color;
    border: 1px solid $color-black;
    border-radius: 99px;

    i::before {
      content: $icon-cross--black;
    }

    &:hover {
      color: $color-white;
      text-decoration: none;
      background-color: $color-su-bright-green;
      border-color: $color-su-bright-green;

      i::before {
        filter: invert(1);
      }
    }
  }
}

.modal-clickout {
  $this: &;

  display: grid;
  grid-template-areas: $modal__template-areas;
  grid-template-columns: $modal__template-columns;
  padding: $modal__padding;
  background: $modal__background;

  @media #{$md} {
    grid-template-areas: $modal__template-areas--mobile;
    grid-template-columns: $modal__template-columns--mobile;
    padding: $modal__padding--mobile;
  }

  &__expired-disclaimer-wrap {
    grid-area: disclaimer;
    height: 30px;
  }

  &__expired-disclaimer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    background-color: #FFF9E9;
    border-bottom: 1px solid #ECECEC;

    &::before {
      min-width: 22px;
      height: 22px;
      margin: 0 13px 0 5px;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='22' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 .1C4.98.1.1 4.98.1 11c0 6.02 4.88 10.9 10.9 10.9 6.02 0 10.9-4.88 10.9-10.9C21.9 4.98 17.02.1 11 .1ZM10.068 5h1.815v1.93h-1.815V5Zm3.004 11.716h-1.997c-.775 0-1.106-.33-1.106-1.122v-5.149c0-.248-.132-.362-.363-.362h-.66V8.3h1.997c.776 0 1.106.346 1.106 1.122v5.165c0 .233.132.363.363.363h.66v1.767Z' fill='%23FFC12E'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      content: " ";
    }
  }

  &__info {
    display: flex;
    padding: 24px;
    padding-top: 45px;
  }

  &__logo {
    grid-area: logo;
    justify-self: center;
    max-width: 110px;
    height: min-content;
    margin: $modal-logo__margin;
    background-color: $modal-logo__bg;
    border: $modal-logo__border;

    @media #{$md} {
      margin: $modal-logo__margin--mobile;
    }

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      max-width: 80px;
      height: auto;

      @media #{$md} {
        width: 100px;
        max-width: 100px;
      }
    }
  }

  &__end-time {
    grid-area: expired;
    font-size: $modal-end-time__font-size;

    .icon-clock {
      margin-right: 3px;
    }
  }

  &__updated {
    grid-area: updated;
    margin-left: 10px;
    color: $modal-updated__color;
    font-size: $modal-updated__font-size;
    line-height: 24px;
  }

  &__code-wrp {
    position: relative;
    grid-area: code-wrap;
    margin: $modal-wrap__margin;
    padding: $modal-wrap__padding;
    text-align: center;
    background-color: $modal-wrap__bg-color;

    @media #{$md} {
      margin: $modal-wrap__margin--mobile;
      padding: $modal-wrap__padding--mobile;
    }

    &:not(.modal-clickout__code-wrp--no-code) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 8px;

      @media #{$md} {
        grid-template-rows: 1fr 1fr auto;
        grid-template-columns: 1fr;
        gap: 0;
      }

      &::after {
        position: absolute;
        top: 0;
        z-index: 0;
        display: block;
        width: 100%;
        max-width: 368px;
        height: 58px;
        background-color: $color-ivory-mist;
        border: 1px solid $color-black;
        border-radius: 8px;
        content: "";

        @media #{$md} {
          max-width: none;
        }
      }
    }
  }

  &__code {
    display: block;
    margin: $modal-code__margin;
    padding: $modal-code__padding;
    color: $modal-code__color;
    font-weight: 700;
    font-size: $modal-code__font-size;
    line-height: $modal-code__line-height;
    background-color: $modal-code__bg-color;
    border: $modal-code__border;
    border-radius: $modal-code__border-radius;

    &:not(.modal-clickout__code--empty) {
      position: relative;
      top: 19px;
      z-index: 1;
      display: -webkit-box;
      max-height: 22px;
      -webkit-line-clamp: 1;
      overflow: hidden;
      line-height: 22px;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }

    &--empty {
      padding: 18px 0;
      color: $modal-code-empty__color;
      text-transform: $modal-code-empty__text-transform;
      background-color: $modal-code-empty__bg-color;
      border-color: $color-black;
      border-style: solid;
      border-width: 1px 0;
    }

    &--copied {
      color: #00000080;
    }
  }

  [data-coupon-code-blur] {
    filter: blur(3px);

    &::after {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      content: "";
    }
  }

  &__copy {
    display: block;
    width: 100%;
    max-height: 58px;
    white-space: nowrap;
    text-transform: $modal-copy-btn__text-transform;
    border-radius: $modal-copy-btn__border-radius;

    @include btn(
      $padding: $modal-copy-btn__padding,
      $color: $modal-copy-btn__color,
      $font-weight: $modal-copy-btn__font-weight,
      $font-size: $modal-copy-btn__font-size,
      $line-height: 22px,
      $background: $modal-copy-btn__bg-color,
      $background--hover: $color-su-pink,
      $border--hover: 1px solid $color-black
    );

    @media #{$md} {
      margin-top: 9px;
      font-size: $modal-copy-btn__font-size--mobile;
    }

    &--copied {
      color: $color-black;
      background-color: $color-su-pink;
      border-color: $color-su-pink;

      .copied-text {
        &::after {
          content: $modal-copy-btn__icon--copied;
        }
      }
    }

    &-text {
      grid-area: 2 / 1 / 3 / 3;
      margin: 16px 0 0;
      color: $modal-copy-text__color;
      font-weight: $modal-copy-text__font-weight;
      font-size: $modal-copy-text__font-size;
      text-align: center;

      @media #{$md} {
        grid-area: 3 / 1 / 4 / 2;
        margin-top: 5px;
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    grid-area: shop-link;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 100%;
    padding: $modal-link__padding;
    color: $modal-link__color;
    font-weight: $modal-link__font-weight;
    font-size: $modal-link__font-size;
    text-align: center;
    text-transform: $modal-link__text-transform;

    &::before {
      position: relative;
      z-index: 1;
      width: 15px;
      height: 15px;
      margin-right: 9px;
      background-repeat: no-repeat;
      content: $modal-link__icon;
    }

    &:hover {
      color: $modal-link__color--hover;
      text-decoration: $modal-link__text-decoration--hover;

      &::before {
        content: $icon-arrow-pink;
      }
    }

    @media #{$md} {
      padding: $modal-link__padding--mobile;
      font-size: $modal-link__font-size--mobile;
    }
  }

  &__link-wrp {
    display: flex;
    grid-area: shop-link;
    justify-content: center;
    width: $modal-link-wrp__width;
    height: $modal-link-wrp__height;
    margin: $modal-link-wrp__margin;
    background-color: $modal-link-wrp__background-color;
  }

  &__link-wrp--code {
    grid-area: shop-link;
    width: auto;
    height: auto;
    margin: $modal-link-wrp__margin;
    padding: $modal-link-wrp__padding;
    border-bottom: none;

    @media #{$md} {
      margin: $modal-link-wrp__margin--mobile;
      padding: $modal-link-wrp__padding--mobile;
    }
  }

  &__vote {
    display: $modal-vote__display;
    flex-wrap: wrap;
    grid-area: vote;
    justify-content: center;
    margin: $modal-vote__margin;
    padding: $modal-vote__padding;
    text-align: center;
    background-color: $modal-vote__bg-color;

    @media #{$md} {
      margin: $modal-vote__margin--mobile;
    }

    &-title {
      width: 100%;
      margin: $modal-vote-title__margin;
      color: $modal-vote-title__color;
      font-weight: $modal-vote-title__font-weight;
      font-size: $modal-vote-title__font-size;
      line-height: $modal-vote-title__line-height;

      a {
        color: $modal-vote-title-link__color;
        text-decoration: $modal-vote-title-link__text-decoration;
      }
    }

    &-positive,
    &-negative {
      font-size: $modal-vote-btn__font-size;
      background-color: $modal-vote-btn__bg-color;
      border: $modal-vote-btn__border;

      &::before {
        display: inline-block;
        width: $modal-vote-btn-icon__width;
        height: $modal-vote-btn-icon__height;
        margin: $modal-vote-btn-icon__margin;
        font-size: $modal-vote-btn-icon__font-size;
        font-family: simple, serif;
        background-repeat: no-repeat;
      }
    }

    &-positive {
      &::before {
        content: $modal-vote-btn-icon-positive__content;
      }
    }

    &-negative {
      &::before {
        content: $modal-vote-btn-icon-negative__content;
      }
    }
  }

  .coupon {
    &__title {
      grid-area: title;
      margin: $modal-title__margin;
      text-align: initial;

      @media #{$md} {
        margin: $modal-title__margin--mobile;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
      }
    }

    &__description {
      display: -webkit-box;
      margin: $modal-description__margin;
      padding: $modal-description__padding;
      color: $modal-description__color;
      font-size: $modal-description__font-size;
      background-color: transparent;

      @media #{$md} {
        margin: $modal-description__margin--mobile;
        padding: $modal-description__padding--mobile;
      }

      &--full {
        display: block;
      }
    }

    &__table {
      margin: 0;

      .key {
        &::before {
          display: none;
        }
      }
    }

    &__see-details {
      grid-area: toggle;
      justify-self: flex-start;
      width: $modal-toggle__width;
      margin: $modal-toggle__margin;
      padding: $modal-toggle__padding;
      color: $modal-toggle__color;
      font-size: $modal-toggle__font-size;
      background-color: $modal-toggle__bg-color;

      @media #{$md} {
        margin: $modal-toggle__margin--mobile;
        padding: $modal-toggle__padding--mobile;
      }
    }

    &__table {
      grid-template-columns: $modal-table__grid-template-columns;
      font-size: $modal-table__font-size;

      @media #{$md} {
        grid-template-columns: $modal-table__grid-template-columns--mobile;
        font-size: $modal-table__font-size--mobile;
      }

      @media #{$sm} {
        column-gap: 16px;
      }

      .key {
        position: relative;
        text-transform: $modal-table-key__text-transform;

        &::before {
          position: absolute;
          content: "";
        }
      }
    }
  }

  .esdo-form {
    &__wrp {
      grid-area: code-wrap;
      width: $modal-esdo-wrp__width;
      margin: $modal-esdo-wrp__margin;

      @media #{$md} {
        width: $modal-esdo-wrp__width--mobile;
      }
    }

    &__email {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: $modal-esdo-email__font-size;

      input {
        width: 100%;
        height: 35px;
        padding: 0 10px;
        border: $modal-esdo-input__border;
        border-radius: 2px;

        ::placeholder {
          font-weight: 600;
        }
      }
    }

    &__btn {
      display: block;
      margin: $modal-esdo-btn__margin;
      padding: $modal-esdo-btn__padding;
      color: $modal-esdo-btn__color;
      font-weight: 500;
      font-size: $modal-esdo-btn__font-size;
      text-align: $modal-esdo-btn__text-align;
      text-transform: uppercase;
      background-color: $modal-esdo-btn__background-color;
      border-radius: $modal-esdo-btn__border-radius;

      &.disabled {
        cursor: default;
        opacity: 0.7;
        pointer-events: none;
      }
    }

    &__message {
      display: block;
      font-weight: 400;
      font-size: $modal-esdo-message__font-size;
      text-align: center;

      &-incorrect,
      &-empty {
        display: none;

        &.show {
          display: block;
        }
      }
    }

    &__description {
      margin: 10px 0 20px;
      font-weight: 400;
      font-size: $modal-esdo-desc__font-size;
      text-align: center;
    }

    &__checkbox {
      position: relative;
      margin-top: 20px;
      font-weight: 400;
      font-size: $modal-esdo-checkout__font-size;

      &-input {
        position: absolute;
        opacity: 0;

        & + label {
          position: relative;
          display: inline-block;
          padding: 0;
          padding-left: 20px;
          font-weight: 400;
          cursor: pointer;
        }

        & + label::before {
          position: absolute;
          left: 0;
          width: 14px;
          height: 14px;
          margin-top: 3px;
          vertical-align: text-top;
          border: 1px solid #132D56;
          border-radius: 2px;
          content: "";
        }

        &:focus + label::before {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        }

        &:checked + label::after {
          position: absolute;
          top: 9px;
          left: 2px;
          width: 2px;
          height: 2px;
          background: $modal-logo__esdo--color;
          box-shadow:
            2px 0 0 $modal-logo__esdo--color,
            4px 0 0 $modal-logo__esdo--color,
            4px -2px 0 $modal-logo__esdo--color,
            4px -4px 0 $modal-logo__esdo--color,
            4px -6px 0 $modal-logo__esdo--color,
            4px -8px 0 $modal-logo__esdo--color;
          transform: rotate(45deg);
          content: "";
        }
      }
    }
  }

  .sent-code-message {
    display: none;
    font-weight: 400;
    font-size: $modal-esdo-sent-code__font-size;
    text-align: center;

    &.show {
      display: block;
    }
  }

  .esdo-text-remember {
    margin: 30px 0 10px;
    font-weight: normal;
    font-size: $modal-esdo-text__font-size;
    line-height: 15px;
    letter-spacing: 0.5em;
    text-align: center;
    text-transform: uppercase;
    opacity: 0.4;
  }
}
