$filter-by-category-header__border-bottom: 1px solid $color-light-grey-2 !default;
$filter-by-category-title__margin: 0 !default;
$filter-by-category-title__color: $color-wet-charcoal !default;
$filter-by-category-title__font-weight: lighter !default;
$filter-by-category-title__font-size: 20px !default;
$filter-by-category-content__padding: 25px 25px 0 !default;
$filter-by-category-p__color: $color-wet-charcoal !default;
$filter-by-category-p__font-weight: lighter !default;
$filter-by-category-p__font-size: 14px !default;
$filter-by-category-li__margin-bottom: 15px !default;
$filter-by-category-a__height: 39px !default;
$filter-by-category-a__padding: 9px 8px !default;
$filter-by-category-a__color: $color-blue !default;
$filter-by-category-a__font-weight: lighter !default;
$filter-by-category-a__font-size: 14px !default;
$filter-by-category-a__text-align: center !default;
$filter-by-category-a__is-uppercase: true !default;
$filter-by-category-a__border: 1px solid $color-blue !default;
$filter-by-category-a__transition: background-color 0.3s ease, color 0.3s ease !default;
$filter-by-category-a-hover__color: $color-white !default;
$filter-by-category-a-hover__text-decoration: none !default;
$filter-by-category-a-hover__background-color: $color-blue !default;

.filter-by-category {
  &__header {
    border-bottom: $filter-by-category-header__border-bottom;
  }

  &__title {
    margin: $filter-by-category-title__margin;
    color: $filter-by-category-title__color;
    font-weight: $filter-by-category-title__font-weight;
    font-size: $filter-by-category-title__font-size;
  }

  &__content {
    padding: $filter-by-category-content__padding;

    p {
      color: $filter-by-category-p__color;
      font-weight: $filter-by-category-p__font-weight;
      font-size: $filter-by-category-p__font-size;
    }

    ul {
      padding-left: 0;
      list-style: none;

      li {
        margin-bottom: $filter-by-category-li__margin-bottom;
      }

      a {
        display: block;
        height: $filter-by-category-a__height;
        padding: $filter-by-category-a__padding;
        color: $filter-by-category-a__color;
        font-weight: $filter-by-category-a__font-weight;
        font-size: $filter-by-category-a__font-size;
        text-align: $filter-by-category-a__text-align;

        @if $filter-by-category-a__is-uppercase {
          text-transform: uppercase;
        }

        border: $filter-by-category-a__border;
        transition: $filter-by-category-a__transition;

        &:focus,
        &:hover {
          color: $filter-by-category-a-hover__color;
          text-decoration: $filter-by-category-a-hover__text-decoration;
          background-color: $filter-by-category-a-hover__background-color;
        }
      }
    }
  }
}
