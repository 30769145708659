$coupon__grid-template:
  "labels labels . btn" 40px
  "logo title title btn" auto
  "toggle . . btn" 23px
  "desc desc desc desc" auto / 150px 1fr 1fr 227px !default;
$coupon__grid-template--mobile:
  "labels labels" 44px
  "logo title" 80px
  "toggle btn" 36px
  "desc desc" auto / 102px auto !default;
$coupon__grid-template--large:
  "labels labels . btn" 48px
  "logo title title btn" auto
  "toggle . . btn" 23px
  "desc desc desc desc" auto / 180px 1fr 1fr 298px !default;
$coupon__grid-template--large--mobile:
  "labels labels" 44px
  "logo title" 80px
  "toggle btn" 36px
  "desc desc" auto /  102px auto !default;
$coupon__padding: 0 !default;
$coupon__padding--mobile: $coupon__padding !default;
$coupon__padding--large-coupon: $coupon__padding !default;
$coupon__padding--large-coupon--mobile: $coupon__padding !default;
$coupon__margin: 0 0 15px !default;
$coupon__bg-color: $color-white !default;
$coupon__bg-color--large-coupon: $color-white !default;
$coupon__border--large-coupon: none !default;
$coupon-logo__align-items: center !default;
$coupon-logo__justify-content: center !default;
$coupon-logo__margin: 5px 0 0 !default;
$coupon-logo__margin--mobile: $coupon-logo__margin !default;
$coupon-logo__margin--large-coupon: 0 !default;
$coupon-logo__margin--large-coupon--mobile: 0 !default;
$coupon-logo__width: 85px !default;
$coupon-logo__width--mobile: 70px !default;
$coupon-logo__width--large-coupon: 120px !default;
$coupon-logo__width--large-coupon--mobile: 90px !default;
$coupon-logo__height: 85px !default;
$coupon-logo__height--mobile: 70px !default;
$coupon-logo__height--large-coupon: 120px !default;
$coupon-logo__height--large-coupon--mobile: 90px !default;
$coupon-logo__color: $color-black !default;
$coupon-logo__text-align: center !default;
$coupon-logo__color--large-coupon: $color-black !default;
$coupon-logo__color--large-coupon--mobile: $color-black !default;
$coupon-logo__bg-color--large-coupon: $color-white !default;
$coupon-logo__bg-color--large-coupon--mobile: $color-white !default;
$coupon-logo__color--bg: $color-black !default;
$coupon-logo__bg-color--bg: $color-white !default;
$coupon-logo-amount__font-weight: 700 !default;
$coupon-logo-amount__font-size: 36px !default;
$coupon-logo-amount__font-size--mobile: 36px !default;
$coupon-logo-amount__line-height: 36px !default;
$coupon-logo-amount__line-height--mobile: 32px !default;
$coupon-logo-type__font-size: 22px !default;
$coupon-logo-type__font-size--mobile: 22px !default;
$coupon-logo-type__line-height: 22px !default;
$coupon-title__margin: 0 10px 0 0 !default;
$coupon-title__margin--mobile: 0 10px 0 0 !default;
$coupon-title__color: $default-title__color !default;
$coupon-title__color--large-coupon: $default-title__color !default;
$coupon-title__font-weight: 500 !default;
$coupon-title__font-size: 18px !default;
$coupon-title__font-size--large-coupon: 24px !default;
$coupon-title__font-size--mobile: 14px !default;
$coupon-title__font-size--large-coupon--mobile: 14px !default;
$coupon-title__line-height: 26px !default;
$coupon-title__line-height--large-coupon: 32px !default;
$coupon-title__line-height--mobile: 18px !default;
$coupon-title__line-height--large-coupon--mobile: 18px !default;
$coupon-description__display: none !default;
$coupon-description__display--mobile: none !default;
$coupon-description__display--large-coupon--mobile: none !default;
$coupon-description__margin: 0 !default;
$coupon-description__margin--mobile: 0 !default;
$coupon-description__padding: 10px 30px 10px 47px !default;
$coupon-description__padding--mobile: 10px 22px !default;
$coupon-description__color: $color-black !default;
$coupon-description__color--mobile: $color-black !default;
$coupon-description__color--large-coupon: $color-black !default;
$coupon-description__font-size: 14px !default;
$coupon-description__font-size--mobile: 14px !default;
$coupon-description__font-size--large-coupon: 16px !default;
$coupon-description__font-size--large-coupon--mobile: 14px !default;
$coupon-description__font-weight: 400 !default;
$coupon-description__line-height: 22px !default;
$coupon-description__line-height--mobile: 22px !default;
$coupon-description__line-height--large-coupon: 22px !default;
$coupon-description__line-height--large-coupon--mobile: 18px !default;
$coupon-description__bg-color: $color-ivory-mist !default;
$coupon-description__webkit-line-clamp: 2 !default;
$coupon-description__webkit-line-clamp--mobile: 2 !default;
$coupon-description__max-height: none !default;
$coupon-description__display--full: inline-block !default;
$coupon-table__display: none !default;
$coupon-table__grid-template-columns: auto 1fr !default;
$coupon-table__grid-template-columns--mobile: 1fr 2fr !default;
$coupon-table__margin: 10px 0 !default;
$coupon-table__margin--large-coupon: $coupon-table__margin !default;
$coupon-table__font-size: 14px !default;
$coupon-table__font-size--mobile: 14px !default;
$coupon-table__font-size--large-coupon: 16px !default;
$coupon-table__font-size--large-coupon--mobile: 14px !default;
$coupon-table__line-height: 22px !default;
$coupon-table__line-height--mobile: 22px !default;
$coupon-table__gap: 0 24px !default;
$coupon-table-key__font-weight: 700 !default;
$coupon-btn__margin: 0 30px 0 0 !default;
$coupon-btn__margin--mobile: 0 0 6px !default;
$coupon-btn__margin--large-coupon--mobile: 0 -3px -1px 0 !default;
$coupon-btn-text__width: auto !default;
$coupon-btn__border-radius--mobile: 8px 0 30px 0 !default;
$coupon-table-key-value__padding: 1px !default;
$coupon-see-details__margin: 0 0 9px !default;
$coupon-see-details__padding: 0 !default;
$coupon-see-details__padding--mobile: 0 !default;
$coupon-see-details__padding--large-coupon: 0 !default;
$coupon-see-details__padding--large-coupon--mobile: 0 !default;
$coupon-see-details__color: $color-black !default;
$coupon-see-details__color--mobile: $color-black !default;
$coupon-see-details__color--large-coupon: $color-black !default;
$coupon-see-details__font-size: 14px !default;
$coupon-see-details__font-size--mobile: 14px !default;
$coupon-see-details__font-weight: 400 !default;
$coupon-see-details__line-height: 14px !default;
$coupon-see-details__line-height--large-coupon: 16px !default;
$coupon-see-details__bg-color: transparent !default;
$coupon-see-details__bg-color--large-coupon: transparent !default;
$coupon-see-details__content: $icon-more-details-arrow !default;
$coupon-labels__margin: 10px !default;
$coupon-labels__margin--mobile: 6px !default;
$coupon-labels__margin--large: 14px !default;
$coupon-label-before__position: relative !default;
$coupon-label-before__top: -1px !default;
$coupon-label-before__margin: 0 2px 0 0 !default;
$coupon-label-before__content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2_15)'%3E%3Cmask id='mask0_2_15' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='14' height='14'%3E%3Cpath d='M0 0H14V14H0V0Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_2_15)'%3E%3Cpath d='M8.7 10.4V9C9.36667 8.66667 9.9 8.2 10.3 7.6C12.1 7.6 13 6 13 3.9C13 3.2 12 2.9 11.4 2.7C11.2 1 8 1 7 1C4 1 2.53333 1.5 2.6 2.5V2.7C1.53333 2.9 1 3.16667 1 3.5C1 5.5 2 7.5 4 7.5V7.7L5.3 8.8V10.4C4.56667 10.8 4.2 11.3333 4.2 12C4.2 13 6.8 12.9 7.2 12.9C9.06667 12.9 10 12.7333 10 12.4C9.93333 11.5333 10 11 8.7 10.4Z' stroke='black' stroke-miterlimit='10'/%3E%3Cpath d='M3 4C3.13333 4.66667 3.46667 5.33333 4 6M11 4C10.8667 4.66667 10.5333 5.33333 10 6' stroke='black' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2_15'%3E%3Crect width='14' height='14' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") !default;
$coupon-label-exclusive__font-weight: 500 !default;
$coupon-label-exclusive__font-size: 14px !default;
$coupon-label-exclusive__line-height: 14px !default;
$coupon-verified__margin: 0 !default;
$coupon-verified__padding: 3px !default;
$coupon-verified__font-weight: 500 !default;
$coupon-verified__font-size: 14px !default;
$coupon-verified__line-height: 12px !default;
$coupon-verified__bg-color: $color-su-deep-blue !default;
$coupon-verified__border: 1px solid $color-black !default;
$coupon-verified__border-radius: 4px !default;
$coupon-verified__text-transform: capitalize !default;
$coupon-verified-before__position: relative !default;
$coupon-verified-before__top: 2px !default;
$coupon-verified-before__margin: 0 !default;
$coupon-verified-before__content: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15'%3E%3Cg filter='url(%23a)'%3E%3Crect width='14' height='14' rx='7' fill='%235C8EC0' shape-rendering='crispEdges'/%3E%3Crect x='.5' y='.5' width='13' height='13' rx='6.5' stroke='%23000' shape-rendering='crispEdges'/%3E%3Cpath d='m5.8 9-.2-.1-1.5-1.6a.3.3 0 1 1 .4-.4l1.4 1.4L9.4 5a.3.3 0 1 1 .4.4L6 8.9z' fill='%23000'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='a' x='0' y='0' width='14.4' height='14.4' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeOffset dx='.4' dy='.4'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_2481_2946' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E") !default;
$coupon-tag__font-size--large-coupon: 22px !default;
$coupon-tag-before__font-size--large-coupon: 17px !default;
$coupon-label__justify-content--large-coupon: left !default;
$coupon-grid-template-rows: $coupon-title__line-height * 2 !default;
$coupon-grid-template-rows--large-coupon: $coupon-title__line-height--large-coupon * 2 !default;
$coupon-logo-green: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 149 163'%3E%3Cpath fill='%236A7256' fill-rule='evenodd' d='M70 7.6a61 61 0 0 1 4-6.5c1.3 1.8 2.6 4 3.9 6.5a74 74 0 0 1 68.6 57A74 74 0 0 1 107 148l-.1-.4-.8-1.4-.1-.4a71.6 71.6 0 0 0 38.2-80.7 71.6 71.6 0 0 0-65-55c7.7 16.1 13.1 42.6-4.2 66.8v49.5c6-24 28.5-33 40-34.6-2.5 11.8-13.7 34.8-40 38V163h-2v-52.8c-26.6-3-38-26.2-40.4-38 11.8 1.6 35.2 11 40.4 36.5V76.9a64.9 64.9 0 0 1-4.2-66.8A71.6 71.6 0 0 0 44 146.3l-.1.3-.7 1.5-.2.3A74 74 0 0 1 28 24 74 74 0 0 1 70 7.6Z' clip-rule='evenodd'/%3E%3C/svg%3E") !default;
$curated-section__grid-template-areas:
  "heading heading heading"
  "logo title title"
  "logo subtitle subtitle" !default;
$curated-section__grid-template-areas--mobile:
  "heading"
  "title"
  "subtitle" !default;
$curated-section__grid-template-columns: 114px auto !default;
$curated-section__grid-template-columns--mobile: auto !default;
$curated-section__margin: 0 !default;
$curated-section__margin--mobile: 0 -15px !default;
$curated-section__padding: 0 0 15px !default;
$curated-section__padding--mobile: 0 15px 15px !default;
$curated-section__text-align--mobile: center !default;
$curated-section__bg-color: transparent !default;
$curated-section__border: solid $color-black !default;
$curated-section__border--mobile: solid #DFDFDF !default;
$curated-section__border-width: 2px 0 0 !default;
$curated-section__border-width--mobile: 1px 0 0 !default;
$curated-heading__width: max-content !default;
$curated-heading__width--mobile: max-content !default;
$curated-heading__margin: 0 0 8px !default;
$curated-heading__margin--mobile: 0 auto !default;
$curated-heading__padding: 4px 12px !default;
$curated-heading__color: $color-white !default;
$curated-heading__font-weight: 400 !default;
$curated-heading__font-size: 14px !default;
$curated-heading__line-height: 22px !default;
$curated-heading__bg-color: $color-black !default;
$curated-logo__width: 80px !default;
$curated-logo__height: $curated-logo__width !default;
$curated-logo__border-radius: 50% !default;
$curated-logo-link__position--mobile: absolute !default;
$curated-logo-link__top--mobile: 83px !default;
$curated-title__color: $color-black !default;
$curated-title__font-size: 18px !default;
$curated-title__font-size--mobile: $curated-title__font-size !default;
$curated-title__font-family: Roboto, sans-serif !default;
$curated-title__padding--mobile: 16px 0 0 !default;
$curated-title__line-height: normal !default;
$curated-title__margin-bottom: 6px !default;
$curated-title-intro__color: $color-black !default;
$curated-title-intro__font-weight: 700 !default;
$curated-title-intro__font-size: 20px !default;
$curated-title-intro__font-family: Roboto, sans-serif !default;
$curated-title-intro__line-height: 22px !default;
$curated-title-intro__width--mobile: 100% !default;
$curated-title-intro__padding--mobile: 0 0 110px !default;
$curated-title-name__color: #057DBC !default;
$curated-title-name__font-weight: 400 !default;
$curated-title-name__font-family: Roboto, sans-serif !default;
$curated-title-name__line-height: 22px !default;
$curated-title-name__font-size: 20px !default;
$curated-title-name__color--hover: $color-black !default;
$curated-subtitle__color: #666 !default;
$curated-subtitle__font-size: 16px !default;
$curated-subtitle__font-size--mobile: $curated-subtitle__font-size !default;
$curated-subtitle__line-height: 19px !default;
$curated-subtitle__webkit-line-clamp: 1 !default;
$curated-subtitle__webkit-line-clamp--mobile: 2 !default;
$curated-read-more__bg-color: $curated-section__bg-color !default;

.coupon {
  $this: &;

  @include default-box($coupon__bg-color);

  position: relative;
  display: grid;
  grid-template: $coupon__grid-template;
  align-items: flex-start;
  min-height: 50px;
  margin: $coupon__margin;
  padding: $coupon__padding;

  @media #{$md} {
    grid-template: $coupon__grid-template--mobile;
    padding: $coupon__padding--mobile;
    border-bottom-right-radius: 30px;
  }

  &__logo {
    display: flex;
    flex-flow: column;
    grid-area: logo;
    align-items: $coupon-logo__align-items;
    align-self: center;
    justify-content: $coupon-logo__justify-content;
    justify-self: center;
    width: $coupon-logo__width;
    height: $coupon-logo__height;
    margin-bottom: 5px;
    color: $coupon-logo__color;
    text-align: $coupon-logo__text-align;
    text-wrap: nowrap;
    cursor: pointer;

    @media #{$md} {
      width: $coupon-logo__width--mobile;
      height: $coupon-logo__height--mobile;
    }

    img {
      width: $coupon-logo__width;
      height: $coupon-logo__height;

      @media #{$md} {
        width: $coupon-logo__width--mobile;
        height: $coupon-logo__height--mobile;
      }
    }

    &--bg {
      margin: $coupon-logo__margin;
      color: $coupon-logo__color--bg;
      background-color: $coupon-logo__bg-color--bg;

      @media #{$md} {
        width: $coupon-logo__width--mobile;
        height: $coupon-logo__height--mobile;
        margin: $coupon-logo__margin--mobile;
      }
    }
  }

  #{$this}__logo--green {
    background-image: $coupon-logo-green;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__amount {
    font-weight: $coupon-logo-amount__font-weight;
    font-size: $coupon-logo-amount__font-size;
    line-height: $coupon-logo-amount__line-height;

    @media #{$md} {
      font-size: $coupon-logo-amount__font-size--mobile;
      line-height: $coupon-logo-amount__line-height--mobile;
    }
  }

  &__type {
    font-size: $coupon-logo-type__font-size;
    line-height: $coupon-logo-type__line-height;

    @media #{$md} {
      font-size: $coupon-logo-type__font-size--mobile;
    }
  }

  &__title {
    grid-area: title;
    margin: $coupon-title__margin;
    color: $coupon-title__color;
    font-weight: $coupon-title__font-weight;
    font-size: $coupon-title__font-size;
    line-height: $coupon-title__line-height;
    cursor: pointer;

    @media #{$md} {
      margin: $coupon-title__margin--mobile;
      font-size: $coupon-title__font-size--mobile;
      line-height: $coupon-title__line-height--mobile;
    }
  }

  &__description {
    @if $coupon-description__display == -webkit-box {
      display: -webkit-box;
      max-height: $coupon-description__max-height;
      overflow: hidden;
      -webkit-line-clamp: $coupon-description__webkit-line-clamp;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    } @else {
      display: $coupon-description__display;
    }

    grid-area: desc;
    margin: $coupon-description__margin;
    padding: $coupon-description__padding;
    color: $coupon-description__color;
    font-size: $coupon-description__font-size;
    line-height: $coupon-description__line-height;
    background-color: $coupon-description__bg-color;

    @media #{$md} {
      @if $coupon-description__display == -webkit-box {
        display: -webkit-box;
        -webkit-line-clamp: $coupon-description__webkit-line-clamp--mobile;
      } @else {
        display: $coupon-description__display--mobile;
      }

      margin: $coupon-description__margin--mobile;
      padding: $coupon-description__padding--mobile;
      color: $coupon-description__color--mobile;
      font-size: $coupon-description__font-size--mobile;
      line-height: $coupon-description__line-height--mobile;
    }

    &--full {
      display: $coupon-description__display--full;
      max-height: unset;
      border-radius: 0 0 4px 4px;

      @media #{$md} {
        border-radius: 0 0 30px 4px;
      }

      #{$this}__table {
        display: grid;
      }
    }
  }

  &__table {
    display: $coupon-table__display;
    grid-template-columns: $coupon-table__grid-template-columns;
    gap: $coupon-table__gap;
    margin: $coupon-table__margin;
    font-size: $coupon-table__font-size;
    line-height: $coupon-table__line-height;

    @media #{$md} {
      grid-template-columns: $coupon-table__grid-template-columns--mobile;
      font-size: $coupon-table__font-size--mobile;
      line-height: $coupon-table__line-height--mobile;
    }

    .key,
    .value {
      padding: $coupon-table-key-value__padding;
    }

    .key {
      display: inline-flex;
      font-weight: $coupon-table-key__font-weight;

      &::before {
        position: relative;
        top: 2px;
        display: inline-block;
        min-width: 14px;
        height: 14px;
        margin-right: 6px;
        background-image: $icon-thumbtack;
        background-repeat: no-repeat;
        content: "";
      }
    }

    .value {
      word-break: break-word;
    }
  }

  &--info-widget #{$this}__action,
  &__action-btn {
    @include btn;

    grid-area: btn;
    align-self: center;
    height: 36px;
    margin: $coupon-btn__margin;

    @media #{$md} {
      justify-self: center;
      min-width: 100%;
      margin: $coupon-btn__margin--mobile;
      border-radius: $coupon-btn__border-radius--mobile;
    }

    &-text {
      display: inline-block;
      width: $coupon-btn-text__width;
      font-size: 14px;
      line-height: 34px;
    }
  }

  &__see-details {
    display: inline-block;
    grid-area: toggle;
    align-self: flex-end;
    justify-self: center;
    margin: $coupon-see-details__margin;
    padding: $coupon-see-details__padding;
    color: $coupon-see-details__color;
    font-weight: $coupon-see-details__font-weight;
    font-size: $coupon-see-details__font-size;
    line-height: $coupon-see-details__line-height;
    background-color: $coupon-see-details__bg-color;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 3px;
    }

    @media #{$md} {
      padding: $coupon-see-details__padding--mobile;
      color: $coupon-see-details__color--mobile;
      font-size: $coupon-see-details__font-size--mobile;
    }

    &::after {
      display: inline-block;
      margin-left: 5px;
      color: inherit;
      font-weight: 700;
      font-size: 10px;
      font-family: simple, serif !important;
      transform: rotate(0deg);
      transition: transform ease-in-out 0.3s;
      content: $coupon-see-details__content;
    }

    &--opened::after {
      transform: rotate(-90deg);
    }
  }

  &__labels {
    display: inline-flex;
    grid-area: labels;
    gap: 4px;
    margin: $coupon-labels__margin--mobile;

    @media #{$min-md} {
      margin: $coupon-labels__margin;
    }
  }

  &__label {
    display: inline-flex;
    grid-area: label;
    align-items: center;

    &--exclusive {
      width: fit-content;
      padding: 3px;
      font-weight: $coupon-label-exclusive__font-weight;
      font-size: $coupon-label-exclusive__font-size;
      line-height: $coupon-label-exclusive__line-height;
      background-color: $color-su-pink;
      border: 1px solid $color-black;
      border-radius: 4px;

      &::before {
        position: $coupon-label-before__position;
        top: $coupon-label-before__top;
        display: inline-block;
        min-width: 12px;
        height: 12px;
        margin: $coupon-label-before__margin;
        content: $coupon-label-before__content;
      }
    }
  }

  &__verified {
    display: inline-flex;
    grid-area: label;
    align-items: center;
    margin: $coupon-verified__margin;
    padding: $coupon-verified__padding;
    font-weight: $coupon-verified__font-weight;
    font-size: $coupon-verified__font-size;
    line-height: $coupon-verified__line-height;
    text-transform: $coupon-verified__text-transform;
    text-wrap: nowrap;
    background-color: $coupon-verified__bg-color;
    border: $coupon-verified__border;
    border-radius: $coupon-verified__border-radius;

    &::before {
      display: inline-block;
      min-width: 14px;
      height: 14px;
      margin: $coupon-verified-before__margin;
      content: $coupon-verified-before__content;
    }
  }

  &--large {
    grid-template: $coupon__grid-template--large;
    padding: $coupon__padding--large-coupon;
    box-shadow: $su-box-shadow-triple-black;

    @media #{$md} {
      grid-template: $coupon__grid-template--large--mobile;
      padding: $coupon__padding--large-coupon--mobile;
      box-shadow: $su-box-shadow-triple-black--mobile;
    }

    &:hover {
      @media #{$min-md} {
        box-shadow: $su-box-shadow-neo-brutalism-thick;
      }
    }

    #{$this}__logo {
      width: $coupon-logo__width--large-coupon;
      height: $coupon-logo__height--large-coupon;
      margin: $coupon-logo__margin--large-coupon;
      color: $coupon-logo__color--large-coupon;
      background-color: $coupon-logo__bg-color--large-coupon;

      @media #{$md} {
        width: $coupon-logo__width--large-coupon--mobile;
        height: $coupon-logo__height--large-coupon--mobile;
        margin: $coupon-logo__margin--large-coupon--mobile;
        color: $coupon-logo__color--large-coupon--mobile;
        background-color: $coupon-logo__bg-color--large-coupon--mobile;
      }
    }

    #{$this}__labels {
      @media #{$min-md} {
        margin: $coupon-labels__margin--large;
      }
    }

    #{$this}__title {
      color: $coupon-title__color--large-coupon;
      font-weight: 600;
      font-size: $coupon-title__font-size--large-coupon;
      line-height: $coupon-title__line-height--large-coupon;

      @media #{$md} {
        font-weight: 500;
        font-size: $coupon-title__font-size--large-coupon--mobile;
        line-height: $coupon-title__line-height--large-coupon--mobile;
      }
    }

    #{$this}__description {
      display: none;
      overflow: hidden;
      color: $coupon-description__color--large-coupon;
      font-size: $coupon-description__font-size--large-coupon;
      line-height: $coupon-description__line-height--large-coupon;

      @media #{$md} {
        display: $coupon-description__display--large-coupon--mobile;
        font-size: $coupon-description__font-size--large-coupon--mobile;
        line-height: $coupon-description__line-height--large-coupon--mobile;
      }

      &--full {
        display: block;
      }
    }

    #{$this}__table {
      margin: $coupon-table__margin--large-coupon;
      font-size: $coupon-table__font-size--large-coupon;

      @media #{$md} {
        font-size: $coupon-table__font-size--large-coupon--mobile;
      }
    }

    #{$this}__action-btn {
      @media #{$min-md} {
        min-width: 220px;
        height: 56px;

        &-text {
          width: 100%;
          font-size: 18px;
          line-height: 54px;
        }
      }

      @media #{$md} {
        margin: $coupon-btn__margin--large-coupon--mobile;
      }
    }

    #{$this}__see-details {
      padding: $coupon-see-details__padding--large-coupon;
      color: $coupon-see-details__color--large-coupon;
      line-height: $coupon-see-details__line-height--large-coupon;
      background-color: $coupon-see-details__bg-color--large-coupon;

      @media #{$md} {
        padding: $coupon-see-details__padding--large-coupon--mobile;
      }
    }
  }

  &[data-coupon-type="coupon"] {
    #{$this}__action-btn {
      @include unleashed-code;

      display: flex;
      background-color: $color-white;
      border: none;

      &-text {
        z-index: 1;
        width: calc(100% - 32px);
        background-color: $color-su-saturated-green;
        border: 1px solid $color-black;
        border-radius: 8px 4px 4px 0;
        transition: width 0.1s ease-in;

        @media #{$min-md} {
          border-radius: 8px;
        }
      }

      &:hover {
        @media #{$min-md} {
          #{$this}__action-btn-text {
            width: calc(100% - 38px);
            color: $color-black;
            background-color: $color-su-pink;
          }
        }
      }

      &::after {
        @media #{$md} {
          border-radius: 0 0 30px;
        }
      }
    }

    &#{$this}--large {
      @media #{$min-md} {
        #{$this}__action-btn {
          &::after {
            font-size: 18px;
          }
        }
      }
    }
  }

  &#{$this}--highlighted {
    #{$this}__action-btn {
      position: relative;
      background: $color-ivory-mist;
      border: 1px solid $color-black;

      &-text {
        max-width: 0;
        visibility: hidden;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        justify-content: center;
        min-width: 100%;
        min-height: 100%;
        padding-right: 0;
        color: $color-black;
        font-size: 14px;
        line-height: 36px;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        border-radius: 28px;
        content: var(--clicked-coupon-text);
        clip-path: none;
      }

      &::after {
        position: absolute;
        top: 56%;
        right: 36px;
        width: auto;
        height: auto;
        padding: 0;
        background: transparent;
        border: none;
        border-radius: 0;
        transform: translateY(-50%);
        content: $icon-tick;

        @media #{$md} {
          right: 48px;
        }

        @media #{$xxs} {
          right: 28px;
        }
      }

      &:hover {
        background: $color-su-pink;

        &::before {
          width: 100%;
        }

        &::after {
          transition: none;
        }
      }
    }

    &#{$this}--large {
      #{$this}__action-btn {
        @media #{$min-md} {
          &::before {
            font-size: 18px;
            line-height: 54px;
          }

          &::after {
            top: 50%;
          }
        }
      }
    }
  }
}

.curated {
  &__section {
    display: grid;
    grid-template-areas: $curated-section__grid-template-areas;
    grid-template-columns: $curated-section__grid-template-columns;
    margin: $curated-section__margin;
    padding: $curated-section__padding;
    background-color: $curated-section__bg-color;
    border: $curated-section__border;
    border-width: $curated-section__border-width;

    @media #{$md} {
      position: relative;
      grid-template-areas: $curated-section__grid-template-areas--mobile;
      grid-template-columns: $curated-section__grid-template-columns--mobile;
      margin: $curated-section__margin--mobile;
      padding: $curated-section__padding--mobile;
      text-align: $curated-section__text-align--mobile;
      border: $curated-section__border--mobile;
      border-width: $curated-section__border-width--mobile;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__heading {
    grid-area: heading;
    width: $curated-heading__width;
    margin: $curated-heading__margin;
    padding: $curated-heading__padding;
    color: $curated-heading__color;
    font-weight: $curated-heading__font-weight;
    font-size: $curated-heading__font-size;
    line-height: $curated-heading__line-height;
    text-align: left;
    background-color: $curated-heading__bg-color;

    @media #{$md} {
      width: $curated-heading__width--mobile;
      margin: $curated-heading__margin--mobile;
    }
  }

  &__logo {
    width: $curated-logo__width;
    height: $curated-logo__height;
    border-radius: $curated-logo__border-radius;
  }

  &__logo-link {
    grid-area: logo;
    justify-self: center;

    @media #{$md} {
      position: $curated-logo-link__position--mobile;
      top: $curated-logo-link__top--mobile;
    }
  }

  &__title {
    grid-area: title;
    margin-bottom: $curated-title__margin-bottom;
    color: $curated-title__color;
    font-size: $curated-title__font-size;
    font-family: $curated-title__font-family;
    line-height: $curated-title__line-height;

    @media #{$md} {
      padding: $curated-title__padding--mobile;
      font-size: $curated-title__font-size--mobile;
    }

    &-intro {
      display: block;
      color: $curated-title-intro__color;
      font-weight: $curated-title-intro__font-weight;
      font-size: $curated-title-intro__font-size;
      font-family: $curated-title-intro__font-family;
      line-height: $curated-title-intro__line-height;

      @media #{$md} {
        display: inline-block;
        width: $curated-title-intro__width--mobile;
        padding: $curated-title-intro__padding--mobile;
      }
    }

    &-name {
      color: $curated-title-name__color;
      font-weight: $curated-title-name__font-weight;
      font-size: $curated-title-name__font-size;
      font-family: $curated-title-name__font-family;
      line-height: $curated-title-name__line-height;

      &:hover {
        color: $curated-title-name__color--hover;
      }
    }
  }

  &__subtitle {
    grid-area: subtitle;
    align-self: center;
    color: $curated-subtitle__color;
    font-size: $curated-subtitle__font-size;
    line-height: $curated-subtitle__line-height;

    @media #{$md} {
      font-size: $curated-subtitle__font-size--mobile;
    }

    &:not(.opened) {
      position: relative;
      display: -webkit-box;
      -webkit-line-clamp: $curated-subtitle__webkit-line-clamp;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;

      @media #{$md} {
        -webkit-line-clamp: $curated-subtitle__webkit-line-clamp--mobile;
      }
    }
  }

  &__read-more {
    display: block;
    background-color: $curated-read-more__bg-color;
    cursor: pointer;

    &::after {
      text-decoration: underline;
      content: attr(data-less-text);
    }

    &:not(.show) {
      display: none;
    }

    &:not(.opened) {
      position: absolute;
      right: 0;

      @media #{$min-md} {
        top: 0;
      }

      @media #{$md} {
        bottom: 0;
      }

      &::before {
        content: "... ";
      }

      &::after {
        content: attr(data-more-text);
      }
    }
  }
}
