$footer_2019-margin-top: 20px !default;
$footer_2019-background-color: $color-white !default;
$footer_2019-a__color: $color-white !default;
$footer_2019-grid-template-areas:
  "to-top to-top"
  "back-wrp area-one"
  "divider divider"
  "area-empty area-two" !default;
$footer_2019-grid-template-areas--mobile:
  "to-top"
  "area-one"
  "back-wrp"
  "divider"
  "area-empty"
  "area-two" !default;
$footer_2019-grid-template-areas--second:
  "to-top to-top"
  "back-wrp area-one"
  "divider divider"
  "area-two area-two" !default;
$footer_2019-grid-template-areas--third:
  "to-top to-top to-top"
  "area-two divider area-one"
  "back-wrp . ." !default;
$footer_2019-grid-template-areas--third--mobile:
  "to-top"
  "area-two"
  "divider"
  "area-one"
  "back-wrp" !default;
$footer_2019-grid-template-areas--fourth-mobile:
  "back-wrp"
  "area-one"
  "divider"
  "area-two"
  "to-top" !default;
$footer_2019-grid-template-areas--fourth-desktop:
  "back-wrp back-wrp"
  "area-one area-two"
  "to-top to-top" !default;
$footer_2019-grid-template-columns: 1fr 4fr !default;
$footer_2019-grid-template-columns--third-desktop: 7fr auto 11fr !default;
$footer_2019-grid-template-columns--fourth-desktop: 11fr 7fr !default;
$footer_2019-to-top__width: fit-content !default;
$footer_2019-to-top__margin: 0 0 0 auto !default;
$footer_2019-to-top__margin--fourth: 0 auto !default;
$footer_2019-to-top__margin--mobile: 0 auto !default;
$footer_2019-to-top__padding: 11px 20px !default;
$footer_2019-to-top__background-color: $link__color !default;
$footer_2019-to-top__background-color--hover: $link__color--hover !default;
$footer_2019-to-top__border-radius: 15px 15px 0 0 !default;
$footer_2019-to-top__min-width--mobile: 50% !default;
$footer_2019-to-top__min-width--fourth-mobile: 200px !default;
$footer_2019-to-top__text-align--mobile: center !default;
$footer_2019-back-wrp__margin-top--mobile: 10px !default;
$footer_2019-back-wrp__margin--fourth: 10px auto 0 !default;
$footer_2019-back-wrp__text-align--mobile: center !default;
$footer_2019-back-btn__min-width: 100px !default;
$footer_2019-back-btn__max-width: 240px !default;
$footer_2019-back-btn__padding: 10px !default;
$footer_2019-back-btn__font-weight: 600 !default;
$footer_2019-back-btn__font-size: 16px !default;
$footer_2019-back-btn__background-color: $link__color !default;
$footer_2019-back-btn__border-radius: 9px !default;
$footer_2019-back-btn__border-radius--fourth: 9px 9px 0 0 !default;
$footer_2019-back-btn__background-color--hover: $link__color--hover !default;
$footer_2019-area-one__padding: 0 20px !default;
$footer_2019-area-one__text-align--mobile: center !default;
$footer_2019-area-one-a__color: $color-grey !default;
$footer_2019-divider__width: 100% !default;
$footer_2019-divider__width--third-desktop: 10px !default;
$footer_2019-divider__height: 1px !default;
$footer_2019-divider__height--third-desktop: auto !default;
$footer_2019-divider__margin: 20px 0 !default;
$footer_2019-divider__margin--third: 0 10px !default;
$footer_2019-divider__margin--third-mobile: 10px 0 !default;
$footer_2019-divider__background-color: $link__color !default;
$footer_2019-area-two__padding: 0 20px !default;
$footer_2019-area-two__text-align--mobile: center !default;
$footer_2019-area-two-a__color: $color-grey !default;
$footer_2019-legal-pages__margin-top: 7px !default;
$footer_2019-logo-img__max-width: 240px !default;
$footer_2019-campaigns__margin-top: 7px !default;
$footer_2019-campaigns__font-weight: 700 !default;
$footer_2019-bottom__height: 20px !default;
$footer_2019-bottom__margin-top: 20px !default;
$footer_2019-bottom__margin-top--third: 0 !default;
$footer_2019-bottom__margin-top--fourth: 0 !default;
$footer_2019-bottom__background-color: $link__color !default;

.footer {
  $this: &;

  &.footer--2019 {
    margin-top: $footer_2019-margin-top;
    background-color: $footer_2019-background-color;

    a {
      color: $footer_2019-a__color;
    }
  }

  &__wrp {
    position: relative;
    display: grid;

    @media #{$md} {
      grid-template-areas: $footer_2019-grid-template-areas--mobile;
    }

    @media #{$min-md} {
      grid-template-areas: $footer_2019-grid-template-areas;
      grid-template-columns: $footer_2019-grid-template-columns;
    }
  }

  &__to-top {
    grid-area: to-top;
    width: $footer_2019-to-top__width;
    margin: $footer_2019-to-top__margin;
    padding: $footer_2019-to-top__padding;
    background-color: $footer_2019-to-top__background-color;
    border-radius: $footer_2019-to-top__border-radius;

    @media #{$md} {
      min-width: $footer_2019-to-top__min-width--mobile;
      margin: $footer_2019-to-top__margin--mobile;
      text-align: $footer_2019-to-top__text-align--mobile;
    }

    &:hover {
      background-color: $footer_2019-to-top__background-color--hover;
    }
  }

  &__back-wrp {
    grid-area: back-wrp;

    @media #{$md} {
      margin-top: $footer_2019-back-wrp__margin-top--mobile;
      text-align: $footer_2019-back-wrp__text-align--mobile;
    }
  }

  &__back-btn {
    display: inline-block;
    grid-area: back-btn;
    min-width: $footer_2019-back-btn__min-width;
    max-width: $footer_2019-back-btn__max-width;
    padding: $footer_2019-back-btn__padding;
    font-weight: $footer_2019-back-btn__font-weight;
    font-size: $footer_2019-back-btn__font-size;
    text-align: center;
    background-color: $footer_2019-back-btn__background-color;
    border-radius: $footer_2019-back-btn__border-radius;

    &:hover {
      background-color: $footer_2019-back-btn__background-color--hover;
    }
  }

  &__area-one {
    grid-area: area-one;
    padding: $footer_2019-area-one__padding;

    @media #{$md} {
      text-align: $footer_2019-area-one__text-align--mobile;
    }

    a {
      color: $footer_2019-area-one-a__color;
    }
  }

  &__divider {
    grid-area: divider;
    width: $footer_2019-divider__width;
    height: $footer_2019-divider__height;
    margin: $footer_2019-divider__margin;
    background-color: $footer_2019-divider__background-color;
  }

  &__area-two {
    grid-area: area-two;
    padding: $footer_2019-area-two__padding;

    @media #{$md} {
      text-align: $footer_2019-area-two__text-align--mobile;
    }

    a {
      color: $footer_2019-area-two-a__color;
    }
  }

  &__legal-pages {
    margin-top: $footer_2019-legal-pages__margin-top;
  }

  &__logo {
    @media #{$md} {
      text-align: center;
    }

    img {
      max-width: $footer_2019-logo-img__max-width;
    }
  }

  &__campaigns {
    margin-top: $footer_2019-campaigns__margin-top;
    font-weight: $footer_2019-campaigns__font-weight;
  }

  &__bottom {
    height: $footer_2019-bottom__height;
    margin-top: $footer_2019-bottom__margin-top;
    background-color: $footer_2019-bottom__background-color;
  }

  &--second {
    #{$this}__wrp {
      @media #{$min-md} {
        grid-template-areas: $footer_2019-grid-template-areas--second;
      }
    }
  }

  &--third {
    #{$this}__wrp {
      @media #{$md} {
        grid-template-areas: $footer_2019-grid-template-areas--third--mobile;
      }

      @media #{$min-md} {
        grid-template-areas: $footer_2019-grid-template-areas--third;
        grid-template-columns: $footer_2019-grid-template-columns--third-desktop;
      }
    }

    #{$this}__divider {
      margin: $footer_2019-divider__margin--third;

      @media #{$md} {
        margin: $footer_2019-divider__margin--third-mobile;
      }

      @media #{$min-md} {
        width: $footer_2019-divider__width--third-desktop;
        height: $footer_2019-divider__height--third-desktop;
      }
    }

    #{$this}__bottom {
      margin-top: $footer_2019-bottom__margin-top--third;
    }
  }

  &--fourth {
    #{$this}__wrp {
      @media #{$md} {
        grid-template-areas: $footer_2019-grid-template-areas--fourth-mobile;
      }

      @media #{$min-md} {
        grid-template-areas: $footer_2019-grid-template-areas--fourth-desktop;
        grid-template-columns: $footer_2019-grid-template-columns--fourth-desktop;
      }
    }

    #{$this}__back-wrp {
      margin: $footer_2019-back-wrp__margin--fourth;
    }

    #{$this}__to-top {
      margin: $footer_2019-to-top__margin--fourth;

      @media #{$md} {
        min-width: $footer_2019-to-top__min-width--fourth-mobile;
        text-align: center;
      }
    }

    #{$this}__back-btn {
      border-radius: $footer_2019-back-btn__border-radius--fourth;
    }

    #{$this}__divider {
      @media #{$min-md} {
        display: none;
      }
    }

    #{$this}__bottom {
      margin-top: $footer_2019-bottom__margin-top--fourth;
    }
  }
}
