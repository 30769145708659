$see-more-categories-margin-top: 20px !default;
$see-more-categories-padding: 0 !default;
$see-more-categories-padding--mobile: 0 !default;
$see-more-categories-background-color: $color-white !default;
$see-more-categories-border: none !default;
$see-more-categories-box-shadow: none !default;
$see-more-categories-box-shadow--hover: none !default;
$see-more-categories-before__background-color: none !default;
$see-more-categories-title__font-size: 20px !default;
$see-more-categories-title__line-height: 28px !default;
$see-more-categories-content__margin-top: 0 !default;
$see-more-categories-list__float: left !default;
$see-more-categories-list__width: 33% !default;
$see-more-categories-list__margin-bottom: 40px !default;
$see-more-categories-list__padding-left: 20px !default;
$see-more-categories-list__style-type: disc !default;
$see-more-categories-item__margin: 0 !default;
$see-more-categories-item__color: $color-black !default;
$see-more-categories-item__color--hover: $color-black !default;
$see-more-categories-item__font-weight: 400 !default;
$see-more-categories-item__font-size: 18px !default;
$see-more-categories-item-link__text-decoration: underline !default;

.see-more-categories {
  position: relative;
  margin-top: $see-more-categories-margin-top;
  padding: $see-more-categories-padding;
  background-color: $see-more-categories-background-color;
  border: $see-more-categories-border;
  box-shadow: $see-more-categories-box-shadow;

  @if $see-more-categories-before__background-color != "unset" {
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $see-more-categories-before__background-color;
      content: "";
    }
  }

  @media #{$md} {
    padding: $see-more-categories-padding--mobile;
  }

  @if $see-more-categories-box-shadow--hover != $see-more-categories-box-shadow {
    &:hover {
      box-shadow: $see-more-categories-box-shadow--hover;
    }
  }

  &__title {
    position: relative;
    margin: 0 0 15px;
    font-weight: 500;
    font-size: $see-more-categories-title__font-size;
    line-height: $see-more-categories-title__line-height;
  }

  &__content {
    margin-top: $see-more-categories-content__margin-top;
    overflow: hidden;
  }

  &__list {
    display: inline-block;

    @if $see-more-categories-list__float != "none" {
      float: $see-more-categories-list__float;
    }

    width: $see-more-categories-list__width;
    margin-bottom: $see-more-categories-list__margin-bottom;
    padding-left: $see-more-categories-list__padding-left;

    @if $see-more-categories-list__style-type != "disc" {
      list-style-type: $see-more-categories-list__style-type;
    }

    @media #{$md} {
      width: 100%;
      margin-bottom: 0;
    }
  }

  &__item {
    position: relative;
    margin: $see-more-categories-item__margin;
    color: $see-more-categories-item__color;
    font-weight: $see-more-categories-item__font-weight;
    font-size: $see-more-categories-item__font-size;
    line-height: 26px;

    &:hover {
      color: $see-more-categories-item__color--hover;
    }

    a {
      color: inherit;
      font-weight: inherit;
      font-size: inherit;

      @if $see-more-categories-item-link__text-decoration != "underline" {
        text-decoration: $item-link__text-decoration;
      }

      &:hover {
        font-weight: 500;
      }
    }
  }
}
