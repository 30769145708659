$flyout__logo-color: $color-black !default;
$flyout__close-button-hover: $color-tornado-grey !default;
$flyout__close-button: #C4C4C4 !default;
$flyout__background: $color-white !default;
$flyout__font: "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif !default;
$flyout__color: $color-black !default;
$flyout__bottom: 175px !default;
$flyout__grid-template-column: 80px auto auto !default;
$flyout__align-items: center !default;
$flyout__width: 515px !default;
$flyout__min-height: 150px !default;
$flyout__padding: 15px 21px 20px 48px !default;
$flyout__border-radius: 15px 0 0 15px !default;
$flyout__box-shadow: -5px 7px 17px rgba(0, 0, 0, 0.34) !default;
$flyout__grid-template-areas--mobile: "logo text" "btn btn" !default;
$flyout__justify-items--mobile: center !default;
$flyout__width--mobile: 90% !default;
$flyout__height--mobile: 165px !default;
$flyout__padding--mobile: 16px 10px 15px 47px !default;
$flyout-before__width: 30px !default;
$flyout-before__color: #FF6859 !default;
$flyout-before__border-radius: 15px 0 0 15px !default;
$flyout-before__height--mobile: 165px !default;
$flyout-close-btn__top: -20px !default;
$flyout-close-btn__right: 16px !default;
$flyout-close-btn__width: 40px !default;
$flyout-close-btn__height: 40px !default;
$flyout-close-btn__border-radius: 50% !default;
$flyout-default__padding: 11px 15px 11px 47px !default;
$flyout-logo__width: 80px !default;
$flyout-logo__height: 80px !default;
$flyout-logo-default__height: 60px !default;
$flyout-logo-default__border-right: 2px solid $color-white !default;
$flyout-logo-first__background-color: $color-white !default;
$flyout-amount__line-height: 1.5em !default;
$flyout-type__line-height: 1.5em !default;
$flyout-text__padding: 0 20px !default;
$flyout-text__font-size: 20px !default;
$flyout-text__line-height: 1.4 !default;
$flyout-text__text-align: center !default;
$flyout-text__padding--mobile: 0 10px !default;
$flyout-text__font-size--mobile: 16px !default;
$flyout-text__text-align--mobile: left !default;
$flyout-clickout__width: 115px !default;
$flyout-clickout__height: 40px !default;
$flyout-clickout__background: $color-su-bright-green !default;
$flyout-clickout__border-radius: 5px !default;
$flyout-clickout-default__font-size: 11px !default;
$flyout-clickout-default__text-align: center !default;
$flyout-clickout-default__border: 1px solid $color-white !default;
$flyout-clickout-default__border-radius: 30px !default;
$flyout-clickout-default__padding: 8px !default;
$flyout-clickout-default__padding--mobile: 8px !default;
$flyout-clickout-default__font-size--mobile: 10px !default;
$flyout-clickout-default__bg-color--mobile-hover: $color-white !default;
$flyout-clickout-first-btn-hover__color: $color-black !default;
$flyout-button__color: $color-white !default;
$flyout-button__font-size: 16px !default;
$flyout-logo-green: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 149 163'%3E%3Cpath fill='%236A7256' fill-rule='evenodd' d='M70 7.6a61 61 0 0 1 4-6.5c1.3 1.8 2.6 4 3.9 6.5a74 74 0 0 1 68.6 57A74 74 0 0 1 107 148l-.1-.4-.8-1.4-.1-.4a71.6 71.6 0 0 0 38.2-80.7 71.6 71.6 0 0 0-65-55c7.7 16.1 13.1 42.6-4.2 66.8v49.5c6-24 28.5-33 40-34.6-2.5 11.8-13.7 34.8-40 38V163h-2v-52.8c-26.6-3-38-26.2-40.4-38 11.8 1.6 35.2 11 40.4 36.5V76.9a64.9 64.9 0 0 1-4.2-66.8A71.6 71.6 0 0 0 44 146.3l-.1.3-.7 1.5-.2.3A74 74 0 0 1 28 24 74 74 0 0 1 70 7.6Z' clip-rule='evenodd'/%3E%3C/svg%3E") !default;

@mixin flyout-clickout {
  font-weight: 700;
  font-size: $flyout-clickout-default__font-size;
  line-height: 1;
  white-space: nowrap;
  text-align: $flyout-clickout-default__text-align;
}

@mixin sustainable-logo {
  width: 80px;
  height: 80px;
  background-image: $flyout-logo-green;
  background-repeat: no-repeat;
  background-size: contain;
}

.flyout {
  position: fixed;
  right: 0;
  bottom: $flyout__bottom;
  z-index: 100;
  display: grid;
  grid-template-columns: $flyout__grid-template-column;
  align-items: $flyout__align-items;
  width: $flyout__width;
  height: auto;
  min-height: $flyout__min-height;
  padding: $flyout__padding;
  color: $flyout__color;
  font-family: $flyout__font;
  background-color: $flyout__background;
  border-radius: $flyout__border-radius;
  box-shadow: $flyout__box-shadow;
  transform: translateX(150%);
  transition: transform ease-in-out 0.3s;

  @media #{$xs} {
    grid-template-areas: $flyout__grid-template-areas--mobile;
    justify-items: $flyout__justify-items--mobile;
    width: $flyout__width--mobile;
    height: $flyout__height--mobile;
    padding: $flyout__padding--mobile;
  }

  &::before {
    position: absolute;
    left: 0;
    width: $flyout-before__width;
    height: 100%;
    background-color: $flyout-before__color;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 16.2602L17.7946 16.0751C17.212 15.5448 16.702 14.9368 16.2785 14.2674C15.816 13.3435 15.5387 12.3345 15.4631 11.2997V8.25193C15.4671 6.6266 14.8899 5.05572 13.8399 3.83443C12.7899 2.61314 11.3395 1.82552 9.76107 1.61954V0.82365C9.76107 0.605205 9.67612 0.395706 9.52489 0.241242C9.37367 0.0867773 9.16856 0 8.9547 0C8.74083 0 8.53573 0.0867773 8.3845 0.241242C8.23328 0.395706 8.14832 0.605205 8.14832 0.82365V1.63188C6.58405 1.85271 5.15112 2.64509 4.11495 3.86228C3.07878 5.07946 2.50957 6.63896 2.51275 8.25193V11.2997C2.4371 12.3345 2.15987 13.3435 1.69732 14.2674C1.28107 14.9351 0.779312 15.5431 0.205369 16.0751L0 16.2602V18H18V16.2602Z' fill='white'/%3E%3Cpath d='M7 19C7.06681 19.2772 7.30613 19.5311 7.67374 19.7149C8.04136 19.8987 8.51245 20 9 20C9.48755 20 9.95864 19.8987 10.3263 19.7149C10.6939 19.5311 10.9332 19.2772 11 19H7Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: $flyout-before__border-radius;
    content: "";

    @media #{$xs} {
      height: $flyout-before__height--mobile;
    }
  }

  .coupon__logo--green {
    @include sustainable-logo;
  }

  &--sustainable {
    .coupon__logo--green {
      @include sustainable-logo;
    }
  }

  &__close-btn {
    position: absolute;
    top: $flyout-close-btn__top;
    right: $flyout-close-btn__right;
    width: $flyout-close-btn__width;
    height: $flyout-close-btn__height;
    background-color: $flyout__close-button;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.6666 14.6667L1.33325 1.33334M14.6666 1.33334L1.33325 14.6667' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    border-radius: $flyout-close-btn__border-radius;
    cursor: pointer;
    content: "";

    &:hover {
      background-color: $flyout__close-button-hover;
    }
  }

  &--visible {
    transform: translateX(0);
  }

  &--hide {
    transform: (translateX(calc(100% - 30px)));
  }

  &--default {
    padding: $flyout-clickout-default__padding;
  }

  &__logo {
    display: grid;
    align-content: center;
    width: $flyout-logo__width;
    height: $flyout-logo__height;
    color: $flyout__logo-color;

    @media #{$xs} {
      grid-area: logo;
    }

    &--default {
      height: $flyout-logo-default__height;
      border-right: $flyout-logo-default__border-right;
    }

    &--first {
      background-color: $flyout-logo-first__background-color;
    }
  }

  &__amount {
    font-weight: 700;
    line-height: $flyout-amount__line-height;
  }

  &__type {
    line-height: $flyout-type__line-height;
  }

  &__text {
    padding: $flyout-text__padding;
    font-size: $flyout-text__font-size;
    line-height: $flyout-text__line-height;
    text-align: $flyout-text__text-align;

    @media #{$xs} {
      grid-area: text;
      padding: $flyout-text__padding--mobile;
      font-size: $flyout-text__font-size--mobile;
      text-align: $flyout-text__text-align--mobile;
    }

    &::first-line {
      font-weight: 700;
    }
  }

  &__clickout {
    display: grid;
    align-items: center;
    justify-content: center;
    width: $flyout-clickout__width;
    height: $flyout-clickout__height;
    background: $flyout-clickout__background;
    border-radius: $flyout-clickout__border-radius;
    cursor: pointer;

    @media #{$xs} {
      grid-area: btn;
    }

    &--default {
      @include flyout-clickout;

      border: $flyout-clickout-default__border;
      border-radius: $flyout-clickout-default__border-radius;

      @media #{$xs} {
        padding: $flyout-clickout-default__padding--mobile;
        font-size: $flyout-clickout-default__font-size--mobile;
      }

      &:hover {
        background-color: $flyout-clickout-default__bg-color--mobile-hover;
      }
    }

    &--first {
      @include flyout-clickout;

      &:hover {
        background-color: $flyout-clickout-default__bg-color--mobile-hover;

        .flyout__button {
          color: $flyout-clickout-first-btn-hover__color;
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
  }

  &__button {
    color: $flyout-button__color;
    font-size: $flyout-button__font-size;
  }
}
