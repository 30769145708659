$shop-page-title__font-size: 70px !default;
$shop-page-title__font-size--mobile: 22px !default;
$shop-page-title__line-height: 70px !default;
$shop-page-title__line-height--mobile: 26px !default;
$shop-page-title__margin: 20px 0 4px !default;
$shop-page-title__margin--mobile: 0 0 0 8px !default;
$shop-page-subtitle__font-size: 26px !default;
$shop-page-subtitle__font-size--mobile: 16px !default;
$shop-page-subtitle__line-height: 34px !default;
$shop-page-subtitle__line-height--mobile: 20px !default;
$shop-page-subtitle__margin: 0 0 10px !default;
$shop-page-subtitle__margin--mobile: 0 0 0 8px !default;
$main-titles__grid-template-areas: "logo title" "logo subtitle" !default;
$main-titles__grid-template-columns: max-content !default;
$main-titles__align-content: center !default;
$main-titles__margin: 0 0 20px !default;
$main-titles__border: none !default;
$page__margin: 30px 0 !default;
$page__bg-color: $color-white !default;
$page__border: none !default;
$page__border-width: 1px 1px 2px 1px !default;
$page__border-radius: 2px !default;
$page__header-padding: 0 !default;
$page-title__font-size: 70px !default;
$page-title__line-height: 90px !default;
$page-content__padding: 0 !default;
$page-content__font-size: 14px !default;
$page-content__line-height: 22px !default;

.page {
  margin: $page__margin;
  background-color: $page__bg-color;
  border: $page__border;
  border-width: $page__border-width;
  border-radius: $page__border-radius;

  &-img {
    grid-area: logo;
    margin: auto;
    margin: 0;

    @media #{$min-lg} {
      display: none;
    }
  }

  &-title {
    grid-area: title;
    margin: $shop-page-title__margin;
    color: $color-black;
    font-size: $shop-page-title__font-size;
    line-height: $shop-page-title__line-height;

    @media #{$lg} {
      margin: 0 0 4px;
      font-size: $shop-page-title__font-size--mobile;
      line-height: $shop-page-title__line-height--mobile;
    }
  }

  &-subtitle {
    grid-area: subtitle;
    margin: $shop-page-subtitle__margin;
    color: $color-black;
    font-weight: 500;
    font-size: $shop-page-subtitle__font-size;
    line-height: $shop-page-subtitle__line-height;

    @media #{$lg} {
      font-size: $shop-page-subtitle__font-size--mobile;
      line-height: $shop-page-subtitle__line-height--mobile;
    }

    @media #{$md} {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__header {
    padding: $page__header-padding;
  }

  &__title {
    margin: 0 0 30px;
    font-weight: 800;
    font-size: $page-title__font-size;
    line-height: $page-title__line-height;

    @media #{$md} {
      margin: 0 0 20px;
      font-size: 22px;
      line-height: 26px;
    }
  }

  &__content {
    margin-bottom: 30px;
    padding: $page-content__padding;
    font-weight: 400;
    font-size: $page-content__font-size;
    line-height: $page-content__line-height;

    @media #{$md} {
      margin-bottom: 20px;
      line-height: 20px;
    }

    h2 {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 26px;
      line-height: 34px;

      @media #{$md} {
        font-size: 18px;
        line-height: 24px;
      }
    }

    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;

      @media #{$md} {
        font-size: 14px;
        line-height: 20px;
      }
    }

    a {
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        color: $color-su-bright-green;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.pages #content {
  order: 0;
}

#main-titles {
  display: grid;
  grid-template-areas: $main-titles__grid-template-areas;
  grid-template-columns: $main-titles__grid-template-columns;
  align-content: $main-titles__align-content;
  margin: $main-titles__margin;
  padding: 0;
  border: none;

  @media #{$min-md} and #{$lg} {
    padding: 25px 0;
  }

  @media #{$md} {
    margin: 10px 0;
  }

  img {
    width: 90px;
  }
}

.shops.show {
  #main-titles {
    @media #{$lg} {
      border: $main-titles__border;
    }

    .page {
      &-title {
        @media #{$lg} {
          margin: $shop-page-title__margin--mobile;
        }
      }

      &-subtitle {
        @media #{$lg} {
          margin: $shop-page-subtitle__margin--mobile;
        }
      }
    }
  }
}
