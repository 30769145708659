$toplinks__padding: 34px 0 !default;
$toplinks__padding--mobile: 23px 0 14px !default;
$toplinks-btn__width--mobile: 223px !default;
$toplinks-btn__font-size: 14px !default;
$toplinks-btn__margin: 0 3px !default;
$toplinks-btn__margin--mobile: 0 auto 10px !default;
$toplinks-btn__padding: 6px 24px !default;
$toplinks-btn__color: #757575 !default;
$toplinks-btn__color--hover: #000 !default;
$toplinks-btn__text-transform: uppercase !default;
$toplinks-btn__border: 1px solid transparentize($border-color, 0.7) !default;
$toplinks-btn__border-radius: 4px !default;
$toplinks-btn__background--hover: transparentize($color-white, 0.95) !default;

.top-links {
  padding: $toplinks__padding;

  @media #{$md} {
    padding: $toplinks__padding--mobile;
  }

  &__inner {
    text-align: center;
  }

  &__btn {
    display: inline-block;
    margin: $toplinks-btn__margin;
    padding: $toplinks-btn__padding;
    color: $toplinks-btn__color;
    font-size: $toplinks-btn__font-size;
    text-transform: $toplinks-btn__text-transform;
    background: transparent;
    border: $toplinks-btn__border;
    border-radius: $toplinks-btn__border-radius;

    &:hover {
      color: $toplinks-btn__color--hover;
      background: $toplinks-btn__background--hover;
    }

    @media #{$md} {
      width: $toplinks-btn__width--mobile;
      margin: $toplinks-btn__margin--mobile;
    }
  }
}
