$premium-offers-offers__margin: 0 -15px 40px !default;
$premium-offers-offers__margin--mobile: 0 -12px 20px !default;
$premium-offers-widget__margin-top: 50px !default;
$premium-offer__height: 400px !default;
$premium-offer__margin: 15px 0 !default;
$premium-offer__background-color: $color-white !default;
$premium-offer__border: $default-box__border !default;
$premium-offer__box-shadow: $su-box-shadow-light-2 !default;
$premium-offer-link-wrp__color: $color-black !default;
$premium-offer-body__width: 63% !default;
$premium-offer-body__padding: 12px 28px !default;
$premium-offer-body__padding--large: 12px 18px !default;
$premium-offer-body__width--desktop: 85% !default;
$premium-offer-action__background-color: $color-bloody-red !default;
$premium-offer-action--hover__background-color: #A7252D !default;
$premium-offer-category__padding-left: 20px !default;
$premium-offer-category__font-size: 12px !default;
$premium-offer-category-before__top: 4px !default;
$premium-offer-category-before__left: 0 !default;
$premium-offer-category-before__width: 10px !default;
$premium-offer-category-before__height: 10px !default;
$premium-offer-headline__margin-top: 20px !default;
$premium-offer-headline__font-weight: 700 !default;
$premium-offer-headline__font-size: 18px !default;
$premium-offer-headline__font-size--mobile: 16px !default;
$premium-offer-headline__line-height: 26px !default;
$premium-offer-headline__line-height--mobile: 20px !default;
$premium-offer-description__min-height: 17px !default;
$premium-offer-description__max-height: 155px !default;
$premium-offer-description__margin-top: 45px !default;
$premium-offer-description__font-size: 14px !default;
$premium-offer-description__font-size--mobile: 14px !default;
$premium-offer-description__line-height: 22px !default;
$premium-offer-description__line-height--mobile: 20px !default;
$premium-offer-hr__margin-top: 17px !default;
$premium-offer-hr__margin-bottom: 0 !default;
$premium-offer-hr__border: 0 !default;
$premium-offer-hr__border-top: 1px solid #CACACA !default;
$premium-offer-cta__margin: auto auto 0 !default;
$premium-offer-cta__padding: 2px 10px !default;
$premium-offer-cta__color: $color-white !default;
$premium-offer-cta__font-size: 14px !default;
$premium-offer-cta__background-color: $color-su-saturated-green !default;
$premium-offer-cta__border: $default-box__border !default;
$premium-offer-cta__border-radius: $default-btn__border-radius !default;
$premium-offer-see-details__margin-top: 15px !default;
$premium-offer-see-details__font-size: 13px !default;
$premium-offer-see-details__color: $themed--color-8 !default;
$premium-offer-see-details-before__top: 4px !default;
$premium-offer-see-details-before__right: -9px !default;
$premium-offer-see-details-before__font-size: 9px !default;
$premium-offer-see-details-before__content: "\e906" !default;
$premium-offer-bg-right-offer-100__background-position--large: right -50px center !default;
$premium-offer-bg-right-offer-100__background-position--desktop: right -150px center !default;
$premium-offer-bg-right-offer-discount__right: 33px !default;
$premium-offer-bg-right-offer-50__background-position--desktop: calc(100% + 100px) center !default;
$premium-offer-white-bg__color: $font-color !default;
$premium-offer-opacity__background: rgba(0, 0, 0, 0.48) !default;
$premium-offer-opacity-black__color: $color-black !default;
$premium-offer-opacity-white__color: $color-black !default;

.premium-offers {
  &__header {
    margin-inline: calc(-0.5 * var(--bs-gutter-x));

    .section {
      &-header,
      &-subheader {
        margin: 0;
        color: $color-black;
        font-weight: 600;
        text-align: left;
        text-transform: capitalize;

        @media #{$md} {
          font-weight: 500;
        }
      }

      &-header {
        margin-bottom: 5px;
        font-size: 20px;
        line-height: 24px;

        @media #{$md} {
          font-size: 18px;
        }
      }

      &-subheader {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  &__offers {
    margin: $premium-offers-offers__margin;

    @media #{$md} {
      margin: $premium-offers-offers__margin--mobile;
    }
  }

  & + .premium-widget {
    margin-top: $premium-offers-widget__margin-top;
  }
}

.premium-offer {
  $this: &;

  @include default-box(
    $background: false,
    $border: $premium-offer__border
  );

  position: relative;
  height: $premium-offer__height;
  margin: $premium-offer__margin;
  overflow: hidden;
  color: $color-black;
  background-color: $premium-offer__background-color;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover;
  border-radius: 4px;
  box-shadow: $premium-offer__box-shadow;
  cursor: pointer;

  &__link-wrp {
    color: $premium-offer-link-wrp__color;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: none;
      box-shadow: none;
    }
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: $premium-offer-body__width;
    height: 100%;
    padding: $premium-offer-body__padding;

    @media #{$xl} {
      padding: $premium-offer-body__padding--large;
    }

    @media #{$md} {
      width: $premium-offer-body__width--desktop;
    }
  }

  &__action {
    background-color: $premium-offer-action__background-color;

    &:hover {
      background-color: $premium-offer-action--hover__background-color;
    }
  }

  &__category {
    position: relative;
    display: inline-block;
    padding-left: $premium-offer-category__padding-left;
    font-weight: 700;
    font-size: $premium-offer-category__font-size;
    text-transform: uppercase;

    &::before {
      position: absolute;
      top: $premium-offer-category-before__top;
      left: $premium-offer-category-before__left;
      display: inline-block;
      width: $premium-offer-category-before__width;
      height: $premium-offer-category-before__height;
      border-radius: 50%;
      content: "";
    }
  }

  &__headline {
    z-index: 1;
    margin-top: $premium-offer-headline__margin-top;
    font-weight: $premium-offer-headline__font-weight;
    font-size: $premium-offer-headline__font-size;
    line-height: $premium-offer-headline__line-height;

    @media #{$md} {
      font-size: $premium-offer-headline__font-size--mobile;
      line-height: $premium-offer-headline__line-height--mobile;
    }
  }

  &__description {
    z-index: 1;
    min-height: $premium-offer-description__min-height;
    max-height: $premium-offer-description__max-height;
    margin-top: $premium-offer-description__margin-top;
    overflow: hidden;
    font-size: $premium-offer-description__font-size;
    line-height: $premium-offer-description__line-height;

    @media #{$md} {
      min-height: 54px;
      font-size: $premium-offer-description__font-size--mobile;
      line-height: $premium-offer-description__line-height--mobile;
    }
  }

  &__hr {
    margin-top: $premium-offer-hr__margin-top;
    margin-bottom: $premium-offer-hr__margin-bottom;
    border: $premium-offer-hr__border;
    border-top: $premium-offer-hr__border-top;
  }

  &__cta {
    @include btn(
      $text-transform: none,
      $background: $premium-offer-cta__background-color,
      $background--hover: $color-su-pink,
      $border: $premium-offer-cta__border,
      $border-radius: $premium-offer-cta__border-radius
    );

    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 197px;
    min-height: 36px;
    margin: $premium-offer-cta__margin;
    padding: $premium-offer-cta__padding;
    color: $premium-offer-cta__color;
    font-weight: 700;
    font-size: $premium-offer-cta__font-size;
    line-height: $premium-offer-cta__font-size + 4px;

    @media #{$md} {
      width: 220px;
      min-width: 220px;
    }

    &:hover {
      @media #{$min-md} {
        color: $color-black;
      }
    }

    &--code {
      @include unleashed-code;

      display: flex;
      width: fit-content;
      height: 0;
      margin-inline: auto;
      padding: 0;
      overflow: hidden;

      &::after {
        border: none;
      }

      #{$this}__cta-text {
        z-index: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 32px);
        height: 100%;
        margin: -1px auto -1px -1px;
        background-color: inherit;
        border-right: $default-btn__border;
        border-radius: 0 4px 4px 0;
        transition: width 0.1s ease-in;
      }

      &:hover #{$this}__cta-text {
        @media #{$min-md} {
          width: calc(100% - 38px);
        }
      }
    }
  }

  &__see-details {
    position: relative;
    display: inline-block;
    margin-top: $premium-offer-see-details__margin-top;
    color: $premium-offer-see-details__color;
    font-size: $premium-offer-see-details__font-size;

    &::before {
      position: absolute;
      top: $premium-offer-see-details-before__top;
      right: $premium-offer-see-details-before__right;
      font-size: $premium-offer-see-details-before__font-size;
      font-family: simple, serif;
      content: $premium-offer-see-details-before__content;
    }
  }

  /* Modifiers Start */

  &--bg-left {
    &.premium-offer {
      text-align: right;
      background-position: left center;

      &.premium-offer--50 {
        @media #{$md} {
          background-position: calc(0% - 100px) center;
        }
      }

      &--100 {
        text-align: right;

        @media #{$md} {
          background-position: top;
          background-size: contain;

          .premium-offer__category {
            align-self: flex-start;
          }
        }
      }
    }

    .premium-offer__body {
      float: right;
    }

    .premium-offer__category {
      align-self: flex-end;
    }
  }

  &--bg-right {
    &.premium-offer--100 {
      background-position: right center;

      @media #{$min-md} and #{$lg} {
        background-position: $premium-offer-bg-right-offer-100__background-position--large;
      }

      @media #{$md} {
        background-position: $premium-offer-bg-right-offer-100__background-position--desktop;
      }
    }

    &.premium-offer--100 {
      @media #{$md} {
        background-position: top;
        background-size: contain;
      }

      .offer__discount {
        right: $premium-offer-bg-right-offer-discount__right;
        left: auto;
      }

      .premium-offer__body {
        float: left;
      }
    }

    @media #{$md} {
      &.premium-offer--50 {
        background-position: $premium-offer-bg-right-offer-50__background-position--desktop;
      }
    }
  }

  /* Modifiers End */

  &--opacity {
    &-white,
    &-black {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: $premium-offer-opacity__background;
        content: "";
      }

      .premium-offer__body {
        position: relative;
      }
    }

    &-black {
      color: $premium-offer-opacity-white__color;

      #{$this}__see-details {
        color: $premium-offer-opacity-white__color;
      }
    }

    &-white {
      color: $premium-offer-opacity-black__color;

      #{$this}__see-details {
        color: $premium-offer-opacity-black__color;
      }

      &::before {
        background: rgba(255, 255, 255, 0.78);
      }
    }
  }
}
