$modal-dialog__max-width: 700px !default;
$modal-dialog__margin: 100px auto !default;
$modal-dialog__margin--mobile: 20px auto !default;
$modal-dialog__bg-color: rgba(0, 0, 0, 0.4) !default;
$modal-dialog-close-btn__top: 20px !default;
$modal-dialog-close-btn__right: 24px !default;
$modal-gift-card__template-areas:
  "logo title title title title ."
  ". . expired-text expired-text . ."
  "form form form form form form"
  ". . completed-img completed-img . ."
  ". completed-text completed-text completed-text completed-text ."
  "info-header info-header . . . ."
  "info-box-1 info-box-1 info-box-2 info-box-2 info-box-3 info-box-3"
  "toggle toggle . . . ."
  "terms terms terms terms terms terms" !default;
$modal-gift-card__template-areas--mobile:
  "logo title title"
  "expired-text expired-text expired-text"
  "form form form"
  "completed-img completed-img completed-img"
  "completed-text completed-text completed-text"
  "info-header info-header info-header"
  "info-box-1 info-box-1 info-box-1"
  "info-box-2 info-box-2 info-box-2"
  "info-box-3 info-box-3 info-box-3"
  "toggle toggle ."
  "terms terms terms" !default;
$modal-gift__template-columns: repeat(6, 1fr) !default;
$modal-gift__template-columns--mobile: repeat(3, 1fr) !default;
$modal-gift__padding: 20px 26px 24px !default;
$modal-gift__padding--mobile: 20px 16px 21px !default;
$modal-gift-logo__margin: 24px auto !default;
$modal-gift-logo__border: 1px solid $border-color !default;
$modal-gift-title__margin: 10px 0 0 0 !default;
$modal-gift-title__margin--mobile: 10px 0 0 0 !default;
$modal-gift-title__font-size: 24px !default;
$modal-gift-title__font-size--mobile: 18px !default;
$modal-gift-title__line-height: 32px !default;
$modal-gift-title__line-height--mobile: 22px !default;
$modal-gift-title__text-align: left !default;
$modal-gift-see-details__padding: 0 !default;
$modal-gift-see-details__width: max-content !default;
$modal-gift-see-details__font-weight: 600 !default;
$modal-gift-see-details__font-size: 14px !default;
$modal-gift-see-details__line-height: 16px !default;
$modal-gift-see-details__text-transform: capitalize !default;
$modal-gift-text__margin: 12px 0 10px 0 !default;
$modal-gift-text__font-weight: 400 !default;
$modal-gift-info__font_size: 14px !default;
$modal-gift-info__line-height: 20px !default;
$modal-gift-info-ul__margin: 0 !default;
$modal-gift-info-ul__padding: 0 16px !default;
$modal-gift-expired-text__margin: 22px 0 0 0 !default;
$modal-gift-expired-text__font-weight: 500 !default;
$modal-gift-expired-text__font-size: 18px !default;
$modal-gift-expired-text__line-height: 20px !default;
$modal-gift-expired-text__text-align: center !default;
$modal-gift-form__template-areas:
  ". input input input input ."
  ". privacy-text privacy-text privacy-text privacy-text ."
  ". form-btn form-btn form-btn form-btn ." !default;
$modal-gift-form__template-areas--mobile:
  "input input input input"
  "privacy-text privacy-text privacy-text privacy-text"
  "form-btn form-btn form-btn form-btn" !default;
$modal-gift-form__template-columns: 72px repeat(4, 1fr) 72px !default;
$modal-gift-form__template-columns--mobile: repeat(4, 1fr) !default;
$modal-gift-form__margin: 0 -26px !default;
$modal-gift-form__margin--mobile: 0 -16px !default;
$modal-gift-form__padding: 27px 26px 0 !default;
$modal-gift-form__padding--mobile: 40px 16px 32px !default;
$modal-gift-input__height: 56px !default;
$modal-gift-input__padding: 16px !default;
$modal-gift-input__border: 1px solid $color-cloud-grey !default;
$modal-gift-input-placeholder__font-weight: 400 !default;
$modal-gift-input-placeholder__font-size: 18px !default;
$modal-gift-input-placeholder__line-height: 26px !default;
$modal-gift-submit__width: 100% !default;
$modal-gift-submit__margin: 27px auto 0 !default;
$modal-gift-submit__margin--mobile: 38px 0 0 !default;
$modal-gift-submit__padding: 17px 0 !default;
$modal-gift-submit__color: $color-white !default;
$modal-gift-submit__font-weight: 400 !default;
$modal-gift-submit__font-size: 20px !default;
$modal-gift-submit__line-height: 24px !default;
$modal-gift-submit__text-transform: uppercase !default;
$modal-gift-submit__background-color: $color-bloody-red !default;
$modal-gift-submit__border: 0 !default;
$modal-gift-completed-img__margin: 30px auto 0 !default;
$modal-gift-completed-text__margin: 22px 0 0 !default;
$modal-gift-completed-text__font-weight: 700 !default;
$modal-gift-completed-text__font-size: 18px !default;
$modal-gift-completed-text__line-height: 20px !default;
$modal-gift-completed-text__text-align: center !default;
$modal-gift-privacy__margin: 16px 20px 27px !default;
$modal-gift-privacy__margin--mobile: 15px 15px 20px !default;
$modal-gift-privacy-text__font-size: 15px !default;
$modal-gift-privacy-text__font-size--mobile: 15px !default;
$modal-gift-privacy-text__line-height: 18px !default;
$modal-gift-privacy-text__line-height--mobile: 18px !default;
$modal-gift-info-header__margin: 24px 0 0 !default;
$modal-gift-info-header__font-weight: 400 !default;
$modal-gift-info-header__font-size: 16px !default;
$modal-gift-info-header__line-height: 18px !default;
$modal-gift-info-title__margin: 5px 0 10px !default;
$modal-gift-info-title__margin--mobile: 0 0 6px !default;
$modal-gift-info-title__font-weight: 700 !default;
$modal-gift-info-title__font-weight--mobile: 600 !default;
$modal-gift-info-title__font-size: 16px !default;
$modal-gift-info-title__font-size--mobile: 14px !default;
$modal-gift-info-title__line-height: 17px !default;
$modal-gift-info-title__text-align: center !default;
$modal-gift-info-title__text-align--mobile: left !default;
$modal-gift-info-text__margin: 12px 0 0 !default;
$modal-gift-info-text__margin--mobile: 0 !default;
$modal-gift-info-text__font-weight: 400 !default;
$modal-gift-info-text__font-size: 14px !default;
$modal-gift-info-text__font-size--mobile: 12px !default;
$modal-gift-info-text__line-height: 20px !default;
$modal-gift-info-text__line-height--mobile: 18px !default;
$modal-gift-info-text__text-align: center !default;
$modal-gift-info-text__text-align--mobile: left !default;
$modal-gift-info-img__margin: 0 auto !default;
$modal-gift-box__tempate-areas--mobile:
  "info-img info-title info-title"
  "info-img info-text info-text" !default;
$modal-gift-box__template-columns--mobile: 0.5fr repeat(2, 1fr) !default;
$modal-gift-box__align-items--mobile: center !default;
$modal-gift-box__margin: 20px 0 0 !default;
$modal-gift-box__margin--mobile: 16px 0 0 !default;
$modal-gift-box--1__margin: 0 9px 0 0 !default;
$modal-gift-box--1__margin--mobile: 0 !default;
$modal-gift-box--2__margin: 0 9px !default;
$modal-gift-box--2__margin--mobile: 0 !default;
$modal-gift-box--3__margin: 0 0 0 9px !default;
$modal-gift-box--3__margin--mobile: 0 0 20px !default;

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
  pointer-events: none;

  &--hidden {
    display: none;
  }

  &__dialog {
    @include default-box(
      $background: $modal-dialog__bg-color,
      $border-radius: 0,
      $box-shadow: $su-box-shadow-neo-brutalism);

    position: relative;
    max-width: $modal-dialog__max-width;
    margin: $modal-dialog__margin;
    pointer-events: auto;

    @media #{$xs} {
      margin: $modal-dialog__margin--mobile;
    }
  }

  &__close-btn {
    position: absolute;
    top: $modal-dialog-close-btn__top;
    right: $modal-dialog-close-btn__right;
  }

  &-gift {
    &__body {
      display: grid;
      grid-template-areas: $modal-gift-card__template-areas;
      grid-template-columns: $modal-gift__template-columns;
      padding: $modal-gift__padding;

      @media #{$sm} {
        grid-template-areas: $modal-gift-card__template-areas--mobile;
        grid-template-columns: $modal-gift__template-columns--mobile;
        padding: $modal-gift__padding--mobile;
      }

      .coupon {
        &__see-details {
          grid-area: toggle;
          width: $modal-gift-see-details__width;
          padding: $modal-gift-see-details__padding;
          font-weight: $modal-gift-see-details__font-weight;
          font-size: $modal-gift-see-details__font-size;
          line-height: $modal-gift-see-details__line-height;
          text-transform: $modal-gift-see-details__text-transform;
          cursor: pointer;
        }

        &__text {
          grid-area: terms;
          margin: $modal-gift-text__margin;
          font-weight: $modal-gift-text__font-weight;

          &--full {
            display: inline-block;

            .coupon-info {
              display: block;
            }
          }
        }

        &-info {
          display: none;
          font-size: $modal-gift-info__font-size;
          line-height: $modal-gift-info__line-height;

          ul {
            margin: $modal-gift-info-ul__margin;
            padding: $modal-gift-info-ul__padding;
          }
        }
      }
    }

    &__logo {
      grid-area: logo;
      margin: $modal-gift-logo__margin;
      border: $modal-gift-logo__border;
    }

    &__title {
      grid-area: title;
      width: fit-content;
      margin: $modal-gift-title__margin;
      font-size: $modal-gift-title__font-size;
      line-height: $modal-gift-title__line-height;
      text-align: $modal-gift-title__text-align;

      @media #{$sm} {
        margin: $modal-gift-title__margin--mobile;
        font-size: $modal-gift-title__font-size--mobile;
        line-height: $modal-gift-title__line-height--mobile;
      }
    }

    &__expired-text {
      grid-area: expired-text;
      margin: $modal-gift-expired-text__margin;
      font-weight: $modal-gift-expired-text__font-weight;
      font-size: $modal-gift-expired-text__font-size;
      line-height: $modal-gift-expired-text__line-height;
      text-align: $modal-gift-expired-text__text-align;
    }

    &__form {
      display: grid;
      grid-area: form;
      grid-template-areas: $modal-gift-form__template-areas;
      grid-template-columns: $modal-gift-form__template-columns;
      margin: $modal-gift-form__margin;
      padding: $modal-gift-form__padding;

      @media #{$sm} {
        grid-template-areas: $modal-gift-form__template-areas--mobile;
        grid-template-columns: $modal-gift-form__template-columns--mobile;
        margin: $modal-gift-form__margin--mobile;
        padding: $modal-gift-form__padding--mobile;
      }

      &--hidden {
        display: none;
      }

      &-input {
        grid-area: input;
        height: $modal-gift-input__height;
        padding: $modal-gift-input__padding;
        border: $modal-gift-input__border;

        &::placeholder {
          font-weight: $modal-gift-input-placeholder__font-weight;
          font-size: $modal-gift-input-placeholder__font-size;
          line-height: $modal-gift-input-placeholder__line-height;
        }
      }

      &-submit {
        grid-area: form-btn;
        width: $modal-gift-submit__width;
        margin: $modal-gift-submit__margin;
        padding: $modal-gift-submit__padding;
        color: $modal-gift-submit__color;
        font-weight: $modal-gift-submit__font-weight;
        font-size: $modal-gift-submit__font-size;
        line-height: $modal-gift-submit__line-height;
        text-transform: $modal-gift-submit__text-transform;
        background-color: $modal-gift-submit__background-color;
        border: $modal-gift-submit__border;

        @media #{$sm} {
          margin: $modal-gift-submit__margin--mobile;
        }
      }
    }

    &__completed {
      &-img {
        grid-area: completed-img;
        margin: $modal-gift-completed-img__margin;
        background-repeat: no-repeat;

        &--hidden {
          display: none;
        }
      }

      &-text {
        grid-area: completed-text;
        margin: $modal-gift-completed-text__margin;
        font-weight: $modal-gift-completed-text__font-weight;
        font-size: $modal-gift-completed-text__font-size;
        line-height: $modal-gift-completed-text__line-height;
        text-align: $modal-gift-completed-text__text-align;

        &--hidden {
          display: none;
        }
      }
    }

    &__privacy-policy {
      grid-area: privacy-text;
      margin: $modal-gift-privacy__margin;

      @media #{$sm} {
        margin: $modal-gift-privacy__margin--mobile;
      }

      &-text {
        font-size: $modal-gift-privacy-text__font-size;
        line-height: $modal-gift-privacy-text__line-height;

        @media #{$sm} {
          font-size: $modal-gift-privacy-text__font-size--mobile;
          line-height: $modal-gift-privacy-text__line-height--mobile;
        }
      }
    }

    &__text-completed {
      &--hidden {
        display: none;
      }
    }

    &__img-completed {
      &--hidden {
        display: none;
      }
    }

    &__info {
      &-header {
        grid-area: info-header;
        margin: $modal-gift-info-header__margin;
        font-weight: $modal-gift-info-header__font-weight;
        font-size: $modal-gift-info-header__font-size;
        line-height: $modal-gift-info-header__line-height;
      }

      &-title {
        margin: $modal-gift-info-title__margin;
        font-weight: $modal-gift-info-title__font-weight;
        font-size: $modal-gift-info-title__font-size;
        line-height: $modal-gift-info-title__line-height;
        text-align: $modal-gift-info-title__text-align;

        @media #{$sm} {
          grid-area: info-title;
          margin: $modal-gift-info-title__margin--mobile;
          font-weight: $modal-gift-info-title__font-weight--mobile;
          font-size: $modal-gift-info-title__font-size--mobile;
          text-align: $modal-gift-info-title__text-align--mobile;
        }
      }

      &-text {
        margin: $modal-gift-info-text__margin;
        font-weight: $modal-gift-info-text__font-weight;
        font-size: $modal-gift-info-text__font-size;
        line-height: $modal-gift-info-text__line-height;
        text-align: $modal-gift-info-text__text-align;

        @media #{$sm} {
          grid-area: info-text;
          margin: $modal-gift-info-text__margin--mobile;
          font-size: $modal-gift-info-text__font-size--mobile;
          line-height: $modal-gift-info-text__line-height--mobile;
          text-align: $modal-gift-info-text__text-align--mobile;
        }
      }

      &-img {
        margin: $modal-gift-info-img__margin;
        background-repeat: no-repeat;

        @media #{$sm} {
          grid-area: info-img;
          background-position: center;
        }
      }

      &-box {
        @media #{$sm} {
          display: grid;
          grid-template-areas: $modal-gift-box__tempate-areas--mobile;
          grid-template-columns: $modal-gift-box__template-columns--mobile;
          align-items: $modal-gift-box__align-items--mobile;
        }

        &--1 {
          grid-area: info-box-1;
          margin: $modal-gift-box--1__margin;

          @media #{$sm} {
            margin: $modal-gift-box--1__margin--mobile;
          }
        }

        &--2 {
          grid-area: info-box-2;
          margin: $modal-gift-box--2__margin;

          @media #{$sm} {
            margin: $modal-gift-box--2__margin--mobile;
          }
        }

        &--3 {
          grid-area: info-box-3;
          margin: $modal-gift-box--3__margin;

          @media #{$sm} {
            margin: $modal-gift-box--3__margin--mobile;
          }
        }
      }
    }
  }
}

/* Backdrop */

.backdrop {
  position: fixed;
  inset: 0;
  z-index: 1040;
  display: none;
  background-color: $modal-dialog__bg-color;

  &--visible {
    display: block;

    &::before {
      position: fixed;
      inset: 0;
      z-index: -1;
      backdrop-filter: blur(3px);
      content: "";
    }
  }
}

.modal-open {
  overflow: hidden;
}
