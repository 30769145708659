$themed--border-radius: 4px !default;
$section-header__margin-bottom: 15px;
$section-header__font-weight: 500 !default;
$section-header__font-size: 24px !default;
$section-header__font-size--mobile: 18px !default;
$section-header__line-height: 28px !default;
$section-header__line-height--mobile: 24px !default;
$section-header__uppercase: false !default;
$section-subheader__color: $themed--color-5 !default;
$section-subheader__font-weight: 300 !default;
$section-subheader__font-size: 1rem !default;
$section-subheader__line-height: 1 !default;
$section-subheader__uppercase: true !default;

.section {
  &-header {
    margin-bottom: $section-header__margin-bottom;
    font-weight: $section-header__font-weight;
    line-height: $section-header__line-height;

    @include font-size($section-header__font-size);

    @if $section-header__uppercase {
      text-transform: uppercase;
    }

    @media #{$md} {
      line-height: $section-header__line-height--mobile;

      @include font-size($section-header__font-size--mobile);
    }

    &--inverse {
      color: invert($body__color);
    }
  }

  &-subheader {
    margin: 0 0 7px;
    color: $section-subheader__color;
    font-weight: $section-subheader__font-weight;
    font-size: $section-subheader__font-size;
    line-height: $section-subheader__line-height;

    @if $section-subheader__uppercase {
      text-transform: uppercase;
    }
  }
}
