$coupons__template-areas:
  "head-disclaimer"
  "m-navigation"
  "content"
  "footer" !default;
$coupons__template-areas--mobile: $coupons__template-areas !default;

body {
  &.coupons {
    &.index {
      &.no-topbar {
        --coupons-template-areas:
          "head-disclaimer"
          "m-navigation"
          "content"
          "footer";
        --coupons-template-areas-mobile:
          "head-disclaimer"
          "m-navigation"
          "content"
          "footer";
      }

      --coupons-template-areas:
        "head-disclaimer"
        "topbar"
        "m-navigation"
        "content"
        "footer";
      --coupons-template-areas-mobile:
        "head-disclaimer"
        "topbar"
        "m-navigation"
        "content"
        "footer";

      grid-template-areas: var(--coupons-template-areas);

      @media #{$sm} {
        grid-template-areas: var(--coupons-template-areas-mobile);
      }
    }
  }
}
