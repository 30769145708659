body {
  &.home.index {
    &.no-topbar {
      --home-template-areas:
        "head-disclaimer"
        "m-navigation"
        "headline"
        "featured-shops"
        "content"
        "footer";
      --home-template-areas-mobile:
        "head-disclaimer"
        "m-navigation"
        "headline"
        "featured-shops"
        "content"
        "footer";
    }

    --home-template-areas:
      "head-disclaimer"
      "topbar"
      "m-navigation"
      "headline"
      "featured-shops"
      "content"
      "footer";
    --home-template-areas-mobile:
      "head-disclaimer"
      "topbar"
      "m-navigation"
      "headline"
      "featured-shops"
      "content"
      "footer";

    grid-template-areas: var(--home-template-areas);

    @media #{$sm} {
      grid-template-areas: var(--home-template-areas-mobile);
    }
  }
}
