// Bootstrap variables
$body__font-size: 14px !default;
$enable-responsive-font-sizes: true !default;

// Common variables
$body__color: $color-black !default;
$body__font-size: 14px !default;
$body__background: $color-white !default;
$body__background--light-grey: $color-white !default;
$font-color: $color-grey !default;
$placeholder__color: $color-grey !default;
$link__color: $color-black !default;
$link__color--hover: $color-black !default;

/* Mixin variables */
$font-family--base: Inter, sans-serif !default;

// Default box
$default-box__background: $color-white !default;
$default-box__border: 1px solid $color-black !default;
$default-box__border-radius: 4px !default;
$default-box__box-shadow: none !default;
$default-box__box-shadow--hover: $su-box-shadow-neo-brutalism !default;

// Default btn
$default-btn__min-width: 150px !default;
$default-btn__padding: 0 !default;
$default-btn__padding--large: 0 !default;
$default-btn__color: $color-white !default;
$default-btn__font-weight: 700 !default;
$default-btn__font-size: 14px !default;
$default-btn__font-size--large: 14px !default;
$default-btn__line-height: 30px !default;
$default-btn__text-transform: uppercase !default;
$default-btn__background-color: $color-su-saturated-green !default;
$default-btn__border: 1px solid $color-black !default;
$default-btn__border--hover: 1px solid $color-black !default;
$default-btn__border-radius: 8px !default;
$default-btn__color--hover: $color-black !default;
$default-btn__text-decoration--hover: none !default;
$default-btn__background-color--hover: $color-su-pink !default;
$default-btn__box-shadow: $su-box-shadow-neo-brutalism !default;
$default-btn__box-shadow--hover: none !default;

// Default title
$default-title__color: $color-black !default;

// Coupon tag
$coupon-tag__content: "\e919" !default;
$coupon-tag__color: $body__color !default;
$coupon-tag__font-size: 14px !default;

// Coupon label
$coupon-label__margin: 0 !default;
$coupon-label__color: $color-black !default;
$coupon-label__color--exclusive: $coupon-label__color !default;
$coupon-label__font-size: 14px !default;
$coupon-label__font-size--mobile: 14px !default;
$coupon-label__font-weight: 400 !default;
$coupon-label__text-align: center !default;
$coupon-label__content: "\e916" !default;
$coupon-label-exclusive__content: none !default;

// Coupon code
$coupon-code__margin: auto 0 !default;
$coupon-code__padding: 2px 5px !default;
$coupon-code__color: $color-black !default;
$coupon-code__font-weight: 400 !default;
$coupon-code__font-size: 14px !default;
$coupon-code__line-height: 16px !default;
$coupon-code__text-transform: uppercase !default;
$coupon-code__bg-color: $color-white !default;

/* Grid settings */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1240px,
  xxl: 1440) !default;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px) !default;
$grid-columns: 12 !default;
$grid-gutter-width: 1.5rem !default;
$xxs: "(max-width: 340px)" !default;
$xs: "(max-width: 375px)" !default;
$sm: "(max-width: #{map-get($grid-breakpoints , sm) - 1px})" !default;
$md: "(max-width: #{map-get($grid-breakpoints, md) - 1px})" !default;
$lg: "(max-width: #{map-get($grid-breakpoints, lg) - 1px})" !default;
$xl: "(max-width: #{map-get($grid-breakpoints, xl) - 1px})" !default;
$xxl: "(max-width: #{map-get($grid-breakpoints, xxl) - 1px})" !default;
$min-xs: "(min-width: 375px)";
$min-sm: "(min-width: #{map-get($grid-breakpoints, sm)})" !default;
$min-md: "(min-width: #{map-get($grid-breakpoints, md)})" !default;
$min-lg: "(min-width: #{map-get($grid-breakpoints, lg)})" !default;
$min-xl: "(min-width: #{map-get($grid-breakpoints, xl)})" !default;
$min-xxl: "(min-width: #{map-get($grid-breakpoints, xxl)})" !default;

/* Campaign colors */
$themed--color-1: $color-bean-green !default; // Green label
$themed--color-2: $color-yellow !default; // yellow label
$themed--color-3: $color-primary !default; // buttons bg
$themed--color-4: $color-asphalt !default; // dark-background;
$themed--color-5: $color-barbi-pink !default; // subheader color
$themed--color-6: #605E5E !default;
$themed--color-7: $color-light-grey !default;
$themed--color-8: #5C6469 !default;
$themed--color-9: #F2994A !default;
$themed--color-10: #9B51E0 !default;
$themed--color-11: #EB5757 !default;
$themed--telegraph-green: #285E6A !default;
$themed--independent-red: #D13030 !default;
$themed--social-blue: #4395DA !default;
$themed--gold-premium: #C3851A !default;
$themed--yellow-paste: $color-yellow !default;
$themed--pastel-purple: #8A44FF !default;
$themed--bubblegum-pink: #FD7AFF !default;
$themed--grey: $color-tornado-grey !default;
$themed--white: $color-white !default;
