$sem-header-logo__max-width: 142px !default;
$sem-header-logo__margin: 40px 0 0 -2px !default;
$sem-header__height: 100px !default;
$sem-campaign__align: center !default;
$sem-campaign-logo__max-width: 100px !default;
$sem-campaign-logo__max-width--mobile: 80px !default;
$sem-campaign-logo__margin-top: 0 !default;
$sem-campaign-logo__margin-top--mobile: 20px !default;
$sem-campaign-header__margin: 12px 0 0 !default;
$sem-campaign-header__font-weight: 800 !default;
$sem-campaign-header__font-size: 70px !default;
$sem-campaign-subheader__margin: 6px 0 0 !default;
$sem-campaign-subheader__font-weight: 500 !default;
$sem-campaign-subheader__font-size: 26px !default;
$sem-coupons__margin: 60px 0 !default;
$sem-coupons__margin--mobile: 30px 0 !default;
$sem-coupons-amount__font-size: 30px !default;
$sem-coupons-type__font-size: 30px !default;

body.campaigns.sem {
  min-height: 100vh;

  .header-wrapper {
    grid-area: header;
  }

  .container {
    grid-area: content;
  }

  footer {
    grid-area: footer;
  }
}

.sem-header__logo {
  max-width: $sem-header-logo__max-width;
  margin: $sem-header-logo__margin;
}

.sem-header {
  height: $sem-header__height;
}

.sem-campaign {
  margin-top: 25px;
  text-align: $sem-campaign__align;

  @media #{$sm} {
    margin-top: 0;
  }

  &__logo {
    max-width: $sem-campaign-logo__max-width;
    margin-top: $sem-campaign-logo__margin-top;
    padding: 10px;
    border: 1px solid $color-black;
    border-radius: 4px;

    @media #{$sm} {
      max-width: $sem-campaign-logo__max-width--mobile;
      margin-top: $sem-campaign-logo__margin-top--mobile;
    }
  }

  &__header {
    margin: $sem-campaign-header__margin;
    font-weight: $sem-campaign-header__font-weight;
    font-size: $sem-campaign-header__font-size;
    line-height: 76px;

    @media #{$md} {
      font-size: 22px;
      line-height: 26px;
    }
  }

  &__subheader {
    margin: $sem-campaign-subheader__margin;
    font-weight: $sem-campaign-subheader__font-weight;
    font-size: $sem-campaign-subheader__font-size;
    line-height: 34px;

    @media #{$md} {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.sem-coupons {
  margin: $sem-coupons__margin;

  @media #{$sm} {
    margin: $sem-coupons__margin--mobile;
  }

  .amount {
    display: block;
    margin: 0;
    font-size: $sem-coupons-amount__font-size;
  }

  .type {
    display: block;
    margin: 0;
    margin-bottom: 15px;
    font-size: $sem-coupons-type__font-size;
  }
}

.coupons-grid__item {
  &--highlighted {
    .coupon__action-btn {
      position: relative;
      background: $color-ivory-mist;
      border: 1px solid $color-black;

      &-text {
        max-width: 0;
        visibility: hidden;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        justify-content: center;
        min-width: 100%;
        min-height: 100%;
        padding-right: 0;
        color: $color-black;
        font-size: 14px;
        line-height: 36px;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        border-radius: 28px;
        content: var(--clicked-coupon-text);
        clip-path: none;
      }

      &::after {
        position: absolute;
        top: 54%;
        right: 36px;
        width: auto;
        height: auto;
        padding: 0;
        background: transparent;
        border: none;
        transform: translateY(-50%);
        content: $icon-tick;
      }

      &:hover {
        background: $color-su-pink;

        &::before {
          width: 100%;
        }

        &::after {
          transition: none;
        }
      }
    }
  }
}
