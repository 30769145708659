.roberto {
  font-weight: normal;
  font-family: simple !important; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &-category {
    &-adults::before {
      content: "\e91b";
    }

    &-babies::before {
      content: "\e909";
    }

    &-beauty::before {
      content: "\e907";
    }

    &-four-chips::before {
      content: "\e91c";
    }

    &-cars::before {
      content: "\e91d";
    }

    &-home::before {
      content: $icon-category-home-and-decor;
    }

    &-sport::before {
      content: $icon-category-sport;
    }

    &-technology::before {
      content: $icon-category-electronics;
    }

    &-books::before {
      content: $icon-category-education;
    }

    &-banking::before {
      content: "\e91f";
    }

    &-photo::before {
      content: "\e924";
    }

    &-music::before {
      content: "\e923";
    }

    &-pets::before {
      content: $icon-category-pets;
    }

    &-shoe::before {
      content: "\e918";
    }

    &-live-shopping::before {
      content: "\e901";
    }

    &-eating::before {
      content: $icon-category-food-delivery;
    }

    &-internet::before {
      content: $icon-category-web-services;
    }

    &-gifts::before {
      content: "\e914";
    }

    &-china-bazaar::before {
      content: "\e917";
    }

    &-travel::before {
      content: $icon-category-travel;
    }

    &-department-store::before {
      content: $icon-category-department-store;
    }

    &-hobby::before {
      content: "\e926";
    }

    &-office::before {
      content: "\e927";
    }

    &-fashion::before {
      content: $icon-category-clothing-shops;
    }

    &-health::before {
      content: $icon-category-selfcare-beauty;
    }

    &-monitor::before {
      content: $icon-category-services;
    }
  }

  &-th-large::before {
    content: $icon-category-default;
  }
}
