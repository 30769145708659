$shops-index__margin: 0 0 50px !default;
$shops-index__background-color: $color-white !default;
$shops-index__border: none !default;
$shops-index__border-width: 0 !default;
$shops-index__border-radius: 4px !default;
$shops-index-header__padding: 0 0 30px !default;
$shops-index-title__margin: 0 !default;
$shops-index-title__color: $color-black !default;
$shops-index-title__font-weight: 500 !default;
$shops-index-title__font-size: 26px !default;
$shops-index-content__padding: 0 !default;
$shops-index-h3__color: $color-black !default;
$shops-index-h3__font-weight: 500 !default;
$shops-index-h3__font-size: 20px !default;
$shops-index-category-text__margin: 0 0 15px !default;
$shops-index-category-text__padding: 0 !default;
$shops-index-category-text__font-size: 14px !default;
$shops-index-category-text__line-height: 18px !default;
$shops-index-category-text__max-height--full: 500px !default;
$shops-index-category-text__transition--full: max-height ease-in-out 0.3s !default;
$shops-index-hr__background-color: $color-black !default;

.shops-index {
  margin: $shops-index__margin;
  background-color: $color-white;
  border: $shops-index__border;
  border-width: $shops-index__border-width;
  border-radius: $shops-index__border-radius;

  &__header {
    padding: $shops-index-header__padding;
  }

  &__title {
    margin: $shops-index-title__margin;
    color: $shops-index-title__color;
    font-weight: $shops-index-title__font-weight;
    font-size: $shops-index-title__font-size;
  }

  &__content {
    padding: $shops-index-content__padding;

    h3 {
      color: $shops-index-h3__color;
      font-weight: $shops-index-h3__font-weight;
      font-size: $shops-index-h3__font-size;
    }

    &__list {
      padding-left: 0;
      list-style: none;
    }
  }

  &__category-text {
    margin: $shops-index-category-text__margin;
    padding: $shops-index-category-text__padding;
    font-size: $shops-index-category-text__font-size;
    line-height: $shops-index-category-text__line-height;

    &--full {
      display: inline-block;
      max-height: $shops-index-category-text__max-height--full;
      overflow: hidden;
      transition: $shops-index-category-text__transition--full;
    }

    &--hidden {
      display: none;
      max-height: 0;
    }
  }

  &__list {
    padding-left: 20px;
    columns: 2;
    column-gap: 0;
    list-style-type: disc;

    @media #{$min-sm} {
      columns: 3;
    }

    &--two-cols > li {
      @media #{$min-sm} {
        display: inline-block;
        width: 49%;
      }
    }
  }

  &__item {
    display: list-item;
    width: auto;
    font-size: 18px;
    line-height: 26px;

    @media #{$lg} {
      margin-bottom: 10px;
      line-height: 44px;
    }

    &:hover {
      font-weight: 500;
    }
  }

  hr {
    background-color: $shops-index-hr__background-color;
  }
}
