// Colors
$color-white: #FFF !default;
$color-black: #000 !default;
$color-primary: #E60000 !default;
$color-blue: #0173B9 !default;
$color-bright: #FDC600 !default;
$color-warning: #D73A00 !default;
$color-light-blue: #4AADE9 !default;
$color-cta: #04777B !default;
$color-bright-blue: #0051AD !default;
$color-bloody-red: #BE2A34 !default;
$color-charcoal: #212529 !default;
$border-color: #D3D3D3 !default;
$color-bubblegum: #49AFF9 !default;
$color-light-grey: #F6F6F6 !default;
$color-light-grey-2: #E3E3E3 !default;
$color-light-grey-3: #DCDCDC !default;
$color-bean-green: #27AE60 !default;
$color-yellow: #EECA02 !default;
$color-barbi-pink: #F12850 !default;
$color-tornado-grey: #959595 !default;
$color-storm-blue: #132D56 !default;
$color-ground: #555 !default;
$color-dark-blue: #0A5895 !default;
$color-dark-blue-2: #001C4C !default;
$color-asphalt: #666 !default;
$color-dark-grey: #323232 !default;
$color-cloud-grey: #D8D8D8 !default;
$color-wet-charcoal: #262626 !default;
$color-medium-grey: #E5E5E5 !default;
$color-dark-charcoal: #333 !default;
$color-dark-grey--campaign: #313131 !default;
$light-grey: #CCC !default;
$light-grey-2: #BCBCBC !default;
$light-grey-3: #DDD !default;
$dark-grey: #3E3E3E !default;
$dark-grey-2: #374043 !default;
$dark-grey-3: #404040 !default;
$light-blue: #2180C0 !default;
$color-blue--sky: #367CB7 !default;
$color-grey: #354043 !default;
$color-orange: #FF9448 !default;
$color-mint-green: #33AA8F !default;
$color-midnight-blue: #1A3649 !default;
$color-midnight-blue-2: #031128 !default;
$color-midnight-grey: #2A2A2A !default;
$color-ivory-mist: #F3F3F3 !default;

/* Colors SU */
$color-su-neon-lime: #F2FF26;
$color-su-light-green: #EBEDDE;
$color-su-light-green--50-opacity: #EBEDDE80;
$color-su-bright-green: #00843B;
$color-su-saturated-green: #009252;
$color-su-grey: #707070;
$color-su-light-grey: #EFEFEF;
$color-su-light-grey-2: #E7E7E7;
$color-su-pink: #F3A7CA;
$color-su-deep-blue: #5C8EC0;
$color-su-pale-teal: #9EBFC2;
$color-grey: #808080;
$color-cta: $color-black;

// Box shadow
$su-box-shadow-light: 1px 3px 12px $color-su-light-grey !default;
$su-box-shadow-light-2: 1px 3px 12px $color-su-light-grey-2 !default;
$su-box-shadow-neo-brutalism: 4px 4px $color-black !default;
$su-box-shadow-neo-brutalism-thick: 5px 5px $color-black !default;
$su-box-shadow-triple-black:
  2px 2px 0 0 $color-white,
  2px 2px 0 1px $color-black,
  5px 5px 0 0 $color-white,
  5px 5px 0 1px $color-black !default;
$su-box-shadow-triple-black--mobile:
  1px 1px 0 0 $color-white,
  1px 1px 0 1px $color-black,
  3px 3px 0 0 $color-white,
  3px 3px 0 1px $color-black !default;
