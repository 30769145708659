$discount-bubbles__padding: 55px 0 50px !default;
$discount-bubble__padding: 7px !default;
$discount-bubble__size: 190px !default;
$discount-bubble__background: linear-gradient(36.75deg, #CD042C 14.65%, #FF4D70 77.35%) !default;
$discount-bubble-value__color: $color-white !default;
$discount-bubble-value__font-size: 56px !default;
$discount-bubble-value__font-weight: 600 !default;
$discount-bubble-value__line-height: 0.7 !default;
$discount-bubble-value__border-bottom: 1px solid #C01837 !default;
$discount-bubble-percent__font-size: 28px !default;

.discount-bubbles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 55px 15px 50px;

  @media #{$xl} {
    width: 1040px;
    margin: 0 auto;
  }

  &__wrapper {
    max-width: map-get($container-max-widths, "xxl");
    margin: 0 auto;

    @media #{$lg} {
      max-width: 100vw;
      margin: 0 - $grid-gutter-width / 2;
      overflow-x: scroll;
    }
  }
}

.discount-bubble {
  position: relative;
  width: $discount-bubble__size;
  height: $discount-bubble__size;
  margin: 5px;
  padding-top: 43px;
  text-align: center;
  background: $discount-bubble__background;
  border-radius: 50%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

  &::before {
    position: absolute;
    top: $discount-bubble__padding;
    left: $discount-bubble__padding;
    display: block;
    box-sizing: border-box;
    width: $discount-bubble__size - 2 * $discount-bubble__padding;
    height: $discount-bubble__size - 2 * $discount-bubble__padding;
    border: 2px solid white;
    border-radius: 50%;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15), inset 2px 2px 2px rgba(0, 0, 0, 0.15);
    content: "";
  }

  &__value {
    display: inline-block;
    width: 63%;
    padding-bottom: 29px;
    color: $discount-bubble-value__color;
    font-weight: $discount-bubble-value__font-weight;
    font-size: $discount-bubble-value__font-size;
    line-height: $discount-bubble-value__line-height;
    white-space: nowrap;
    text-shadow: 1px 0 1px rgba(105, 81, 85, 0.5);
    word-spacing: -10px;
    border-bottom: $discount-bubble-value__border-bottom;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  }

  &__percent {
    font-size: $discount-bubble-percent__font-size;
    vertical-align: top;
  }

  &__logo {
    display: block;
    max-width: 80px;
    max-height: 36px;
    margin: 13px auto 0;
    filter: brightness(0) invert(1);
  }
}
