$headline-padding: 0 !default;
$headline-padding--mobile: 20px 0 10px !default;
$headline-text-align: left !default;
$headline-background-color: transparent !default;
$headline-title__margin: 0 !default;
$headline-title__color: $color-black !default;
$headline-title__font-weight: 800 !default;
$headline-title__font-size: 70px !default;
$headline-title__font-size--tablet: 22px !default;
$headline-title__line-height: 76px !default;
$headline-title__line-height--tablet: 26px !default;
$headline-subtitle__margin: 6px 0 0 !default;
$headline-subtitle__color: $color-black !default;
$headline-subtitle__font-weight: 500 !default;
$headline-subtitle__font-size: 26px !default;
$headline-subtitle__font-size--tablet: 16px !default;
$headline-subtitle__line-height: 34px !default;
$headline-subtitle__line-height--tablet: 20px !default;

.headline {
  grid-area: headline;
  padding: $headline-padding;
  text-align: $headline-text-align;
  background-color: $headline-background-color;

  @media #{$min-md} {
    height: 100%;
  }

  @media #{$md} {
    padding: $headline-padding--mobile;
  }

  &__title {
    margin: $headline-title__margin;
    color: $headline-title__color;
    font-weight: $headline-title__font-weight;
    font-size: $headline-title__font-size;
    line-height: $headline-title__line-height;

    @media #{$md} {
      font-size: $headline-title__font-size--tablet;
      line-height: $headline-title__line-height--tablet;
    }
  }

  &__subtitle {
    margin: $headline-subtitle__margin;
    color: $headline-subtitle__color;
    font-weight: $headline-subtitle__font-weight;
    font-size: $headline-subtitle__font-size;
    line-height: $headline-subtitle__line-height;

    @media #{$md} {
      font-size: $headline-subtitle__font-size--tablet;
      line-height: $headline-subtitle__line-height--tablet;
    }
  }

  .home & {
    @media #{$lg} {
      padding: $headline-padding--mobile;
    }

    &__title {
      @media #{$lg} {
        font-size: $headline-title__font-size--tablet;
        line-height: $headline-title__line-height--tablet;
      }
    }

    &__subtitle {
      @media #{$lg} {
        font-size: $headline-subtitle__font-size--tablet;
        line-height: $headline-subtitle__line-height--tablet;
      }
    }
  }
}
