$mail-form__margin: 30px 0 0 !default;
$mail-form__margin--mobile: 20px 0 0 !default;
$mail-form__padding: 30px !default;
$mail-form__padding--mobile: 15px 15px 30px !default;
$mail-form__background-color: $color-white !default;
$mail-form__border: 1px solid $color-black !default;
$mail-form__border-bottom-width: 0 !default;
$mail-form__border-radius: 4px 4px 0 0 !default;
$mail-form-header__padding: 0 !default;
$mail-form-header__padding--mobile: 0 !default;
$mail-form-title__margin: 0 0 4px !default;
$mail-form-title__margin--mobile: 0 0 4px !default;
$mail-form-title__color: $color-black !default;
$mail-form-title__font-weight: 700 !default;
$mail-form-title__font-size: 26px !default;
$mail-form-title__font-size--mobile: 22px !default;
$mail-form-title__line-height: 34px !default;
$mail-form-title__line-height--mobile: 26px !default;
$mail-form-content__padding: 0 !default;
$mail-form-content__font-size: 14px !default;
$mail-form-content__line-height: 12px !default;
$mail-form-content-label__margin: 0 0 5px !default;
$mail-form-content-label__font-weight: 500 !default;
$mail-form-content-label__font-size: 16px !default;
$mail-form-content-label__line-height: 24px !default;
$mail-form-text__margin: 0 0 15px !default;
$mail-form-text__margin--mobile: 0 0 15px !default;
$mail-form-text__padding: 0 !default;
$mail-form-text__font-weight: 500 !default;
$mail-form-text__font-size: 20px !default;
$mail-form-text__font-size--mobile: 16px !default;
$mail-form-text__line-height: 28px !default;
$mail-form-text__line-height--mobile: 20px !default;
$mail-form-text__border-bottom: none !default;
$mail-form-group__display: grid !default;
$mail-form-group__template-areas: "label" "input" !default;
$mail-form-group__template-columns: auto !default;
$mail-form-group__margin-bottom: 15px !default;
$mail-form-control__height: 146px !default;
$mail-form-control__padding: 10px 15px !default;
$mail-form-control__font-size: 14px !default;
$mail-form-control__line-height: 22px !default;
$mail-form-control__background-color: $color-white !default;
$mail-form-control__border: 1px solid $color-black !default;
$mail-form-control__border-radius: 4px !default;
$mail-form-control__box-shadow: none !default;
$mail-form-control-not-textarea__height: 38px !default;
$mail-form-control-not-textarea__line-height: 12px !default;
$mail-form-button__min-width: 197px !default;
$mail-form-button__margin: auto !default;
$mail-form-button__padding: 3px 0 !default;
$mail-form-button__color: $color-white !default;
$mail-form-button__font-weight: 700 !default;
$mail-form-button__font-size: 14px !default;
$mail-form-button__line-height: 30px !default;
$mail-form-button__bg-color: $color-su-bright-green !default;
$mail-form-button__bg-color--hover: $color-su-pink !default;
$mail-form-button__border: 1px solid $color-black !default;
$mail-form-button__border-radius: 4px !default;
$mail-form-alert__margin-bottom: 20px !default;
$mail-form-alert__padding: 15px !default;
$mail-form-alert__border: 1px solid $color-su-bright-green !default;
$mail-form-alert__border-radius: 4px !default;
$mail-form-alert-info__color: $color-black !default;
$mail-form-alert-info__bg-color: transparent !default;
$mail-form-alert-info__border-color: transparent !default;
$privacy-note__margin: 0 0 30px !default;
$privacy-note__margin--mobile: 0 0 20px !default;
$privacy-note__font-size: 14px !default;
$privacy-note-link__font-weight: 500 !default;
$privacy-note-link__text-underline: true !default;

.mail-form {
  margin: $mail-form__margin;
  padding: $mail-form__padding;
  background-color: $mail-form__background-color;
  border: $mail-form__border;
  border-bottom-width: $mail-form__border-bottom-width;
  border-radius: $mail-form__border-radius;
  box-shadow: $su-box-shadow-neo-brutalism;

  @media #{$md} {
    margin: $mail-form__margin--mobile;
    padding: $mail-form__padding--mobile;
  }

  &__header {
    padding: $mail-form-header__padding;

    @media #{$md} {
      padding: $mail-form-header__padding--mobile;
    }
  }

  &__title {
    margin: $mail-form-title__margin;
    color: $mail-form-title__color;
    font-weight: $mail-form-title__font-weight;
    font-size: $mail-form-title__font-size;
    line-height: $mail-form-title__line-height;
    text-align: center;
    text-transform: capitalize;

    @media #{$md} {
      margin: $mail-form-title__margin--mobile;
      font-size: $mail-form-title__font-size--mobile;
      line-height: $mail-form-title__line-height--mobile;
    }
  }

  &__content {
    padding: $mail-form-content__padding;
    font-weight: 300;
    font-size: $mail-form-content__font-size;
    line-height: $mail-form-content__line-height;

    label {
      display: inline-block;
      max-width: 100%;
      margin: $mail-form-content-label__margin;
      font-weight: $mail-form-content-label__font-weight;
      font-size: $mail-form-content-label__font-size;
      line-height: $mail-form-content-label__line-height;
    }
  }

  &__text {
    margin: $mail-form-text__margin;
    padding: $mail-form-text__padding;
    font-weight: $mail-form-text__font-weight;
    font-size: $mail-form-text__font-size;
    line-height: $mail-form-text__line-height;
    text-align: center;
    border-bottom: $mail-form-text__border-bottom;

    @media #{$md} {
      margin: $mail-form-text__margin--mobile;
      font-size: $mail-form-text__font-size--mobile;
      line-height: $mail-form-text__line-height--mobile;
    }
  }

  &__group {
    display: $mail-form-group__display;
    margin-bottom: $mail-form-group__margin-bottom;

    @if $mail-form-group__display == grid {
      grid-template: $mail-form-group__template-areas;
      grid-template-columns: $mail-form-group__template-columns;
    }
  }

  &__control {
    display: block;
    width: 100%;
    height: $mail-form-control__height;
    padding: $mail-form-control__padding;
    font-size: $mail-form-control__font-size;
    line-height: $mail-form-control__line-height;
    background-color: $mail-form-control__background-color;
    background-image: none;
    border: $mail-form-control__border;
    border-radius: $mail-form-control__border-radius;
    box-shadow: $mail-form-control__box-shadow;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  &__control:not(textarea) {
    height: $mail-form-control-not-textarea__height;
    line-height: $mail-form-control-not-textarea__line-height;
  }

  &__button {
    position: relative;
    z-index: 1;
    display: block;
    min-width: $mail-form-button__min-width;
    margin: $mail-form-button__margin;
    padding: $mail-form-button__padding;
    color: $mail-form-button__color;
    font-weight: $mail-form-button__font-weight;
    font-size: $mail-form-button__font-size;
    line-height: $mail-form-button__line-height;
    text-transform: uppercase;
    background-color: $mail-form-button__bg-color;
    background-image: $icon-for-mail-button;
    background-repeat: no-repeat;
    background-position: 80% 50%;
    border: $mail-form-button__border;
    border-radius: $mail-form-button__border-radius;
    box-shadow: $su-box-shadow-neo-brutalism;
    cursor: pointer;

    &:hover {
      color: $color-black;
      background-color: $mail-form-button__bg-color--hover;
      background-image: $icon-for-mail-button--hover;
      border-color: $color-black;
    }
  }
}

.privacy-note {
  margin: $privacy-note__margin;
  padding: 0 0 30px;
  font-size: $privacy-note__font-size;
  text-align: center;
  background-color: $color-white;
  border-color: $color-black;
  border-style: solid;
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;
  box-shadow: $su-box-shadow-neo-brutalism;

  @media #{$md} {
    margin: $privacy-note__margin--mobile;
  }

  @media #{$sm} {
    padding: 0 15px 30px;
  }

  a {
    font-weight: $privacy-note-link__font-weight;

    @if $privacy-note-link__text-underline {
      text-decoration: underline;
    }
  }
}

.alert {
  margin-bottom: $mail-form-alert__margin-bottom;
  padding: $mail-form-alert__padding;
  border: $mail-form-alert__border;
  border-radius: $mail-form-alert__border-radius;

  &-info {
    color: $mail-form-alert-info__color;
    font-weight: 500;
    text-align: center;
    background-color: $mail-form-alert-info__bg-color;
    border-color: $mail-form-alert-info__border-color;
  }
}

.mail_forms {
  display: block;
}
