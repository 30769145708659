$categories__template-areas:
  "head-disclaimer"
  "topbar"
  "m-navigation"
  "m-search-box"
  "content"
  "footer" !default;
$categories__template-areas--mobile: $categories__template-areas !default;
$categories-show__template-areas:
  "head-disclaimer"
  "m-navigation"
  "featured-shops"
  "content"
  "footer" !default;
$categories-show__template-areas--mobile: $categories-show__template-areas !default;

body {
  &.categories {
    &.index {
      &.no-topbar {
        --categories-index-template-areas:
          "head-disclaimer"
          "m-navigation"
          "m-search-box"
          "content"
          "footer";
        --categories-index-template-areas-mobile:
          "head-disclaimer"
          "m-navigation"
          "m-search-box"
          "content"
          "footer";
      }

      --categories-index-template-areas:
        "head-disclaimer"
        "topbar"
        "m-navigation"
        "m-search-box"
        "content"
        "footer";
      --categories-index-template-areas-mobile:
        "head-disclaimer"
        "topbar"
        "m-navigation"
        "m-search-box"
        "content"
        "footer";

      grid-template-areas: var(--categories-index-template-areas);

      @media #{$sm} {
        grid-template-areas: var(--categories-index-template-areas);
      }
    }

    &.show {
      &.no-topbar {
        --categories-show-template-areas:
          "head-disclaimer"
          "m-navigation"
          "m-search-box"
          "content"
          "footer";
        --categories-show-template-areas-mobile:
          "head-disclaimer"
          "m-navigation"
          "m-search-box"
          "content"
          "footer";
      }

      --categories-show-template-areas:
        "head-disclaimer"
        "topbar"
        "m-navigation"
        "m-search-box"
        "content"
        "footer";
      --categories-show-template-areas-mobile:
        "head-disclaimer"
        "topbar"
        "m-navigation"
        "headline"
        "featured-shops"
        "content"
        "footer";

      grid-template-areas: var(--categories-show-template-areas);

      @media #{$sm} {
        grid-template-areas: var(--categories-show-template-areas);
      }
    }
  }
}
