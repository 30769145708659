$filter-by-type-header__border-bottom: none !default;
$filter-by-type-title__margin: 0 0 20px !default;
$filter-by-type-title__padding: 20px 20px 15px !default;
$filter-by-type-title__color: $color-black !default;
$filter-by-type-title__font-weight: 700 !default;
$filter-by-type-title__font-size: 26px !default;
$filter-by-type-title__text-align: center !default;
$filter-by-type-title__border: 1px solid $color-black !default;
$filter-by-type-title__border-width: 0 0 1px !default;
$filter-by-type-icon__margin-left: 10px !default;
$filter-by-type-icon__font-size: 8px !default;
$filter-by-type-icon__transform: 0 !default;
$filter-by-type-icon__transform--active: rotate(-90deg) !default;
$filter-by-type-icon__transition: transform ease-in-out 0.2s !default;
$filter-by-type-dropdown__max-height: 0 !default;
$filter-by-type-dropdown__max-height--expanded: 500px !default;
$filter-by-type-dropdown__transition: max-height ease-in-out 0.2s !default;
$filter-by-type-content__padding: 0 !default;
$filter-by-type-content__padding--mobile: 0 !default;
$filter-by-type-li__margin-bottom: 15px !default;
$filter-by-type-a__padding: 20px !default;
$filter-by-type-a__color: $color-black !default;
$filter-by-type-a__color--hover: $color-su-bright-green !default;
$filter-by-type-a__font-weight: 600 !default;
$filter-by-type-a__font-size: 20px !default;
$filter-by-type-a__font-size--mobile: 20px !default;
$filter-by-type-a__text-align: left !default;
$filter-by-type-a__uppercase: false !default;
$filter-by-type-a__bg-color: $color-ivory-mist !default;
$filter-by-type-a__border: none !default;
$filter-by-type-a__transition: background-color 0.3s ease, color 0.3s ease !default;
$filter-by-type-a__text-decoration--hover: underline !default;
$filter-by-type-a__background-color--hover: $color-ivory-mist !default;
$icon-arrow--active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='8' fill='none'%3E%3Cpath stroke='%23F2FF26' stroke-linecap='round' stroke-linejoin='round' d='M1 7.5 4 4 1 .5'/%3E%3C/svg%3E") !default;

.filter-by-type {
  &__header {
    border-bottom: $filter-by-type-header__border-bottom;
  }

  &__title {
    margin: $filter-by-type-title__margin;
    padding: $filter-by-type-title__padding;
    color: $filter-by-type-title__color;
    font-weight: $filter-by-type-title__font-weight;
    font-size: $filter-by-type-title__font-size;
    text-align: $filter-by-type-title__text-align;
    border: $filter-by-type-title__border;
    border-width: $filter-by-type-title__border-width;
  }

  &__toggle {
    *[class^="icon"] {
      display: inline-block;
      margin-left: $filter-by-type-icon__margin-left;
      font-size: $filter-by-type-icon__font-size;
      transform: $filter-by-type-icon__transform;
      transition: $filter-by-type-icon__transition;
    }

    @media #{$min-lg} {
      display: none;
    }

    &--active {
      *[class^="icon"] {
        transform: $filter-by-type-icon__transform--active;
      }
    }
  }

  &__dropdown {
    @media #{$lg} {
      max-height: $filter-by-type-dropdown__max-height;
      overflow: hidden;
      transition: $filter-by-type-dropdown__transition;

      &--expanded {
        max-height: $filter-by-type-dropdown__max-height--expanded;
      }
    }
  }

  &__content {
    padding: $filter-by-type-content__padding;

    @media #{$md} {
      padding: $filter-by-type-content__padding--mobile;
    }

    ul {
      padding-left: 0;
      list-style: none;

      @media #{$md} {
        margin-bottom: 0;
      }

      li {
        margin-bottom: $filter-by-type-li__margin-bottom;
      }

      a {
        display: block;
        padding: $filter-by-type-a__padding;
        color: $filter-by-type-a__color;
        font-weight: $filter-by-type-a__font-weight;
        font-size: $filter-by-type-a__font-size;
        text-align: $filter-by-type-a__text-align;

        @if $filter-by-type-a__uppercase {
          text-transform: uppercase;
        }

        background-color: $filter-by-type-a__bg-color;
        border: $filter-by-type-a__border;
        transition: $filter-by-type-a__transition;

        @include c4r-arrow-link;

        @media #{$md} {
          font-size: $filter-by-type-a__font-size--mobile;
        }

        &:focus,
        &:hover {
          color: $filter-by-type-a__color--hover;
          text-decoration: $filter-by-type-a__text-decoration--hover;
          background-color: $filter-by-type-a__background-color--hover;
        }
      }
    }
  }
}

.coupons.index {
  .filter-by-type {
    &__title {
      line-height: 28px;

      @media #{$md} {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &__content {
      ul a {
        border-radius: 4px;

        &:hover,
        &:active,
        &:focus {
          border-color: $color-su-bright-green;

          .filter-by-type__icon::before {
            content: $icon-arrow--active;
          }
        }
      }
    }

    &__icon {
      &::before {
        content: $icon-toggle-arrow;
      }
    }
  }
}
