@import "filter-by-type";
@import "coupon-type-filter";
@import "coupons-grid";

$coupons-index-content__order: 2 !default;
$coupons-index-sidebar__margin-bottom: 20px !default;

.coupons.index {
  .sidebar {
    padding: 0 26px;
    border: none;
  }

  @media #{$md} {
    #content {
      order: $coupons-index-content__order;
    }

    .sidebar {
      margin-bottom: $coupons-index-sidebar__margin-bottom;
      padding: 0;
    }
  }
}
