body {
  &.shops {
    &.index {
      &.no-topbar {
        --categories-template-areas:
          "head-disclaimer"
          "m-navigation"
          "content"
          "footer";
        --categories-template-areas-mobile:
          "head-disclaimer"
          "m-navigation"
          "content"
          "footer";
      }

      --categories-template-areas:
        "head-disclaimer"
        "topbar"
        "m-navigation"
        "content"
        "footer";
      --categories-template-areas-mobile:
        "head-disclaimer"
        "topbar"
        "m-navigation"
        "content"
        "footer";

      grid-template-areas: var(--categories-template-areas);

      @media #{$sm} {
        grid-template-areas: var(--categories-template-areas-mobile);
      }
    }

    &.show {
      &.no-topbar {
        --shops-show-template-areas:
          "head-disclaimer"
          "m-navigation"
          "content"
          "footer";
        --shops-show-template-areas-mobile:
          "head-disclaimer"
          "m-navigation"
          "content"
          "footer";
      }

      --shops-show-template-areas:
        "head-disclaimer"
        "topbar"
        "m-navigation"
        "content"
        "footer";
      --shops-show-template-areas-mobile:
        "head-disclaimer"
        "topbar"
        "m-navigation"
        "content"
        "footer";

      grid-template-areas: var(--shops-show-template-areas);

      @media #{$sm} {
        grid-template-areas: var(--shops-show-template-areas-mobile);
      }
    }
  }
}
