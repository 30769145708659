$top-x-coupons__margin: 0 -17px !default;
$top-x-coupons-list__padding-left: 0 !default;
$top-x-coupons-item__height: 112px !default;
$top-x-coupons-item__margin-bottom: 10px !default;
$top-x-coupons-item__background: $color-white !default;
$top-x-coupons-img__height: 110px !default;
$top-x-coupons-content__padding: 7px 12px !default;
$top-x-coupons-content__color: $color-grey !default;
$top-x-coupons-title__margin-bottom: 6px !default;
$top-x-coupons-title__color: $color-blue !default;
$top-x-coupons-item-a__grid-template-areas: "img text" !default;
$top-x-coupons-item-a__grid-template-columns: 110px auto !default;

.w-top-x {
  margin: $top-x-coupons__margin;

  &__list {
    padding-left: $top-x-coupons-list__padding-left;
    list-style-type: none;
  }

  &__item {
    height: $top-x-coupons-item__height;
    margin-bottom: $top-x-coupons-item__margin-bottom;
    background: $top-x-coupons-item__background;

    a {
      display: grid;
      grid-template-areas: $top-x-coupons-item-a__grid-template-areas;
      grid-template-columns: $top-x-coupons-item-a__grid-template-columns;
    }
  }

  &__img {
    height: $top-x-coupons-img__height;
  }

  &__content {
    padding: $top-x-coupons-content__padding;
    color: $top-x-coupons-content__color;
  }

  &__title {
    display: block;
    margin-bottom: $top-x-coupons-title__margin-bottom;
    color: $top-x-coupons-title__color;
    text-transform: uppercase;
  }
}
