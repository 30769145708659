$premium-campaign-slider-color: $color-white !default;
$premium-campaign-slider-container__width: 100% !default;
$premium-campaign-slider-container__scroll-snap-type: x mandatory !default;
$premium-campaign-slider-container__scroll-behavior: smooth !default;
$premium-campaign-slider-container__overflow-x: scroll !default;
$premium-campaign-slider-container__overflow-y: hidden !default;
$premium-campaign-slider-arrow__top: 0 !default;
$premium-campaign-slider-arrow__width: 90px !default;
$premium-campaign-slider-arrow__background--hover: rgba(0, 0, 0, 0.48) !default;
$premium-campaign-slider-arrow__background--active: transparent !default;
$premium-campaign-slider-arrow-i__top--before: 50% !default;
$premium-campaign-slider-arrow-i__left--before: 50% !default;
$premium-campaign-slider-arrow-i__width--before: auto !default;
$premium-campaign-slider-arrow-i__margin--before: 0 !default;
$premium-campaign-slider-arrow-i__color--before: $color-white !default;
$premium-campaign-slider-arrow-i__font-size--before: 40px !default;
$premium-campaign-slider-arrow-i__transform--before: translate(-50%, -50%) !default;
$premium-campaign-slider-item__width: 100% !default;
$premium-campaign-slider-item__min-width: 100% !default;
$premium-campaign-slider-item__background-repeat: no-repeat !default;
$premium-campaign-slider-item__background-size: cover !default;
$premium-campaign-slider-item__text-align: center !default;
$premium-campaign-slider-item__padding: 0 0 54px !default;
$premium-campaign-slider-item__scroll-snap-align: center !default;
$premium-campaign-slider-item-before__background-color: rgba(0, 0, 0, 0.3) !default;
$premium-campaign-slider-headline__margin: 15px 3px 4px !default;
$premium-campaign-slider-headline__font-style: normal !default;
$premium-campaign-slider-headline__font-weight: 500 !default;
$premium-campaign-slider-headline__font-size: 34px !default;
$premium-campaign-slider-headline__line-height: 40px !default;
$premium-campaign-slider-description__margin: 0 0 10px !default;
$premium-campaign-slider-description__font-style: normal !default;
$premium-campaign-slider-description__font-weight: 500 !default;
$premium-campaign-slider-description__font-size: 20px !default;
$premium-campaign-slider-description__line-height: 28px !default;
$premium-campaign-slider-logo__margin: 23px 0 0 !default;
$premium-campaign-slider-logo__width: 70px !default;
$premium-campaign-slider-logo__height: 70px !default;
$premium-campaign-slider-logo__width--sm: 40px !default;
$premium-campaign-slider-logo__height--sm: 40px !default;
$premium-campaign-slider-logo__background-color: $color-white !default;
$premium-campaign-slider-logo__border-radius: 4px !default;
$premium-campaign-slider-button__margin-top: 0 !default;
$premium-campaign-slider-button__padding: 3px 14px !default;
$premium-campaign-slider-button__font-weight: 700 !default;
$premium-campaign-slider-button__font-size: 14px !default;
$premium-campaign-slider-button__line-height: 30px !default;
$premium-campaign-slider-button__background-color: $color-su-bright-green !default;
$premium-campaign-slider-button__background-color--hover: $color-su-pink !default;
$premium-campaign-slider-button__border-radius: 30px !default;
$premium-campaign-slider-button-span__color: $color-white !default;
$premium-campaign-slider-indicator-list__margin: 0 0 8px !default;
$premium-campaign-slider-indicator-list__padding: 0 !default;
$premium-campaign-slider-indicator-list__justify-content: center !default;
$premium-campaign-slider-indicator-item__width: 16px !default;
$premium-campaign-slider-indicator-item__height: 16px !default;
$premium-campaign-slider-indicator-item__margin: 15px 8px !default;
$premium-campaign-slider-indicator-item__background-color: #FFFFFF80 !default;
$premium-campaign-slider-indicator-item--active__background-color: $color-white;
$premium-campaign-slider-indicator-item__background-color--hover: $color-white !default;
$premium-campaign-slider-indicator-item__justify-content: center !default;
$premium-campaign-slider-indicator-item__scroll-snap-align: center !default;
$premium-campaign-slider-indicator-item--active__background-color: $color-midnight-blue !default;

.premium-campaign-slider {
  position: relative;
  grid-area: campaign-slider;
  color: $premium-campaign-slider-color;

  &__container {
    display: flex;
    width: $premium-campaign-slider-container__width;
    scroll-snap-type: $premium-campaign-slider-container__scroll-snap-type;
    overflow-x: $premium-campaign-slider-container__overflow-x;
    overflow-y: $premium-campaign-slider-container__overflow-y;
    scroll-behavior: $premium-campaign-slider-container__scroll-behavior;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__arrow {
    position: absolute;
    top: $premium-campaign-slider-arrow__top;
    width: $premium-campaign-slider-arrow__width;
    height: calc(100% - 27px);
    cursor: pointer;

    i {
      &::before {
        position: absolute;
        top: $premium-campaign-slider-arrow-i__top--before;
        left: $premium-campaign-slider-arrow-i__left--before;
        width: $premium-campaign-slider-arrow-i__width--before;
        margin: $premium-campaign-slider-arrow-i__margin--before;
        color: $premium-campaign-slider-arrow-i__color--before;
        font-size: $premium-campaign-slider-arrow-i__font-size--before;
        transform: $premium-campaign-slider-arrow-i__transform--before;
      }
    }

    &:hover,
    &:focus {
      background: $premium-campaign-slider-arrow__background--hover;
    }

    &:active {
      background: $premium-campaign-slider-arrow__background--active;
    }

    @media #{$md} {
      display: none;
    }

    &--left {
      left: 0;

      i::before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }

    &--right {
      right: 0;
    }
  }

  &__item {
    position: relative;
    width: $premium-campaign-slider-item__width;
    min-width: $premium-campaign-slider-item__min-width;
    padding: $premium-campaign-slider-item__padding;
    text-align: $premium-campaign-slider-item__text-align;
    background-repeat: $premium-campaign-slider-item__background-repeat;
    background-size: $premium-campaign-slider-item__background-size;
    scroll-snap-align: $premium-campaign-slider-item__scroll-snap-align;

    & > * {
      position: relative;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $premium-campaign-slider-item-before__background-color;
      content: "";
    }
  }

  &__headline {
    margin: $premium-campaign-slider-headline__margin;
    font-weight: $premium-campaign-slider-headline__font-weight;
    font-size: $premium-campaign-slider-headline__font-size;
    font-style: $premium-campaign-slider-headline__font-style;
    line-height: $premium-campaign-slider-headline__line-height;
  }

  &__description {
    margin: $premium-campaign-slider-description__margin;
    font-weight: $premium-campaign-slider-description__font-weight;
    font-size: $premium-campaign-slider-description__font-size;
    font-style: $premium-campaign-slider-description__font-style;
    line-height: $premium-campaign-slider-description__line-height;
  }

  &__logo {
    display: inline-block;
    width: $premium-campaign-slider-logo__width;
    height: $premium-campaign-slider-logo__height;
    margin: $premium-campaign-slider-logo__margin;
    padding: 5px;
    background-color: $premium-campaign-slider-logo__background-color;
    border-radius: $premium-campaign-slider-logo__border-radius;

    @media #{$sm} {
      width: $premium-campaign-slider-logo__width--sm;
      height: $premium-campaign-slider-logo__height--sm;
    }
  }

  &__img {
    width: auto;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }

  &__button {
    display: inline-block;
    width: 197px;
    height: 36px;
    margin-top: $premium-campaign-slider-button__margin-top;
    padding: $premium-campaign-slider-button__padding;
    font-weight: $premium-campaign-slider-button__font-weight;
    font-size: $premium-campaign-slider-button__font-size;
    line-height: $premium-campaign-slider-button__line-height;
    text-transform: uppercase;
    text-decoration: none;
    background-color: $premium-campaign-slider-button__background-color;
    border-radius: $premium-campaign-slider-button__border-radius;

    &[data-coupon-type="coupon"] {
      @include unleashed-code-old;
    }

    span {
      color: $premium-campaign-slider-button-span__color;
    }

    &:hover {
      text-decoration: none;
      background-color: $premium-campaign-slider-button__background-color--hover;

      span {
        color: inherit;
      }
    }
  }

  &__indicator-list {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: $premium-campaign-slider-indicator-list__justify-content;
    width: 100%;
    margin: $premium-campaign-slider-indicator-list__margin;
    padding: $premium-campaign-slider-indicator-list__padding;
  }

  &__indicator-item {
    justify-content: $premium-campaign-slider-indicator-item__justify-content;
    width: $premium-campaign-slider-indicator-item__width;
    height: $premium-campaign-slider-indicator-item__height;
    margin: $premium-campaign-slider-indicator-item__margin;
    list-style-type: none;
    background-color: $premium-campaign-slider-indicator-item__background-color;
    border: 1px solid $color-su-light-green;
    border-radius: 50%;
    scroll-snap-align: $premium-campaign-slider-indicator-item__scroll-snap-align;
    cursor: pointer;

    &:hover {
      background-color: $premium-campaign-slider-indicator-item__background-color--hover;
    }

    &--active {
      background-color: $premium-campaign-slider-indicator-item--active__background-color;
    }
  }
}
