$hero__min-height: 110px !default;
$hero__min-height--mobile: unset !default;
$hero__padding: 0 15px !default;
$hero__padding--mobile: 0 15px !default;
$hero__template-areas: "h1" "h2" "countdown" !default;
$hero__border: none !default;
$hero__box-shadow: none !default;
$hero-h1__margin: 0 0 6px !default;
$hero-h1__font-size: 62px !default;
$hero-h1__font-size--mobile: 22px !default;
$hero-h1__font-weight: 800 !default;
$hero-h1__line-height: 70px !default;
$hero-h1__line-height--mobile: 26px !default;
$hero-h1__uppercase: false !default;
$hero-h2__margin: 0 !default;
$hero-h2__font-size: 26px !default;
$hero-h2__font-size--mobile: 16px !default;
$hero-h2__font-weight: 500 !default;
$hero-h2__line-height: 34px !default;
$hero-h2__line-height--mobile: 20px !default;
$hero-h2__uppercase: false !default;
$hero-counter__margin: 20px 0 0 !default;
$hero-counter__font-size: 2rem !default;
$hero-counter__font-weight: 700 !default;

.hero {
  grid-area: headline;
  min-height: 371px;
  margin-bottom: 40px;
  padding-bottom: 20px;
  text-align: center;
  background-color: $color-su-pink;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  box-shadow: $hero__box-shadow;

  @media #{$xxl} and #{$min-md} {
    background-position: right;
    background-size: cover;
  }

  @media #{$md} {
    margin-bottom: 20px;
    background-position: right bottom;

    &--only-desktop {
      background-size: cover;
    }
  }

  &--align {
    &-top {
      align-content: start;
    }

    &-middle {
      align-content: center;
    }

    &-bottom {
      align-content: end;
    }

    &-left {
      justify-content: start;
      text-align: left;
    }

    &-center {
      justify-content: center;
      text-align: center;
    }

    &-right {
      justify-content: end;
      text-align: right;
    }
  }

  &__grid {
    display: grid;
    grid-template-areas: $hero__template-areas;
    grid-template-rows: min-content min-content min-content;
    min-height: $hero__min-height;
    padding: $hero__padding;
    border: $hero__border;

    @media #{$md} {
      min-height: $hero__min-height--mobile;
      padding: $hero__padding--mobile;
    }
  }

  &__h1 {
    grid-area: h1;
    margin: $hero-h1__margin;
    font-weight: $hero-h1__font-weight;
    font-size: $hero-h1__font-size;
    line-height: $hero-h1__line-height;

    @if $hero-h1__uppercase {
      text-transform: uppercase;
    }

    @media #{$md} {
      font-size: $hero-h1__font-size--mobile;
      line-height: $hero-h1__line-height--mobile;
    }
  }

  &__h2 {
    grid-area: h2;
    margin: $hero-h2__margin;
    font-weight: $hero-h2__font-weight;
    font-size: $hero-h2__font-size;
    line-height: $hero-h2__line-height;

    @if $hero-h2__uppercase {
      text-transform: uppercase;
    }

    @media #{$md} {
      font-size: $hero-h2__font-size--mobile;
      line-height: $hero-h2__line-height--mobile;
    }
  }

  #counter {
    grid-area: countdown;
    margin: $hero-counter__margin;
    text-align: inherit;

    @media #{$lg} {
      margin-top: 0;
    }
  }

  .countdown {
    display: inline-block;
    margin: 0 -15px;

    &-time {
      font-weight: $hero-counter__font-weight;
      font-size: $hero-counter__font-size;
      line-height: 1;

      td {
        text-align: center;

        span {
          padding: 0 15px;
        }
      }
    }

    &-labels {
      font-size: 0.8em;
      text-transform: uppercase;
      opacity: 0.5;

      td {
        text-align: center;
      }
    }
  }

  .m-search-box {
    &__form {
      max-width: 450px;
      margin: 0 auto;
    }

    &__input {
      font-size: 14px;
      line-height: 12px;
      border-color: $color-su-pink;
      border-radius: 100px;

      &::placeholder {
        font-style: normal;
      }
    }
  }

  .search-header-results {
    left: 50%;
    max-width: 506px;
    transform: translateX(-50%);
  }

  &--no-image {
    background-color: $color-white;
    box-shadow: none;

    .headline {
      &__title,
      &__subtitle {
        color: $color-black;
      }
    }
  }

  .home & {
    @media #{$xxl} and #{$min-lg} {
      background-position: 70% 100%;
      background-size: cover;
    }

    @media #{$lg} {
      margin-bottom: 20px;
      background-position: 50% calc(50% + min(7vw, 7vh));
      background-size: #{"min(100%, 600px)"} auto;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;

      @media #{$lg} {
        align-items: center;
        justify-content: center;
        height: auto;
        text-align: center;
      }
    }

    .col {
      @media #{$min-lg} {
        max-width: 64%;
      }
    }
  }

  .campaigns & {
    min-height: 0;
    margin: 30px 0;
    padding: 0;
    background: transparent;

    @media #{$md} {
      margin: 20px 0;
    }

    &__title {
      &--h1-small {
        margin-bottom: 6px;
        font-weight: $hero-h1__font-weight;
        font-size: $hero-h1__font-size;
        line-height: $hero-h1__line-height;

        @media #{$md} {
          margin-bottom: 4px;
          font-size: $hero-h1__font-size--mobile;
          line-height: $hero-h1__line-height--mobile;
        }
      }

      &--h2-small {
        margin-bottom: 0;
        font-weight: $hero-h2__font-weight;
        font-size: $hero-h2__font-size;
        line-height: $hero-h2__line-height;

        @media #{$md} {
          font-size: $hero-h2__font-size--mobile;
          line-height: $hero-h2__line-height--mobile;
        }
      }
    }
  }
}
