$multipurpose__margin: 40px 0 !default;
$multipurpose__padding: 0 !default;
$multipurpose-row__border: none !default;
$multipurpose-row__margin: 0 0 30px !default;
$multipurpose-row__margin--mobile: none !default;
$multipurpose-row__padding: 15px 15px 0 !default;
$multipurpose-row__padding--mobile: none !default;
$multipurpose-row__color: inherit !default;
$multipurpose-title__margin: 0 0 15px !default;
$multipurpose-title__padding: 0 !default;
$multipurpose-title__font-size: 20px !default;
$multipurpose-title__font-weight: 600 !default;
$multipurpose-title__line-height: 24px !default;
$multipurpose-main-title__color: $color-white !default;
$multipurpose-main-title__margin: 0 0 6px !default;
$multipurpose-main-title__font-weight: 500 !default;
$multipurpose-main-title__font-size: 26px !default;
$multipurpose-main-title__line-height: 34px !default;
$multipurpose-main-subtitle__color: $color-white !default;
$multipurpose-main-subtitle__font-weight: 500 !default;
$multipurpose-main-subtitle__font-size: 18px !default;
$multipurpose-main-subtitle__line-height: 26px !default;
$multipurpose-headline__margin: 15px !default;
$multipurpose-headline__padding: 0 15px !default;
$multipurpose-headline__color: $color-black !default;
$multipurpose-headline__font-weight: 700 !default;
$multipurpose-headline__font-size: 16px !default;
$multipurpose-headline__line-height: 22px !default;
$multipurpose-headline__text-transform: capitalize !default;
$multipurpose-text__margin: 0 0 15px !default;
$multipurpose-text__margin--mobile: 0 0 15px !default;
$multipurpose-text__font-weight: 400 !default;
$multipurpose-text__font-size: 14px !default;
$multipurpose-text__line-height: 22px !default;
$multipurpose-text-a__font-weight: inherit !default;
$multipurpose-text-a__font-size: inherit !default;
$multipurpose-text-a__font-size--mobile: inherit !default;
$multipurpose-text-a__color: $color-cta !default;
$multipurpose-text-a--hover__color: $color-cta !default;
$multipurpose-text-a__text-decoration: underline !default;
$multipurpose-text-a--hover__text-decoration: underline !default;
$multipurpose-text-a__border-bottom: none !default;
$multipurpose-text-a--hover__border-bottom: none !default;
$multipurpose-text-b__color: initial !default;
$multipurpose-text-b__font-weight: 700 !default;
$multipurpose-text-u__text-decoration: underline !default;
$multipurpose-text-u__border-bottom: none !default;
$multipurpose-widget__margin: 0 0 15px !default;
$multipurpose-widget-33-wrap__border: none !default;
$multipurpose-widget-33-wrap__border-radius: 0 !default;
$multipurpose-widget-33-wrap__height: 436px !default;
$multipurpose-widget-33-wrap__height--mobile: 352px !default;
$multipurpose-widget-33-wrap__margin--mobile: 0 !default;
$multipurpose-widget-33-wrap__background: transparent !default;
$multipurpose-widget-33-img__height: 200px !default;
$multipurpose-widget-33-img__height--mobile: 200px !default;
$multipurpose-widget-33-img__bg-position: center !default;
$multipurpose-widget-33-img__bg-size: cover !default;
$multipurpose-widget-33-img__border-width: 1px !default;
$multipurpose-widget-33-img__border-radius: 4px !default;
$multipurpose-widget-33-text__padding: 0 10px !default;
$multipurpose-widget-33-text__webkit-line-clamp: 7 !default;
$multipurpose-widget-33-text__webkit-line-clamp--mobile: 5 !default;
$multipurpose-widget-100-main-hero__align: center !default;
$multipurpose-widget-100-main-hero__justify: center !default;
$multipurpose-widget-100-main-hero__height: 200px !default;
$multipurpose-widget-100-main-hero__border-bottom: none !default;
$multipurpose-widget-50-img__height: 224px !default;
$multipurpose-widget-50-img__height--mobile: 300px !default;
$multipurpose-widget-25-img__height: 200px !default;
$multipurpose-widget-25-img__height--mobile: 212px !default;

.multipurpose-seo {
  $this: &;

  margin: $multipurpose__margin;
  padding: $multipurpose__padding;

  &__main {
    &-title,
    &-subtitle {
      z-index: 1;
    }

    &-title {
      margin: $multipurpose-main-title__margin;
      padding: 0 15px;
      color: $multipurpose-main-title__color;
      font-weight: $multipurpose-main-title__font-weight;
      font-size: $multipurpose-main-title__font-size;
      line-height: $multipurpose-main-title__line-height;
      text-align: center;
    }

    &-subtitle {
      color: $multipurpose-main-subtitle__color;
      font-weight: $multipurpose-main-subtitle__font-weight;
      font-size: $multipurpose-main-subtitle__font-size;
      line-height: $multipurpose-main-subtitle__line-height;
    }
  }

  &__row {
    margin: $multipurpose-row__margin;
    padding: $multipurpose-row__padding;
    color: $multipurpose-row__color;
    background-color: $color-ivory-mist;
    border: $multipurpose-row__border;
    border-radius: 4px;

    &:first-of-type {
      margin-bottom: 0;
    }

    & > div {
      padding-right: 15px;
      padding-left: 0;

      @media #{$sm} {
        padding: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    .shops.show & {
      .col-md-3:nth-child(odd) {
        @media #{$min-sm} and #{$md} {
          padding-right: 0;
        }
      }
    }

    .home & {
      .col-md-3:nth-child(even) {
        @media #{$min-sm} and #{$md} {
          padding-right: 0;
        }
      }
    }
  }

  &__title {
    width: 100%;
    margin: $multipurpose-title__margin;
    padding: $multipurpose-title__padding;
    font-weight: $multipurpose-title__font-weight;
    font-size: $multipurpose-title__font-size;
    line-height: $multipurpose-title__line-height;
  }

  &__headline {
    margin: $multipurpose-headline__margin;
    padding: $multipurpose-headline__padding;
    color: $multipurpose-headline__color;
    font-weight: $multipurpose-headline__font-weight;
    font-size: $multipurpose-headline__font-size;
    line-height: $multipurpose-headline__line-height;
    text-align: center;
    text-transform: $multipurpose-headline__text-transform;
  }

  &__text {
    margin: $multipurpose-text__margin;
    font-weight: $multipurpose-text__font-weight;
    font-size: $multipurpose-text__font-size;
    line-height: $multipurpose-text__line-height;

    @media #{$md} {
      margin: $multipurpose-text__margin--mobile;
    }

    @media #{$sm} {
      line-height: 20px;
    }

    a {
      color: $multipurpose-text-a__color;
      font-weight: $multipurpose-text-a__font-weight;
      font-size: $multipurpose-text-a__font-size;
      text-decoration: $multipurpose-text-a__text-decoration;
      text-underline-offset: 3px;
      border-bottom: $multipurpose-text-a__border-bottom;

      &:hover {
        color: $multipurpose-text-a--hover__color;
        text-decoration: $multipurpose-text-a--hover__text-decoration;
        border-bottom: $multipurpose-text-a--hover__border-bottom;
      }

      @media #{$sm} {
        font-size: $multipurpose-text-a__font-size--mobile;
      }
    }

    strong,
    b {
      color: $multipurpose-text-b__color;
      font-weight: $multipurpose-text-b__font-weight;
    }

    u {
      text-decoration: $multipurpose-text-u__text-decoration;
      border-bottom: $multipurpose-text-u__border-bottom;
    }
  }

  &__img {
    border: 1px solid $color-black;
  }

  &__widgets {
    display: inline-block;
  }

  &__widget {
    margin: $multipurpose-widget__margin;

    &--33 {
      @media #{$md} {
        width: 100%;
      }

      #{$this}__wrap {
        height: $multipurpose-widget-33-wrap__height;
        background: $multipurpose-widget-33-wrap__background;
        border: $multipurpose-widget-33-wrap__border;
        border-radius: $multipurpose-widget-33-wrap__border-radius;

        @media #{$md} {
          height: $multipurpose-widget-33-wrap__height--mobile;
          margin: $multipurpose-widget-33-wrap__margin--mobile;
        }
      }

      #{$this}__img {
        height: $multipurpose-widget-33-img__height;
        margin: 15px;
        background-repeat: no-repeat;
        background-position: $multipurpose-widget-33-img__bg-position;
        background-size: $multipurpose-widget-33-img__bg-size;
        border-width: $multipurpose-widget-33-img__border-width;
        border-radius: $multipurpose-widget-33-img__border-radius;

        @media #{$md} {
          height: $multipurpose-widget-33-img__height--mobile;
        }
      }

      #{$this}__text {
        display: -webkit-box;
        max-height: 110px;
        padding: $multipurpose-widget-33-text__padding;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $multipurpose-widget-33-text__webkit-line-clamp;
        text-align: center;

        @media #{$md} {
          -webkit-line-clamp: $multipurpose-widget-33-text__webkit-line-clamp--mobile;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    &--100-main-hero {
      display: grid;
      align-content: $multipurpose-widget-100-main-hero__align;
      justify-items: $multipurpose-widget-100-main-hero__justify;
      min-height: $multipurpose-widget-100-main-hero__height;
      margin: 0;
      border-bottom: $multipurpose-widget-100-main-hero__border-bottom;
      border-radius: 4px;

      &::before {
        border-radius: 4px;
      }
    }

    &--50-img,
    &--25-img {
      background-repeat: no-repeat;
      background-size: cover;
      border: 1px solid $color-black;
    }

    &--50-img {
      height: $multipurpose-widget-50-img__height;
      border-radius: 4px;

      @media #{$md} {
        height: $multipurpose-widget-50-img__height--mobile;
      }
    }

    &--25-img {
      height: $multipurpose-widget-25-img__height;
      margin-inline: auto;
      border-radius: 4px;

      @media #{$md} {
        height: $multipurpose-widget-25-img__height--mobile;
      }

      .col-md-3.col-sm-6 & {
        height: 286px;

        @media #{$md} {
          height: 300px;
        }
      }
    }

    &--opacity {
      &-black,
      &-white {
        position: relative;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.48);
          content: "";
        }

        #{$this}__main {
          &-title,
          &-subtitle {
            color: #FFF;
          }
        }
      }

      &-white {
        &::before {
          background: rgba(255, 255, 255, 0.6);
        }

        #{$this}__main {
          &-title,
          &-subtitle {
            color: #000;
          }
        }
      }
    }
  }

  div[data-coupon-url] {
    cursor: pointer;
  }
}
