/* Jump to links */
$jump-to-links__margin: 0 0 20px !default;
$jump-to-links__padding: 20px !default;
$jump-to-links__background: $color-ivory-mist !default;
$jump-to-links-links__padding: 0 !default;
$jump-to-links-item__margin-bottom: 4px !default;
$jump-to-links-link__font-size: $body__font-size !default;
$jump-to-links-link__color: $body__color !default;

.jump-to-links {
  margin: $jump-to-links__margin;

  h2 {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    @media #{$md} {
      font-weight: 500;
      font-size: 18px;
    }
  }

  &__item {
    margin-bottom: 6px;
  }

  &__link {
    color: $jump-to-links-link__color;
  }

  ul {
    padding: $jump-to-links-links__padding;
    list-style-type: none;
  }

  .shops.show &,
  .coupons.index & {
    padding: $jump-to-links__padding;
    text-align: center;
    background: $jump-to-links__background;

    h2 {
      display: flex;
      flex-direction: column;
      align-items: center;

      &::before {
        @include svg-icon-base(60px, 60px, $icon-question);
      }
    }

    &__link {
      font-weight: 600;
      text-decoration: underline 2px;
    }
  }

  .campaigns.show & {
    &__link {
      @include c4r-arrow-link;

      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
    }
  }
}
