$search__template-areas:
  "head-disclaimer"
  "topbar"
  "m-navigation"
  "sub-nav-bar"
  "featured-shops"
  "content"
  "footer" !default;
$search__template-areas--mobile: $search__template-areas !default;

body {
  &.searches.index {
    &.no-topbar {
      --search-template-areas:
        "head-disclaimer"
        "m-navigation"
        "sub-nav-bar"
        "featured-shops"
        "content"
        "footer";
      --search-template-areas-mobile:
        "head-disclaimer"
        "m-navigation"
        "sub-nav-bar"
        "featured-shops"
        "content"
        "footer";
    }

    --search-template-areas:
      "head-disclaimer"
      "topbar"
      "m-navigation"
      "sub-nav-bar"
      "featured-shops"
      "content"
      "footer";
    --search-template-areas-mobile:
      "head-disclaimer"
      "topbar"
      "m-navigation"
      "sub-nav-bar"
      "featured-shops"
      "content"
      "footer";

    grid-template-areas: var(--search-template-areas);

    @media #{$sm} {
      grid-template-areas: var(--search-template-areas-mobile);
    }
  }
}
