$subpage-teaser__padding: 0 0 68px !default;
$subpage-teaser__padding--mobile: 0 0 28px !default;
$subpage-teaser__background: $themed--color-4 !default;
$subpage-teaser-header__padding: 88px 0 0 !default;
$subpage-teaser-header__padding--mobile: 46px 0 0 !default;
$subpage-teaser-overlay__background: rgba(58, 58, 58, 0.8) !default;
$subpage-teaser-text__color: $body__color !default;
$subpage-teaser-text__background: $color-white !default;
$subpage-teaser-text__font-size: 1.25rem !default;
$subpage-teaser-text__font-weight: 300 !default;

.subpage-teaser {
  padding: $subpage-teaser__padding;
  background: $subpage-teaser__background;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  @media #{$md} {
    padding: $subpage-teaser__padding--mobile;
  }

  &__header {
    position: relative;
    padding: $subpage-teaser-header__padding;
    text-align: center;

    @media #{$md} {
      padding: $subpage-teaser-header__padding--mobile;
    }

    .section-header {
      margin-top: 0;
    }
  }

  &__label {
    position: absolute;
    top: 0;
    right: 159px;

    @media #{$xl} {
      right: 10px;
    }

    @media #{$md} {
      right: 0;
      max-height: 88px;
    }
  }

  &__wrapper {
    padding-top: 90px;

    @media #{$md} {
      padding-top: 31px;
    }
  }

  &__item {
    display: block;
    max-width: 350px;
    margin: 0 auto 23px;

    &:hover {
      text-decoration: none;
    }
  }

  &__picture {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 233px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $subpage-teaser-overlay__background;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-height: 100px;
    transform: translate(-50%, -50%);
  }

  &__text {
    padding: 13px 0;
    color: $subpage-teaser-text__color;
    font-weight: $subpage-teaser-text__font-weight;
    font-size: $subpage-teaser-text__font-size;
    text-align: center;
    background: $subpage-teaser-text__background;
  }
}
