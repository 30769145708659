$sponsor-bar__height: 100px !default;
$sponsor-bar__margin: 10px 0 !default;
$sponsor-bar__padding: 10px 45px !default;
$sponsor-bar__padding--mobile: 10px 20px !default;
$sponsor-bar__background: $body__background !default;
$sponsor-bar__grid-template: "logo title button" "logo desc button" !default;
$sponsor-bar__grid-template--mobile:
  "title title logo"
  "desc desc logo"
  "button button logo" !default;
$sponsor-bar__grid-template-columns: 1fr 4fr 1fr !default;
$sponsor-bar__grid-template-columns--mobile: 1fr 1fr 1fr !default;
$sponsor-bar__gap: 5px !default;
$sponsor-bar-logo__max-height: 80px !default;
$sponsor-bar-title__color: $body__color !default;
$sponsor-bar-title__font-size: 25px !default;
$sponsor-bar-title__font-size--mobile: 16px !default;
$sponsor-bar-title__font-weight: 700 !default;
$sponsor-bar-title__line-height: 30px !default;
$sponsor-bar-title__line-height--mobile: 19px !default;
$sponsor-bar-text__color: $body__color !default;
$sponsor-bar-text__font-size: 20px !default;
$sponsor-bar-text__font-size--mobile: 16px !default;
$sponsor-bar-text__font-weight: 400 !default;
$sponsor-bar-text__line-height: 24px !default;
$sponsor-bar-text__line-height--mobile: 19px !default;
$sponsor-bar-btn__padding: 8px !default;
$sponsor-bar-btn__padding--mobile: 5px !default;
$sponsor-bar-btn__font-weight: 400 !default;
$sponsor-bar-btn__font-size: 14px !default;
$sponsor-bar-btn__line-height: 19px !default;
$sponsor-bar-btn__text-transform: uppercase !default;
$sponsor-bar-btn__background: $color-blue !default;
$sponsor-bar-btn__color: $color-white !default;
$sponsor-bar-btn__border-radius: 0 !default;

.sponsor-bar {
  height: $sponsor-bar__height;
  margin: $sponsor-bar__margin;
  padding: $sponsor-bar__padding;
  background: $sponsor-bar__background;

  @media #{$md} {
    height: auto;
    padding: $sponsor-bar__padding--mobile;
  }

  a {
    &:hover {
      color: initial;
    }
  }

  &__container {
    display: grid;
    grid-template: $sponsor-bar__grid-template;
    grid-template-columns: $sponsor-bar__grid-template-columns;
    gap: $sponsor-bar__gap;
    align-items: center;
    height: 100%;

    @media #{$md} {
      grid-template: $sponsor-bar__grid-template--mobile;
      grid-template-columns: $sponsor-bar__grid-template-columns--mobile;
    }
  }

  &__logo {
    grid-area: logo;
    max-height: $sponsor-bar-logo__max-height;

    @media #{$md} {
      margin: 0 auto;
    }
  }

  &__title {
    grid-area: title;
    color: $sponsor-bar-title__color;
    font-weight: $sponsor-bar-title__font-weight;
    font-size: $sponsor-bar-title__font-size;
    line-height: $sponsor-bar-title__line-height;

    @media #{$md} {
      font-size: $sponsor-bar-title__font-size--mobile;
      line-height: $sponsor-bar-title__line-height--mobile;
    }
  }

  &__text {
    display: inline-block;
    grid-area: desc;
    width: 100%;
    color: $sponsor-bar-text__color;
    font-weight: $sponsor-bar-text__font-weight;
    font-size: $sponsor-bar-text__font-size;
    line-height: $sponsor-bar-text__line-height;

    @media #{$md} {
      font-size: $sponsor-bar-text__font-size--mobile;
      line-height: $sponsor-bar-text__line-height--mobile;
    }
  }

  &__btn {
    grid-area: button;
    padding: $sponsor-bar-btn__padding;
    color: $sponsor-bar-btn__color;
    font-weight: $sponsor-bar-btn__font-weight;
    font-size: $sponsor-bar-btn__font-size;
    line-height: $sponsor-bar-btn__line-height;
    text-align: center;
    text-transform: $sponsor-bar-btn__text-transform;
    background-color: $sponsor-bar-btn__background;
    border-radius: $sponsor-bar-btn__border-radius;

    @media #{$md} {
      width: 80%;
      min-width: auto;
      padding: $sponsor-bar-btn__padding--mobile;

      &.o-button {
        margin: 0;
      }
    }
  }
}
