/* Author widget */
$colors: (
  "color-border": rgba(235, 237, 222, 0.5)
) !default;
$images: (
  "linkedin-logo": url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='37' fill='none' xmlns:v='https://vecta.io/nano'%3E%3Cpath d='M0 .5v36h36V.5H0zm8.829 7.077c1.426.016 2.83 1 2.892 2.703.03 1.514-1.289 2.668-2.929 2.703h-.04c-1.412-.016-2.79-1.025-2.854-2.703.02-1.497 1.279-2.667 2.931-2.703zm15.297 7.154c1.568.01 3.047.475 4.263 1.714 1.263 1.409 1.669 3.351 1.714 5.326v8.983h-5.177v-8.372c-.012-1.569-.549-3.476-2.626-3.54-1.217.013-2.076.72-2.663 1.903-.16.381-.184.818-.191 1.255v8.754h-5.175l.037-13.129-.037-2.511h5.175v2.206c.439-.625.926-1.217 1.58-1.694.886-.633 1.951-.875 3.1-.894zm-17.923.382h5.175v15.64H6.203v-15.64z' fill='%23000000'/%3E%3C/svg%3E")
) !default;
$author-widget__grid-template-areas:
  "header header header"
  "avatar name social-link"
  "avatar profession social-link"
  "bio bio bio"
  "additional-info additional-info additional-info"
  "reviewed reviewed reviewed"
  "links links links" !default;
$author-widget__grid-template-columns: 60px 1fr 42px !default;
$author-widget__margin--sidebar: 0 0 20px !default;
$author-widget__margin--pages: 0 0 25px !default;
$author-widget__padding: 20px !default;
$author-widget__gap: 0 15px !default;
$author-widget__bg-color: $color-ivory-mist !default;
$author-widget__border-radius: 0 !default;
$author-widget__border-color: map_get($colors, "color-border") !default;
$author-widget__border-style: solid !default;
$author-widget__border-width: 0 !default;
$author-widget__box-shadow: none !default;
$author-widget-header__color: $color-black !default;
$author-widget-header__font-weight: 600 !default;
$author-widget-header__font-size: 20px !default;
$author-widget-header__font-size--mobile: 16px !default;
$author-widget-header__line-height: 24px !default;
$author-widget-header__margin: 0 0 15px !default;
$author-widget-header__line-height--mobile: 20px !default;
$author-widget-header__border-color: $color-black !default;
$author-widget-header__border-style: solid !default;
$author-widget-header__border-width: 0 0 1px !default;
$author-widget-header__display: flex !default;
$author-widget-header__dlex-direction: column !default;
$author-widget-header__align-items: center !default;
$author-widget-header-before__width: 60px !default;
$author-widget-header-before__height: 60px !default;
$author-widget-header-before__content: $icon-checklist-document !default;
$author-widget-avatar__margin: 0 0 15px !default;
$author-widget-avatar-img__width: 60px !default;
$author-widget-avatar-img__height: 60px !default;
$author-widget-avatar-img__bg-color: $color-su-deep-blue !default;
$author-widget-avatar-img__border-radius: 4px !default;
$author-widget-name__margin: 5px 0 0 !default;
$author-widget-name__padding: 0 !default;
$author-widget-name__color: $color-black !default;
$author-widget-name__font-weight: 700 !default;
$author-widget-name__font-size: 20px !default;
$author-widget-name__line-height: 28px !default;
$author-widget-name__text-decoration: none !default;
$author-widget-profession__margin: 0 !default;
$author-widget-profession__padding: 0 !default;
$author-widget-profession__color: $color-su-grey !default;
$author-widget-profession__font-weight: 500 !default;
$author-widget-profession__font-size: 14px !default;
$author-widget-profession__line-height: 22px !default;
$author-widget-profession__text-transform: capitalize !default;
$author-widget-profession__font-size--mobile: 14px !default;
$author-widget-social-link__display: block !default;
$author-widget-social-link__width: 36px !default;
$author-widget-social-link__height: 36px !default;
$author-widget-social-link__margin: 15px 0 15px !default;
$author-widget-social-link__bg-image: map_get($images, "linkedin-logo") !default;
$author-widget-bio__margin: 0 !default;
$author-widget-bio__padding: 15px 0 0 !default;
$author-widget-bio__color: $color-black !default;
$author-widget-bio__font-weight: 400 !default;
$author-widget-bio__font-size: 14px !default;
$author-widget-bio__line-height: 22px !default;
$author-widget-bio__border-style: solid !default;
$author-widget-bio__border-width: 0 !default;
$author-widget-bio__border-color: $color-black !default;
$author-widget-bio__font-size--mobile: 14px !default;
$author-widget-bio__line-height--mobile: 18px !default;
$author-widget-additional-info__margin: $author-widget-bio__margin !default;
$author-widget-additional-info__padding: $author-widget-bio__padding !default;
$author-widget-additional-info__color: $author-widget-bio__color !default;
$author-widget-additional-info__font-weight: $author-widget-bio__font-weight !default;
$author-widget-additional-info__font-size: $author-widget-bio__font-size !default;
$author-widget-additional-info__line-height: $author-widget-bio__line-height !default;
$author-widget-additional-info__border-style: $author-widget-bio__border-style !default;
$author-widget-additional-info__border-width: $author-widget-bio__border-width !default;
$author-widget-additional-info__border-color: $author-widget-bio__border-color !default;
$author-widget-additional-info__font-size--mobile: $author-widget-bio__font-size--mobile !default;
$author-widget-additional-info__line-height--mobile: $author-widget-bio__line-height--mobile !default;
$author-widget-reviewed__margin: $author-widget-bio__margin !default;
$author-widget-reviewed__padding: 0 0 15px !default;
$author-widget-reviewed__color: $author-widget-bio__color !default;
$author-widget-reviewed__font-weight: $author-widget-bio__font-weight !default;
$author-widget-reviewed__font-size: $author-widget-bio__font-size !default;
$author-widget-reviewed__line-height: $author-widget-bio__line-height !default;
$author-widget-reviewed__border-style: $author-widget-bio__border-style !default;
$author-widget-reviewed__border-width: $author-widget-bio__border-width !default;
$author-widget-reviewed__border-color: $author-widget-bio__border-color !default;
$author-widget-reviewed__font-size--mobile: $author-widget-bio__font-size--mobile !default;
$author-widget-reviewed__line-height--mobile: $author-widget-bio__line-height--mobile !default;
$author-widget-page-links__margin: 0 !default;
$author-widget-page-links__padding: 15px 0 0 !default;
$author-widget-page-links__list-style-type: none !default;
$author-widget-page-links__border-top: 1px solid $color-black !default;
$author-widget-page-link__display: block !default;
$author-widget-page-link__margin: 0 0 10px !default;
$author-widget-page-link__padding: 0 !default;
$author-widget-page-link__color: $color-black;
$author-widget-page-link__font-weight: 700 !default;
$author-widget-page-link__font-size: 14px !default;
$author-widget-page-link__line-height: 20px !default;
$author-widget-page-link__text-align: left !default;
$author-widget-page-link__font-size--mobile: 14px !default;
$author-widget-page-link__line-height--mobile: 19px !default;
$author-widget-page-link__text-decoration: none !default;
$author-widget-page-link-before__width: 15px !default;
$author-widget-page-link-before__height: 15px !default;
$author-widget-page-link-before__font-size: 16px !default;
$author-widget-page-link-before__bg-color: #F8A300 !default;
$icon-link: true !default;
$icon-circle: false !default;
$author-widget-page-link-before__content: " " !default;

#sidebar {
  .author-widget {
    margin: $author-widget__margin--sidebar;
    border-color: $author-widget__border-color;
    border-style: $author-widget__border-style;
    border-width: $author-widget__border-width;
    box-shadow: $author-widget__box-shadow;
  }
}

.pages {
  .author-widget {
    margin: $author-widget__margin--pages;
    border-color: $author-widget__border-color;
    border-style: $author-widget__border-style;
    border-width: $author-widget__border-width;
  }
}

#sidebar,
* {
  .author-widget {
    $this: ".author-widget";

    display: grid;
    grid-template-areas: $author-widget__grid-template-areas;
    grid-template-columns: $author-widget__grid-template-columns;
    gap: $author-widget__gap;
    padding: $author-widget__padding;
    background-color: $author-widget__bg-color;
    border-radius: $author-widget__border-radius;

    &__header {
      grid-area: header;
      margin: $author-widget-header__margin;
      padding: 0 0 15px;
      color: $author-widget-header__color;
      font-weight: $author-widget-header__font-weight;
      font-size: $author-widget-header__font-size;
      line-height: $author-widget-header__line-height;
      text-align: center;
      border-color: $author-widget-header__border-color;
      border-style: $author-widget-header__border-style;
      border-width: $author-widget-header__border-width;

      @if $author-widget-header-before__content != "none" {
        display: $author-widget-header__display;
        flex-direction: $author-widget-header__dlex-direction;
        align-items: $author-widget-header__align-items;
      }

      &::before {
        width: $author-widget-header-before__width;
        height: $author-widget-header-before__height;
        content: $author-widget-header-before__content;
      }

      @media #{$sm} {
        font-size: $author-widget-header__font-size--mobile;
        line-height: $author-widget-header__line-height--mobile;
      }
    }

    &__avatar {
      grid-area: avatar;
      margin: $author-widget-avatar__margin;

      img {
        width: $author-widget-avatar-img__width;
        height: $author-widget-avatar-img__height;
        background-color: $author-widget-avatar-img__bg-color;
        border: 1px solid $color-black;
        border-radius: $author-widget-avatar-img__border-radius;
      }
    }

    &__name {
      grid-area: name;
      margin: $author-widget-name__margin;
      padding: $author-widget-name__padding;
      color: $author-widget-name__color;
      font-weight: $author-widget-name__font-weight;
      font-size: $author-widget-name__font-size;
      line-height: $author-widget-name__line-height;
      text-decoration: $author-widget-name__text-decoration;

      @media #{$sm} {
        font-size: 16px;
        line-height: 18px;
      }

      &-link {
        grid-area: name;
      }
    }

    &__profession {
      grid-area: profession;
      margin: $author-widget-profession__margin;
      padding: $author-widget-profession__padding;
      color: $author-widget-profession__color;
      font-weight: $author-widget-profession__font-weight;
      font-size: $author-widget-profession__font-size;
      line-height: $author-widget-profession__line-height;
      text-transform: $author-widget-profession__text-transform;

      @media #{$sm} {
        font-size: $author-widget-profession__font-size--mobile;
        line-height: 18px;
      }
    }

    &__social-link {
      display: $author-widget-social-link__display;
      grid-area: social-link;
      width: $author-widget-social-link__width;
      height: $author-widget-social-link__height;
      margin: $author-widget-social-link__margin;
      background-image: $author-widget-social-link__bg-image;
      background-repeat: no-repeat;
    }

    &__bio {
      grid-area: bio;
      margin: $author-widget-bio__margin;
      padding: $author-widget-bio__padding;
      color: $author-widget-bio__color;
      font-weight: $author-widget-bio__font-weight;
      font-size: $author-widget-bio__font-size;
      line-height: $author-widget-bio__line-height;
      border-color: $author-widget-bio__border-color;
      border-style: $author-widget-bio__border-style;
      border-width: $author-widget-bio__border-width;

      @media #{$sm} {
        font-size: $author-widget-bio__font-size--mobile;
        line-height: $author-widget-bio__line-height--mobile;
      }
    }

    &__additional-info {
      grid-area: additional-info;
      margin: $author-widget-additional-info__margin;
      padding: $author-widget-additional-info__padding;
      color: $author-widget-additional-info__color;
      font-weight: $author-widget-additional-info__font-weight;
      font-size: $author-widget-additional-info__font-size;
      line-height: $author-widget-additional-info__line-height;
      border-color: $author-widget-additional-info__border-color;
      border-style: $author-widget-additional-info__border-style;
      border-width: $author-widget-additional-info__border-width;

      @media #{$sm} {
        font-size: $author-widget-additional-info__font-size--mobile;
        line-height: $author-widget-additional-info__line-height--mobile;
      }
    }

    &__reviewed {
      grid-area: reviewed;
      margin: $author-widget-reviewed__margin;
      padding: $author-widget-reviewed__padding;
      color: $author-widget-reviewed__color;
      font-weight: $author-widget-reviewed__font-weight;
      font-size: $author-widget-reviewed__font-size;
      line-height: $author-widget-reviewed__line-height;
      border-color: $author-widget-reviewed__border-color;
      border-style: $author-widget-reviewed__border-style;
      border-width: $author-widget-reviewed__border-width;

      @media #{$sm} {
        font-size: $author-widget-reviewed__font-size--mobile;
        line-height: $author-widget-reviewed__line-height--mobile;
      }

      &::before {
        display: inline-block;
        width: 5px;
        height: 5px;
        margin: 2px 10px;
        background-color: $color-black;
        border-radius: 50%;
        content: "";
      }
    }

    &__page-links {
      grid-area: links;
      margin: $author-widget-page-links__margin;
      padding: $author-widget-page-links__padding;
      list-style-type: $author-widget-page-links__list-style-type;
      border-top: $author-widget-page-links__border-top;
    }

    &__page-link {
      display: $author-widget-page-link__display;
      margin: $author-widget-page-link__margin;
      padding: $author-widget-page-link__padding;
      color: $author-widget-page-link__color;
      font-weight: $author-widget-page-link__font-weight;
      font-size: $author-widget-page-link__font-size;
      line-height: $author-widget-page-link__line-height;
      text-align: $author-widget-page-link__text-align;
      text-decoration: $author-widget-page-link__text-decoration;

      @include c4r-arrow-link;

      @media #{$sm} {
        font-size: $author-widget-page-link__font-size--mobile;
        line-height: $author-widget-page-link__line-height--mobile;
      }

      &:hover {
        text-decoration: underline;
      }

      &::before {
        @if $icon-circle {
          background-color: $author-widget-page-link-before__bg-color;
          border-radius: 50%;
        }
      }
    }
  }
}
