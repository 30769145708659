$premium-offers-100__height: 320px !default;
$premium-offers-100__height--desktop: 380px !default;
$premium-offers-100__background-size: 50% !default;
$premium-offers-100__background-size--large: 50% 100% !default;
$premium-offers-100__background-size--mobile: cover !default;
$premium-offers-100__padding-top: 177px !default;
$premium-offers-100-body__width: 50% !default;
$premium-offers-100-body__padding: 15px 15px 40px !default;
$premium-offers-100-body__padding--large: 20px 15px 15px !default;
$premium-offers-100-body__padding--desktop: 100% !default;
$premium-offers-100-headline__margin-top: 50px !default;
$premium-offers-100-headline__margin-top--large: 0 !default;
$premium-offers-100-description__margin-top: 15px !default;
$premium-offers-100-offer-discount__top: 20px !default;
$premium-offers-100-offer-discount__left: 33px !default;
$premium-offers-100-description__line-clamp: 5 !default;
$premium-offers-100-bg-offer__padding-top: 0 !default;
$premium-offers-100-bg-offer__opacity: 0.8 !default;

.premium-offer {
  $this: &;

  &--100 {
    height: $premium-offers-100__height;
    background-position: left center;
    background-size: $premium-offers-100__background-size;

    @media #{$xl} {
      background-size: $premium-offers-100__background-size--large;
    }

    @media #{$md} {
      height: $premium-offers-100__height--desktop;
      padding-top: $premium-offers-100__padding-top;
      background-position: top center;
      background-size: $premium-offers-100__background-size--mobile;
    }

    &,
    &#{$this}--bg-left {
      @media #{$min-md} {
        background-position: right 351px top;
      }

      @media #{$min-lg} {
        background-position: right 471px top;
      }

      @media #{$min-xl} {
        background-position: right 590px top;
      }
    }

    &#{$this}--bg-right {
      @media #{$min-md} {
        background-position: left 351px top;
      }

      @media #{$min-lg} {
        background-position: left 471px top;
      }

      @media #{$min-xl} {
        background-position: left 590px top;
      }
    }

    &#{$this}--bg-left,
    &#{$this}--bg-right {
      @media #{$md} {
        background-size: $premium-offers-100__background-size--mobile;
      }
    }

    .premium-offer {
      &__body {
        float: right;
        width: $premium-offers-100-body__width;
        padding: $premium-offers-100-body__padding;
        text-align: center;
        background-color: $color-white;

        @media #{$xl} {
          padding: $premium-offers-100-body__padding--large;
        }

        @media #{$md} {
          width: $premium-offers-100-body__padding--desktop;
        }
      }

      &__headline {
        margin-top: $premium-offers-100-headline__margin-top;

        @media #{$lg} {
          margin-top: $premium-offers-100-headline__margin-top--large;
        }
      }

      &__description {
        display: -webkit-box;
        margin-top: $premium-offers-100-description__margin-top;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $premium-offers-100-description__line-clamp;
      }

      #{$this}__hr {
        display: none;
      }

      #{$this}__cta {
        min-width: 197px;
        padding: 2px 10px;
        font-weight: 700;
        line-height: 30px;
        text-transform: uppercase;

        @media #{$md} {
          min-width: 220px;
        }

        &:hover {
          color: $color-black;
          background-color: $color-su-pink;
          border-color: $color-su-pink;
        }

        @media #{$md} {
          justify-self: center;
          min-width: 220px;
          margin: 13px 0 0;
        }
      }
    }

    .offer__discount {
      position: absolute;
      top: $premium-offers-100-offer-discount__top;
      left: $premium-offers-100-offer-discount__left;
      display: none;
    }

    .premium-offer--100-img {
      display: none;
    }

    &.premium-offer.premium-offer--bg-100-width {
      position: relative;
      padding-top: $premium-offers-100-bg-offer__padding-top;
      background-image: none;

      .premium-offer--100-img {
        display: block;
        width: 100%;
      }

      .premium-offer__body {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 50%;
        height: auto;
        opacity: $premium-offers-100-bg-offer__opacity;

        @media #{$md} {
          position: relative;
          width: 100%;
          opacity: 1;
        }
      }
    }
  }
}
