$shop_bubbles__background-color: $color-white !default;
$shop_bubbles__margin: 40px 0 !default;
$shop_bubbles__padding: 0 !default;
$shop_bubbles__padding--mobile: 0 !default;
$shop-bubbles-header__margin: 0 0 15px;
$shop_bubbles-header__color: $color-black !default;
$shop_bubbles-subheader__color: $color-black !default;
$shop_bubbles-scrollbar__height: 12px !default;
$shop_bubbles-scrollbar__background-color: rgba(0, 0, 0, 0.5) !default;
$shop_bubbles-scrollbar__border-radius: 10px !default;
$shop_bubbles-wrapper__margin--mobile: 0 -15px -20px !default;
$shop_bubbles-wrapper__padding--mobile: 0 0 20px !default;
$shop_bubbles-list__padding: 0 !default;
$shop_bubbles-list__padding--mobile: 0 !default;
$shop_bubbles-item__width: 150px !default;
$shop_bubbles-item__width--mobile: 100px !default;
$shop_bubbles-item__height: 150px !default;
$shop_bubbles-item__height--mobile: 100px !default;
$shop_bubbles-img__max-width: 110px !default;
$shop_bubbles-img__max-width--mobile: 110px !default;

.shop-bubbles {
  margin: $shop_bubbles__margin;
  padding: $shop_bubbles__padding;
  background-color: $shop_bubbles__background-color;

  @media #{$sm} {
    padding: $shop_bubbles__padding--mobile;
  }

  &__header {
    margin: $shop-bubbles-header__margin;
  }

  .section {
    &-header {
      margin: 0 0 4px;
      color: $shop_bubbles-header__color;
      font-weight: 500;
      font-size: 26px;
      line-height: 34px;

      @media #{$md} {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &-subheader {
      color: $shop_bubbles-subheader__color;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      text-transform: capitalize;

      @media #{$md} {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  &__wrapper {
    @media #{$md} {
      margin: $shop_bubbles-wrapper__margin--mobile;
      padding: $shop_bubbles-wrapper__padding--mobile;
    }

    @media #{$min-md} {
      &::-webkit-scrollbar {
        appearance: none;
      }

      &::-webkit-scrollbar:horizontal {
        height: $shop_bubbles-scrollbar__height;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $shop_bubbles-scrollbar__background-color;
        border-radius: $shop_bubbles-scrollbar__border-radius;
        visibility: hidden;
      }

      &:hover::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }

  &__list {
    max-width: 100%;
    margin: 0 auto;
    padding: $shop_bubbles-list__padding;

    @media #{$md} {
      padding: $shop_bubbles-list__padding--mobile;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    margin-bottom: 0;
    padding-left: 0;
  }

  &__item {
    @include default-box(
      $background: false,
      $box-shadow: false
    );

    display: flex;
    align-items: center;
    justify-content: center;
    width: $shop_bubbles-item__width;
    height: $shop_bubbles-item__height;

    @media #{$sm} {
      width: $shop_bubbles-item__width--mobile;
      height: $shop_bubbles-item__height--mobile;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  &__img {
    @media #{$lg} {
      max-width: $shop_bubbles-img__max-width;
    }

    @media #{$sm} {
      max-width: $shop_bubbles-img__max-width--mobile;
    }
  }
}
