$hot-offers-container__padding: 20px 0 77px !default;
$hot-offers-container__padding--mobile: 25px 0 0 !default;
$hot-offers__padding: 43px 0 0 !default;
$hot-offers__padding--mobile: 40px 0 0 !default;
$offer__height: 322px !default;
$offer__height--mobile: 299px !default;
$offer__margin-botom: 10px !default;
$offer__background: $color-white !default;
$offer__background--gray: rgb(223, 221, 221) !default;
$offer__border--no-bg: 1px solid $border-color !default;
$offer__padding: 20px !default;
$offer__padding--mobile: 10px !default;
$offer-logo__width: 110px !default;
$offer-logo__height: 110px !default;
$offer-label__font-size: 22px !default;
$offer-label__line-height: 27px !default;
$offer-label__width: 102px !default;
$offer-label__height: 28px !default;
$offer-label__color: $color-white !default;
$offer-name__font-size: 1rem !default;
$offer-name__font-weight: 700 !default;
$offer-description__font-size: 12px !default;
$offer-description__line-height: 1.5 !default;
$offer-btn__margin: 16px 0 0 -4px !default;
$offer-btn__padding: 6px 30px !default;
$offer-btn__color: $color-white !default;
$offer-btn__background: $themed--color-3 !default;

.hot-offers {
  padding: $hot-offers-container__padding;

  @media #{$md} {
    padding: $hot-offers-container__padding--mobile;
  }

  &__offers {
    padding-top: $hot-offers__padding;

    @media #{$md} {
      padding-top: $hot-offers__padding--mobile;
    }
  }

  @media #{$min-lg} {
    div[class^="col-md-"] {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}

.offer {
  position: relative;
  height: $offer__height;
  margin-bottom: $offer__margin-botom;
  background-color: $offer__background;
  cursor: pointer;

  @media #{$md} {
    height: $offer__height--mobile;
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;

    @media #{$xs} {
      background-size: cover;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: $offer__padding;

    @media #{$md} {
      padding: $offer__padding--mobile;
    }
  }

  &__head,
  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__body {
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
  }

  &__vendor {
    max-width: $offer-logo__width;
    max-height: $offer-logo__height;

    &--transparent {
      background: rgba(255, 255, 255, 0.6);
    }
  }

  &__discount {
    position: relative;
    width: $offer-label__width;
    height: $offer-label__height;
    color: $offer-label__color;
    font-size: $offer-label__font-size;
    line-height: $offer-label__line-height;
    text-align: center;
  }

  &__discount-tag {
    position: absolute;
    left: 0;
  }

  &__discount-value {
    position: relative;
  }

  &__information {
    max-width: 60%;

    @media #{$md} {
      max-width: 80%;
    }
  }

  &__backing {
    background: rgba(246, 246, 246, 0.85);
    box-shadow: -3px 0 0 rgba(246, 246, 246, 0.85);
    box-decoration-break: clone;
  }

  &__name {
    font-weight: $offer-name__font-weight;
    font-size: $offer-name__font-size;
  }

  &__description {
    font-size: $offer-description__font-size;
    line-height: $offer-description__line-height;
  }

  &__cta.btn {
    display: inline-block;
    margin: $offer-btn__margin;
    padding: $offer-btn__padding;
    color: $offer-btn__color;
    background: $offer-btn__background;
  }

  /* modifiers start */
  &--left-align {
    .offer {
      &__img {
        background-position: 100% 70%;
      }

      &__body {
        @media #{$md} {
          flex-direction: row;
          align-items: flex-end;
        }
      }
    }
  }

  &--bg {
    background-color: $offer__background--gray;
  }

  &--no-bg {
    background: none;
    border: $offer__border--no-bg;
  }

  &--small {
    .offer {
      &__img {
        background-position: right center;
      }

      &__information {
        max-width: 80%;
      }
    }
  }

  &--right-align {
    .offer {
      &__img {
        background-position: bottom left;
      }

      &__body {
        align-items: flex-end;
        align-self: flex-end;

        @media #{md} {
          flex-direction: row;
          align-items: flex-end;
        }
      }
    }
  }

  &--full-bg {
    .offer {
      &__img {
        background-size: cover;
      }
    }
  }

  &--green-label {
    .offer {
      &__discount-tag path {
        fill: $themed--color-1;
      }
    }
  }

  &--yellow-label {
    .offer {
      &__discount-tag path {
        fill: $themed--color-2;
      }
    }
  }

  /* Modifiers stop */
}

/* Hot Offers End */
