$loader__width: 30px !default;
$loader__height: 30px !default;
$loader__border: 4px solid $body__background--light-grey !default;
$loader__border-top: 4px solid $color-asphalt !default;
$loader__border-bottom: 4px solid $color-asphalt !default;
$loader__border-radius: 50% !default;

* {
  -webkit-font-smoothing: antialiased;
}

body {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100%;
}

#content,
.main-content {
  grid-area: content;
}

.hidden {
  display: none !important;
}

.img-fluid,
.img-responsive {
  max-width: 100%;
  height: auto;
}

.loader {
  width: $loader__width;
  height: $loader__height;
  border: $loader__border;
  border-top: $loader__border-top;
  border-bottom: $loader__border-bottom;
  border-radius: $loader__border-radius;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
