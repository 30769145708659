$copyright__margin: 0 !default;
$copyright__text-align: left !default;
$copyright-link__display: inline-block !default;
$copyright-link__margin: 0 0 92px !default;
$copyright-disclaimer__font-weight: 300 !default;

.copyright {
  margin: $copyright__margin;
  text-align: $copyright__text-align;

  &__link {
    display: $copyright-link__display;
    margin: $copyright-link__margin;

    @media #{$md} {
      margin-bottom: 60px;
    }
  }

  &__disclaimer {
    font-weight: $copyright-disclaimer__font-weight;
  }

  .img-responsive {
    width: 231px;

    @media #{$md} {
      width: 122px;
    }
  }

  & > .container {
    display: flex;
    flex-direction: column-reverse;
  }
}
