$pages-show__template-areas:
  "head-disclaimer"
  "topbar"
  "m-navigation"
  "sub-nav-bar"
  "featured-shops"
  "content"
  "footer" !default;
$pages-show__template-areas--mobile: $pages-show__template-areas !default;

body {
  &.pages {
    &.show {
      grid-template-areas: $pages-show__template-areas;

      @media #{$sm} {
        grid-template-areas: $pages-show__template-areas--mobile;
      }
    }
  }
}
