$deals__padding: 71px 0 43px !default;
$deals__background: $color-white !default;
$deals-wrapper__padding: 43px 0 0 !default;

.deals {
  padding: $deals__padding;
  background: $deals__background;

  &__wrapper {
    padding: $deals-wrapper__padding;
  }
}
