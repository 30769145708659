$featured-shops__margin: 0 0 40px !default;
$featured-shops__padding: 0 0 11px !default;
$featured-shops__background: $body__background--light-grey !default;
$featured-shops-list__gap--lg: 10px !default;
$featured-shops-item__width: 110px !default;
$featured-shops-item__height: 145px !default;
$featured-shops-item__margin: 0 8px !default;
$featured-shops-item__margin--lg: 0 0 11px !default;
$featured-shops-item__background: $color-white !default;
$featured-shops-item-img__height: 99% !default;
$featured-shops-counter__width: 100% !default;
$featured-shops-counter__padding: 6px 0 !default;
$featured-shops-counter__color: $color-white !default;
$featured-shops-counter__color--hover: $color-black !default;
$featured-shops-counter__font-size: 14px !default;
$featured-shops-counter__line-height: 20px !default;
$featured-shops-counter__text-align: center !default;
$featured-shops-counter__background: $color-su-saturated-green !default;
$featured-shops-counter__background--hover: $color-su-pink !default;

.featured-shops {
  $this: &;

  grid-area: featured-shops;
  margin: $featured-shops__margin;
  padding: $featured-shops__padding;
  background-color: $featured-shops__background;

  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @media #{$lg} {
      flex-wrap: wrap;
      gap: $featured-shops-list__gap--lg;
      justify-content: center;
    }
  }

  &__item {
    display: inline-block;
    width: $featured-shops-item__width;
    height: $featured-shops-item__height;
    margin: $featured-shops-item__margin;
    background: $featured-shops-item__background;

    @media #{$lg} {
      flex-grow: 1;
      margin: $featured-shops-item__margin--lg;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    img {
      max-width: 100%;

      @media #{$lg} {
        height: $featured-shops-item-img__height;
      }
    }

    .img-responsive {
      width: 84px;
      height: 84px;
      margin: 14px auto;
    }
  }

  &__link {
    @include default-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    &:hover {
      text-decoration: none;

      #{$this}__counter {
        @media #{$min-md} {
          color: $featured-shops-counter__color--hover;
          background-color: $featured-shops-counter__background--hover;
        }
      }
    }
  }

  &__counter {
    width: $featured-shops-counter__width;
    padding: $featured-shops-counter__padding;
    color: $featured-shops-counter__color;
    font-weight: 700;
    font-size: $featured-shops-counter__font-size;
    line-height: $featured-shops-counter__line-height;
    text-align: $featured-shops-counter__text-align;
    background-color: $featured-shops-counter__background;
    border-top: 1px solid $color-black;
  }
}

.categories {
  .featured-shops {
    margin: 40px 0 0;

    @media #{$md} {
      margin-top: 30px;
    }
  }
}
