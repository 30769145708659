$newsletter__margin: 20px 0 !default;
$newsletter__padding: 0 0 20px 30px !default;
$newsletter__padding--mobile: 15px !default;
$newsletter__border: 1px solid $color-black !default;
$newsletter__border-radius: 4px !default;
$newsletter__background-color: $color-ivory-mist !default;
$newsletter-form__grid-template-areas:
  "header header image"
  "input submit image"
  "privacy privacy image" !default;
$newsletter-form__grid-template-columns: 2fr 1fr 168px !default;
$newsletter-form__grid-template-areas--mobile:
  "image header"
  "input input"
  "submit submit"
  "privacy privacy" !default;
$newsletter-form__grid-template-columns--mobile: 95px auto !default;
$newsletter-coupon-title__margin: 20px 0 10px !default;
$newsletter-coupon-title__padding: 0 !default;
$newsletter-coupon-title__color: $color-black !default;
$newsletter-coupon-title__font-weight: 700 !default;
$newsletter-coupon-title__font-size: 18px !default;
$newsletter-coupon-title__line-height: 26px !default;
$newsletter-coupon-title__margin--mobile: 0 0 10px !default;
$newsletter-coupon-title__padding--mobile: 0 !default;
$newsletter-coupon-title__font-size--mobile: 16px !default;
$newsletter-coupon-title__line-height--mobile: 20px !default;
$newsletter-coupon-title__text-align: left !default;
$newsletter-subheader__margin-top: 10px !default;
$newsletter-subheader__padding: 25px !default;
$newsletter-subheader__color: $color-black !default;
$newsletter-form__max-width: 629px !default;
$newsletter-form__margin: 0 !default;
$newsletter-form__padding--mobile: 0 !default;
$newsletter-input__height: 38px !default;
$newsletter-input__margin: 0 !default;
$newsletter-input__padding: 13px 10px !default;
$newsletter-input__font-size: 14px !default;
$newsletter-input__line-height: 12px !default;
$newsletter-input__border: 1px solid $color-black !default;
$newsletter-input-placeholder__font-size--mobile: 14px !default;
$newsletter-input-placeholder__line-height--mobile: 12px !default;
$newsletter-input-placeholder__color: $color-su-grey !default;
$newsletter-submit__height: 36px !default;
$newsletter-submit__background: $color-su-bright-green !default;
$newsletter-submit__color: $color-white !default;
$newsletter-submit__color-hover: $color-black !default;
$newsletter-submit__font-size: 14px !default;
$newsletter-submit__line-height: 30px !default;
$newsletter-submit-active__color: $color-white !default;
$newsletter-submit__margin-top: 0 !default;
$newsletter-submit__margin-top--mobile: 10px !default;
$newsletter-submit__font-weight: 700 !default;
$newsletter-privacy__margin: 0 !default;
$newsletter-privacy__font-size: 13px !default;
$newsletter-privacy-label__margin-top: 10px !default;
$newsletter-privacy-label__margin-bottom: 0 !default;
$newsletter-privacy-label__margin-left: 20px !default;
$newsletter-privacy-input__top: 3px !default;
$newsletter-privacy-input__left: -20px !default;
$newsletter-privacy-a__color: $color-black !default;
$newsletter-text__color: $color-black !default;
$newsletter-text__font-size: 14px !default;
$newsletter-text__line-height: 22px !default;
$newsletter-text__font-size--mobile: 14px !default;
$newsletter-text__line-height--mobile: 20px !default;
$newsletter-more-details__margin: 10px 0 0 !default;
$newsletter-more-details__color: $color-black !default;
$newsletter-more-details__font-size: 14px !default;
$newsletter-more-details__line-height: 14px !default;
$newsletter-for-coupons-placeholder__color: $color-light-grey-3 !default;
$newsletter-for-coupons-placeholder__font-size: 14px !default;
$newsletter-for-coupons-placeholder__line-height: 12px !default;
$newsletter-for-coupons-placeholder__opacity: 1 !default;
$newsletter-for-coupons-subheader__font-size: 19px !default;
$newsletter-for-coupons-form__padding--desktop: 0 !default;
$newsletter-for-coupons-form-subheader__font-size: 19px !default;
$newsletter-for-coupons-input__width: 100% !default;
$newsletter-for-coupons-input__margin-top: 20px !default;
$newsletter-for-coupons-input__width--mobile: 100% !default;
$newsletter-for-coupons-privacy__margin: 0 !default;

.btn.w-newsletter {
  &__submit {
    background-color: $newsletter-submit__background;

    &:hover {
      color: $newsletter-submit__color-hover;
    }
  }
}

.w-newsletter {
  margin: $newsletter__margin;
  padding: $newsletter__padding;
  text-align: center;
  background-color: $newsletter__background-color;
  border: $newsletter__border;
  border-radius: $newsletter__border-radius;

  @media #{$md} {
    padding: $newsletter__padding--mobile;
  }

  $this: &;

  h3,
  .coupon__title {
    grid-area: header;
    margin: $newsletter-coupon-title__margin;
    padding: $newsletter-coupon-title__padding;
    color: $newsletter-coupon-title__color;
    font-weight: $newsletter-coupon-title__font-weight;
    font-size: $newsletter-coupon-title__font-size;
    line-height: $newsletter-coupon-title__line-height;
    text-align: $newsletter-coupon-title__text-align;
    cursor: text;

    @media #{$md} {
      align-self: center;
      min-height: 36px;
      margin: $newsletter-coupon-title__margin--mobile;
      padding: $newsletter-coupon-title__padding--mobile;
      font-size: $newsletter-coupon-title__font-size--mobile;
      line-height: $newsletter-coupon-title__line-height--mobile;
      text-align: center;
    }
  }

  &__images {
    position: relative;
    top: -6px;
    grid-area: image;
    height: min-content;

    &::after {
      @include svg-icon-base(153px, 97px, $icons-for-newsletter);

      @media #{$min-md} {
        position: absolute;
        right: 0;
        bottom: -10px;
      }

      @media #{$md} {
        width: 90px;
        height: 72px;
        background-size: contain;
      }
    }
  }

  &__image {
    @media #{$md} {
      display: none;
    }
  }

  &__text,
  .coupon__text {
    color: $newsletter-text__color;
    font-size: $newsletter-text__font-size;
    line-height: $newsletter-text__line-height;

    @media #{$md} {
      font-size: $newsletter-text__font-size--mobile;
      line-height: $newsletter-text__line-height--mobile;
    }
  }

  &__subheader {
    display: inline-block;
    margin-top: $newsletter-subheader__margin-top;
    padding: $newsletter-subheader__padding;
    color: $newsletter-subheader__color;
  }

  &__form {
    display: grid;
    grid-template-areas: $newsletter-form__grid-template-areas;
    grid-template-columns: $newsletter-form__grid-template-columns;
    margin: $newsletter-form__margin;

    @media #{$md} {
      grid-template-areas: $newsletter-form__grid-template-areas--mobile;
      grid-template-columns: $newsletter-form__grid-template-columns--mobile;
      padding: $newsletter-form__padding--mobile;
    }

    .coupon__text {
      max-height: none;
    }
  }

  &__input {
    grid-area: input;
    height: $newsletter-input__height;
    margin: $newsletter-input__margin;
    padding: $newsletter-input__padding;
    font-size: $newsletter-input__font-size;
    line-height: $newsletter-input__line-height;
    border: $newsletter-input__border;
    border-radius: 8px;

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }

    @media #{$sm} {
      display: block;
      width: 100%;

      &::placeholder {
        font-size: $newsletter-input-placeholder__font-size--mobile;
        line-height: $newsletter-input-placeholder__line-height--mobile;
      }
    }

    &::placeholder {
      color: $newsletter-input-placeholder__color;
      font-size: 14px;
      font-style: normal;
      line-height: 12px;
    }
  }

  &__submit {
    @include btn(196px, $background: $newsletter-submit__background, $color: $newsletter-submit__color);

    display: inline-flex;
    grid-area: submit;
    align-items: center;
    justify-content: center;
    height: $newsletter-submit__height;
    margin-top: $newsletter-submit__margin-top;
    padding: 4px;
    font-weight: $newsletter-submit__font-weight;
    font-size: $newsletter-submit__font-size;
    line-height: $newsletter-submit__line-height;
    text-transform: uppercase;
    border-radius: 8px;
    box-shadow: $su-box-shadow-neo-brutalism;

    @media #{$min-md} {
      margin-right: 14px;
      margin-left: 10px;
    }

    @media #{$md} {
      display: block;
      width: 100%;
      margin-top: $newsletter-submit__margin-top--mobile;
    }

    &:hover,
    &:focus,
    &:active {
      color: $newsletter-submit-active__color;
      background-color: $color-su-pink;
      outline: none;

      &::after {
        background-image: $icon-envelope-green;
      }
    }

    &::after {
      @include svg-icon-base(14px, 14px, $icon-envelope-pink);

      margin-left: 5px;
    }

    .loader {
      margin: auto;
    }

    &.loading {
      &::after {
        display: none;
      }
    }
  }

  &__privacy,
  .coupon__privacy {
    grid-area: privacy;
    margin: $newsletter-privacy__margin;
    font-size: $newsletter-privacy__font-size;
    text-align: left;

    label {
      position: relative;
      margin-top: $newsletter-privacy-label__margin-top;
      margin-bottom: $newsletter-privacy-label__margin-bottom;
      margin-left: $newsletter-privacy-label__margin-left;
      text-align: inherit;
    }

    input[type="checkbox"] {
      position: absolute;
      top: $newsletter-privacy-input__top;
      left: $newsletter-privacy-input__left;
      cursor: pointer;

      &::before {
        display: inline-block;
        width: 15px;
        height: 15px;
        background-color: $color-su-saturated-green;
        border: 1px solid $color-black;
        border-radius: 4px;
        box-shadow: 2px 2px 0 0 $color-black;
        content: " ";
      }

      &::after {
        position: absolute;
        top: -2px;
        left: 2px;
        display: none;
        width: 14px;
        height: 14px;
        content: $icon-white-check;
      }

      &:checked {
        &::after {
          display: inline-block;
        }
      }
    }

    a {
      color: $newsletter-privacy-a__color;
      font-weight: 700;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }

  &__more-details,
  .coupon__more-details {
    display: block;
    margin: $newsletter-more-details__margin;
    color: $newsletter-more-details__color;
    font-size: $newsletter-more-details__font-size;
    line-height: $newsletter-more-details__line-height;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &::after {
      display: inline-block;
      margin-left: 3px;
      color: inherit;
      font-weight: 700;
      font-size: 10px;
      font-family: simple, serif;
      transform: rotate(0deg);
      transition: transform ease-in-out 0.3s;
      content: $icon-toggle-arrow;
    }

    &--opened {
      &::after {
        margin-left: 7px;
        transform: rotate(-90deg);
      }
    }
  }

  &--for-coupons {
    ::placeholder {
      color: $newsletter-for-coupons-placeholder__color;
      font-size: $newsletter-for-coupons-placeholder__font-size;
      line-height: $newsletter-for-coupons-placeholder__line-height;
      opacity: $newsletter-for-coupons-placeholder__opacity;
    }

    #{$this}__form {
      @media #{$lg} {
        padding: $newsletter-for-coupons-form__padding--desktop;
      }
    }

    #{$this}__subheader {
      font-weight: 700;
      font-size: $newsletter-for-coupons-subheader__font-size;
    }

    #{$this}__input {
      width: $newsletter-for-coupons-input__width;

      @media #{$sm} {
        width: $newsletter-for-coupons-input__width--mobile;
      }
    }

    #{$this}__privacy {
      display: block;
      margin: $newsletter-for-coupons-privacy__margin;
    }
  }
}

.coupon {
  &--newsletter {
    display: block;
    border: none;
  }
}
