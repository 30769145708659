$sidebar__position: left !default;
$sidebar__background: $body__background !default;
$sidebar__color: $color-black !default;
$sidebar__padding: 20px !default;
$sidebar__padding--mobile: 20px 10px 0 !default;
$sidebar__margin-right: 10px !default;
$sidebar__margin-right--mobile: 0 !default;
$sidebar__border: 0 !default;
$sidebar__box-shadow: none !default;
$sidebar__box-shadow--hover: $default-box__box-shadow--hover !default;
$sidebar-header__font-weight: 600 !default;
$sidebar-header__font-size: 20px !default;
$sidebar-header__font-size--mobile: 16px !default;
$sidebar-header__line-height: 24px !default;
$sidebar-header__line-height--mobile: 20px !default;
$sidebar-logo__height: 150px !default;
$sidebar-logo__border: none !default;
$sidebar-logo__background: $color-white !default;
$sidebar-link__margin-top: 24px !default;
$sidebar-link-a__font-weight: 700 !default;
$sidebar-link-a__font-size: 14px !default;
$sidebar-link-a__line-height: 30px !default;
$sidebar-link-a__text-transform: capitalize !default;
$sidebar-link-a__text-decoration: underline 2px !default;
$sidebar-table__font-size: 13px !default;
$sidebar-table-row__background--hover: darken($body__background--light-grey, 5%) !default;
$sidebar-banner__margin-bottom: 15px !default;
$sidebar-widget__margin: 0 0 20px !default;
$sidebar-widget__padding: 20px !default;
$sidebar-widget__font-size: 14px !default;
$sidebar-widget__text-align: center !default;
$sidebar-widget__background: $color-ivory-mist !default;
$sidebar-widget__border-radius: 4px !default;
$sidebar-star__margin: 40px 0 20px !default;
$sidebar-star__padding: 20px !default;
$sidebar-seo-h2__line-height: 1.1 !default;

.show #content,
.searches #content,
.not_found #content {
  @if $sidebar__position == left {
    order: 1;
  } @else if $sidebar__position == right {
    order: 0;
  }

  @media #{$lg} {
    order: 0;
  }
}

.sidebar {
  @include default-box(
    $sidebar__background,
    $border: $sidebar__border,
    $box-shadow: $sidebar__box-shadow,
    $box-shadow--hover: $sidebar__box-shadow--hover,
    $hover: false
  );

  padding: $sidebar__padding;
  color: $sidebar__color;

  @if $sidebar__position == left {
    margin-right: $sidebar__margin-right;

    @media #{$xl} {
      margin-right: $sidebar__margin-right--mobile;
    }
  } @else if $sidebar__position == right {
    margin-left: $sidebar__margin-right;

    @media #{$xl} {
      margin-left: $sidebar__margin-right--mobile;
    }
  }

  @media #{$xl} {
    padding: $sidebar__padding--mobile;
  }

  @media #{$md} {
    padding-top: 0;
  }

  &--static {
    margin-top: 28px;
  }

  h3 {
    font-weight: $sidebar-header__font-weight;
    font-size: $sidebar-header__font-size;
    line-height: $sidebar-header__line-height;

    @media #{$md} {
      font-size: $sidebar-header__font-size--mobile;
      line-height: $sidebar-header__line-height--mobile;
    }
  }

  &__logo {
    height: $sidebar-logo__height;
    background: $sidebar-logo__background;
    border: $sidebar-logo__border;
  }

  &__logo-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__shop-link {
    display: block !important;
    margin-top: $sidebar-link__margin-top;
    text-align: center;

    @media #{$md} {
      margin-top: 0;
    }
  }

  &__shop-link {
    a {
      font-weight: $sidebar-link-a__font-weight;
      font-size: $sidebar-link-a__font-size;
      line-height: $sidebar-link-a__line-height;
      text-transform: $sidebar-link-a__text-transform;
      text-decoration: $sidebar-link-a__text-decoration;
    }
  }

  .table {
    width: 100%;
    font-size: $sidebar-table__font-size;
    table-layout: fixed;

    &-row--hover:hover {
      background-color: $sidebar-table-row__background--hover;
    }

    td {
      border-top: 1px solid #DDD;

      &.nowrap {
        white-space: nowrap;
      }
    }
  }

  &__banner {
    margin-bottom: $sidebar-banner__margin-bottom;
    cursor: pointer;

    a {
      display: block;
    }
  }

  .widget {
    $this: ".widget";

    margin: $sidebar-widget__margin;
    padding: $sidebar-widget__padding;
    font-size: $sidebar-widget__font-size;
    text-align: $sidebar-widget__text-align;
    background-color: $sidebar-widget__background;
    border-radius: $sidebar-widget__border-radius;

    &#{$this}--guarantee,
    &#{$this}--about,
    &#{$this}--additional {
      text-align: center;

      h3 {
        display: flex;
        flex-direction: column;
        margin: 0 0 1rem;
      }

      .guarantee__list {
        padding: 0;
        list-style-type: none;
      }
    }

    &--rating {
      margin: $sidebar-star__margin;
      padding: $sidebar-star__padding;
      border-bottom: 1px solid $color-su-light-green;

      @media #{$lg} {
        margin-top: 27px;
        border-bottom: none;
      }
    }

    &--summary {
      a {
        display: block;
        color: inherit;
      }

      th:first-of-type {
        width: 50%;
      }
    }

    &--seo-text {
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
      }

      h2 {
        line-height: $sidebar-seo-h2__line-height;
        text-align: center;
      }

      ol,
      ul {
        padding-left: 20px;

        li {
          text-align: initial;
        }
      }
    }

    &--guarantee {
      h3 {
        &::before {
          content: $icon-broken-heart;
        }
      }

      .guarantee__list li {
        a {
          font-weight: 700;
          font-size: 14px;
          text-decoration: underline;
          text-decoration-thickness: 2px;

          &:hover {
            color: $color-su-saturated-green;
          }
        }
      }
    }

    &--about h3 {
      &::before {
        content: $icon-shopping-lover;
      }
    }

    &--additional h3:first-of-type {
      &::before {
        content: $icon-package-deliver;
      }
    }

    &--faq {
      text-align: left;

      h2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        font-weight: 600;
        font-size: 26px;
        line-height: 34px;

        @media #{$md} {
          font-size: 18px;
          line-height: 24px;
        }

        &::before {
          content: $icon-question;
        }
      }
    }
  }
}

.badge,
.tested {
  &__banner {
    cursor: pointer;

    a {
      display: block;
    }
  }
}
