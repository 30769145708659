$w-featured__margin: 0 0 40px !default;
$w-featured__margin--before-premium: 40px 0 0 !default;
$w-featured-headline__color: $color-black !default;
$w-featured-headline__font-weight: 600 !default;
$w-featured-headline__font-weight--mobile: 500 !default;
$w-featured-headline__font-size: 20px !default;
$w-featured-headline__font-size--mobile: 18px !default;
$w-featured-headline__line-height: 24px !default;
$w-featured-headline__line-height--mobile: 24px !default;
$w-featured-author__padding: 8px 10px !default;
$w-featured-author__color: #000 !default;
$w-featured-author__font-weight: 400 !default;
$w-featured-author__font-size: 14px !default;
$w-featured-author__line-height: 14px !default;
$w-featured-author__letter-spacing: 0.5px !default;
$w-featured-author__text-decoration: underline !default;
$w-featured__background: $color-white !default;
$w-featured-frame__margin: 0 0 15px !default;
$w-featured-frame__border: 1px solid $color-black !default;
$w-featured-logo__size--33: 80px !default;
$w-featured-footer__padding--33: 7px 0 7px 19px !default;
$w-featured-content__padding--33: 11px 0 0 28px !default;
$w-featured-content__padding--33--mobile: 11px 0 0 28px !default;
$w-featured-content__line-height--33: 1.3 !default;
$w-featured-shop-title__font-weight--33: 600 !default;
$w-featured-shop-title__text-transform--33: capitalize !default;
$w-featured-hero__height: 120px !default;
$w-featured-more-link__float: right !default;
$w-featured-more-link__display: inline !default;
$w-featured-more-link__margin: 0 0 15px !default;
$w-featured-more-link__color: $color-black !default;
$w-featured-shop-title__font-size: 16px !default;
$w-featured-shop-title__line-height: 22px !default;
$w-featured-shop-title__uppercase: false !default;
$w-featured-logo__size: 80px !default;
$w-featured-content__areas:
  "code exclusive"
  "title title"
  "desc desc" !default;
$w-featured-content__template-rows: 27px 22px auto !default;
$w-featured-content__padding: 4px 5px 24px 10px !default;
$w-featured-content__font-size: 14px !default;
$w-featured-code__display: none !default;
$w-featured-code__width: 100px !default;
$w-featured-code__padding: 3px !default;
$w-featured-code__margin: 0 !default;
$w-featured-code__color: $color-white !default;
$w-featured-code__font-size: 10px !default;
$w-featured-code__line-height: 1.2 !default;
$w-featured-code__background: $color-primary !default;
$w-featured-exclusive__padding: 2px 3px !default;
$w-featured-exclusive__border: 1px solid $color-black !default;
$w-featured-exclusive__border-radius: 4px !default;
$w-featured-description__line-height: 20px !default;
$w-featured-description__line-clamp: 2 !default;
$w-featured-frame__min-height--same-shop: 92px !default;
$w-featured-hero__height--same-shop: 108px !default;
$w-featured-logo__background--same-shop: $color-white !default;
$w-featured-logo__box-shadow--same-shop: $su-box-shadow-light-2 !default;
$w-featured-content__padding--same-shop: 12px !default;

.w-featured {
  $this: &;

  &__headline {
    display: inline-block;
    margin: 0 0 15px;
    color: $w-featured-headline__color;
    font-weight: $w-featured-headline__font-weight;
    font-size: $w-featured-headline__font-size;
    font-family: $font-family--base;
    line-height: $w-featured-headline__line-height;

    @media #{$md} {
      font-weight: $w-featured-headline__font-weight--mobile;
      font-size: $w-featured-headline__font-size--mobile;
      line-height: $w-featured-headline__line-height--mobile;
    }
  }

  &__more-link {
    display: $w-featured-more-link__display;
    float: $w-featured-more-link__float;
    margin: $w-featured-more-link__margin;
    color: $w-featured-more-link__color;

    i {
      font-size: 0.8em;
    }
  }

  &__list {
    margin: $w-featured__margin;

    & + .premium-widget,
    & + .premium-offers {
      margin: $w-featured__margin--before-premium;

      @media #{$md} {
        margin-top: 0;
      }
    }

    &--33 {
      #{$this}__frame {
        width: 100%;
      }

      #{$this}__logo {
        max-width: $w-featured-logo__size--33;
        max-height: $w-featured-logo__size--33;
      }

      #{$this}__footer {
        padding: $w-featured-footer__padding--33;
      }

      #{$this}__content {
        padding: $w-featured-content__padding--33;
        line-height: $w-featured-content__line-height--33;

        @media #{$md} {
          padding: $w-featured-content__padding--33--mobile;
        }
      }

      #{$this}__shop-title {
        font-weight: $w-featured-shop-title__font-weight--33;
        text-transform: $w-featured-shop-title__text-transform--33;
      }
    }
  }

  &__item {
    height: 100%;

    &--big {
      .w-featured__frame {
        height: calc(100% - 15px);
        margin: $w-featured-frame__margin;
      }
    }

    &--small {
      flex-direction: column;
    }
  }

  &__frame {
    @include default-box(
      $background: $w-featured__background,
      $border: $w-featured-frame__border
    );

    position: relative;
    display: block;
    height: calc(50% - 15px);
    margin: $w-featured-frame__margin;

    &:hover {
      text-decoration: none;
    }
  }

  &__hero {
    width: 100%;
    height: $w-featured-hero__height;
  }

  &__author {
    display: inline-block;
    padding: $w-featured-author__padding;
    color: $w-featured-author__color;
    font-weight: $w-featured-author__font-weight;
    font-size: $w-featured-author__font-size;
    line-height: $w-featured-author__line-height;
    letter-spacing: $w-featured-author__letter-spacing;
    text-decoration: $w-featured-author__text-decoration;
  }

  &__shop-title {
    display: block;
    grid-area: title;
    font-weight: 700;
    font-size: $w-featured-shop-title__font-size;
    line-height: $w-featured-shop-title__line-height;

    @if $w-featured-shop-title__uppercase == true {
      text-transform: uppercase;
    }
  }

  &__footer {
    position: relative;
    display: flex;
  }

  &__logo {
    max-width: $w-featured-logo__size;
    max-height: $w-featured-logo__size;
    margin: 15px 0 15px 10px;
  }

  &__content {
    display: grid;
    grid-template-areas: $w-featured-content__areas;
    grid-template-rows: $w-featured-content__template-rows;
    padding: $w-featured-content__padding;
    font-size: $w-featured-content__font-size;
  }

  &__time {
    display: block;
    grid-area: title;
  }

  &__code {
    @if $w-featured-code__display != none {
      display: $w-featured-code__display;
      width: $w-featured-code__width;
      height: min-content;
      margin: $w-featured-code__margin;
      padding: $w-featured-code__padding;
      color: $w-featured-code__color;
      font-size: $w-featured-code__font-size;
      line-height: $w-featured-code__line-height;
      text-align: center;
      background: $w-featured-code__background;
    } @else {
      display: none;
    }
  }

  &__exclusive {
    display: flex;
    margin: 0 0 auto;
    padding: $w-featured-exclusive__padding;
    font-weight: 500;
    line-height: 14px;
    background-color: $color-su-pink;
    border: $w-featured-exclusive__border;
    border-radius: $w-featured-exclusive__border-radius;

    @if $icon-exclusive {
      &::before {
        width: auto;
        height: 1em;
        margin-right: 4px;
        aspect-ratio: 1;
        content: $icon-exclusive;
      }
    }
  }

  &__description {
    display: -webkit-box;
    grid-area: desc;
    overflow: hidden;
    line-height: $w-featured-description__line-height;
    text-overflow: ellipsis;
    -webkit-line-clamp: $w-featured-description__line-clamp;
    line-clamp: $w-featured-description__line-clamp;
    -webkit-box-orient: vertical;
  }

  &--same-shop {
    @media #{$min-md} {
      &.col-lg-2 {
        padding-right: 0;
      }

      .col-lg-4 {
        padding: 0 0 0 10px;
      }
    }

    .col-lg-4 {
      &:first-child {
        @media #{$min-md} and #{$lg} {
          padding-left: 0;
        }
      }
    }

    &.col-lg-10 {
      @media #{$md} {
        padding: 0;
      }

      .row {
        margin: 0;
      }
    }

    #{$this}__item {
      height: 100%;
    }

    #{$this}__frame {
      min-height: $w-featured-frame__min-height--same-shop;
    }

    #{$this}__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: unset;
      height: calc(100% - 15px);
      max-height: unset;
      margin: 0;
      background: $w-featured-logo__background--same-shop;
      border: 1px solid $color-su-light-green--50-opacity;
      border-radius: 4px;
      box-shadow: $w-featured-logo__box-shadow--same-shop;

      @media #{$sm} {
        height: calc(100% - 30px);
        margin: 15px 0;
      }
    }

    #{$this}__headline {
      display: flex;
      align-items: center;
      height: 100%;
    }

    #{$this}__hero {
      height: $w-featured-hero__height--same-shop;
    }

    #{$this}__content {
      padding: $w-featured-content__padding--same-shop;
    }
  }
}
