$shop-filter-max-height: 365px !default;
$shop-filter-margin: 0 0 20px 0 !default;
$shop-filter-padding: 25px 0 !default;
$shop-filter-color: $color-black !default;
$shop-filter-background-color: $color-white !default;
$shop-filter-title__height: 35px !default;
$shop-filter-title__padding: 0 36px !default;
$shop-filter-title__color: $color-black !default;
$shop-filter-title__font-size: 20px !default;
$shop-filter-content__max-height: 270px !default;
$shop-filter-content__max-height: 270px !default;
$shop-filter-content__margin: 0 !default;
$shop-filter-content__padding: 0 15px !default;
$shop-filter-content__scrollbar-color: $color-su-bright-green !default;
$shop-filter-content__scrollbar-thumb-color: $color-black !default;
$shop-filter-content__scrollbar-border-radius: 4px !default;
$shop-filter-list__padding: 10px 0 !default;
$shop-filter-list__font-size: 16px !default;
$shop-filter-label__margin-bottom: 4px !default;
$shop-filter-checkbox__width: 18px !default;
$shop-filter-checkbox__height: 18px !default;
$shop-filter-checkbox__margin-right: 15px !default;
$shop-filter-checkbox__border: 1px solid $color-su-light-green !default;
$shop-filter-checkbox__border-radius: 4px !default;
$shop-filter-checkbox__background--checked: $color-su-bright-green !default;
$shop-filter-check-mark__color: $color-white !default;
$shop-filter-check-mark__font-size: 10px !default;
$shop-filter-check-mark__icon: "\e904" !default;

.shop-filter {
  max-height: $shop-filter-max-height;
  margin: $shop-filter-margin;
  padding: $shop-filter-padding;
  overflow: hidden;
  color: $shop-filter-color;
  background-color: $shop-filter-background-color;
  border: 1px solid $color-su-light-green;
  border-radius: 4px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: $shop-filter-title__height;
    padding: $shop-filter-title__padding;
    color: $shop-filter-title__color;
    font-weight: 500;
    font-size: $shop-filter-title__font-size;
    line-height: 28px;
  }

  &__content {
    max-height: $shop-filter-content__max-height;
    margin: $shop-filter-content__margin;
    padding: $shop-filter-content__padding;
    overflow-y: scroll;
    scrollbar-color: $shop-filter-content__scrollbar-color $shop-filter-content__scrollbar-thumb-color;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: $shop-filter-content__scrollbar-thumb-color;
      border-radius: $shop-filter-content__scrollbar-border-radius;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $shop-filter-content__scrollbar-color;
      border-radius: $shop-filter-content__scrollbar-border-radius;
    }
  }

  &__list {
    margin-bottom: 0;
    padding: $shop-filter-list__padding;
    font-size: $shop-filter-list__font-size;
    list-style-type: none;
  }

  &__label {
    margin-bottom: $shop-filter-label__margin-bottom;
    cursor: pointer;

    &:hover {
      font-weight: 500;
    }
  }

  &__checkbox {
    position: relative;
    width: $shop-filter-checkbox__width;
    height: $shop-filter-checkbox__height;
    margin-right: $shop-filter-checkbox__margin-right;
    vertical-align: middle;
    border: $shop-filter-checkbox__border;
    border: 1px solid $color-su-light-green;
    border-radius: $shop-filter-checkbox__border-radius;
    cursor: pointer;
    appearance: none;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }

    &:checked {
      background: $shop-filter-checkbox__background--checked;

      &::before {
        position: absolute;
        top: 1px;
        left: 1px;
        color: $shop-filter-check-mark__color;
        font-size: $shop-filter-check-mark__font-size;
        font-family: simple, sans-serif !important;
        content: $shop-filter-check-mark__icon;
      }
    }
  }
}
