$m-search__padding: 1px 0 !default;
$m-search__background: transparent !default;
$m-search__border: 1px solid $color-black !default;
$m-search-placeholder__color: $placeholder__color !default;
$m-search-input__padding: 10px !default;
$m-search-input__background: $color-white !default;
$m-search-input__font-size: 16px !default;
$m-search-input__text-indent: 4px !default;
$m-search-submit__color: $color-black !default;
$m-search-submit__background: $color-su-pink !default;
$m-search-submit__font-size: 17px !default;
$m-search-submit__width: 58px !default;
$m-search-submit__height: 38px !default;
$m-search-submit__position: right !default;
$m-search-submit__top: 0 !default;
$m-search-submit__indent: 0 !default;
$m-search-submit__border-radius: 0 100px 100px 0 !default;
$m-search-small-btn__font-size: 13px !default;
$m-search-small-btn__top: 0 !default;
$m-search-small-btn__right: 0 !default;
$m-search-small-btn__width: 56px !default;
$m-search-small-btn__height: 38px !default;
$m-search-small-btn__color: $color-white !default;
$m-search-small-btn__color--hover: $color-black !default;
$m-search-small-btn__background: $color-black !default;
$m-search-small-btn__background--hover: $color-su-pink !default;
$m-search-small-input__padding: 6px 10px 6px !default;
$m-search-small__right: 0 !default;
$m-search-small__width: 100% !default;
$m-search-popular__font-size: 14px !default;
$m-search-popular__color: $body__color !default;
$m-search-popular__justify: flex-start !default;
$m-search-popular__uppercase: true !default;
$m-search-popular__margin: 4px 0 0 !default;
$m-search-popular__padding: 0 0 0 17px !default;
$m-search-popular-item__margin: 0 17px 0 0 !default;

.search-container {
  position: relative;
  padding: $m-search__padding;

  .col-lg-3 {
    position: relative;
  }
}

.m-search-box {
  $this: &;

  grid-area: m-search-box;
  background-color: $m-search__background;

  &__form {
    position: relative;
    width: 100%;

    ::placeholder {
      color: $m-search-placeholder__color;
      font-style: normal;
      opacity: 1;
    }

    &--small {
      position: relative;
      z-index: 101;

      #{$this}__submit {
        top: $m-search-small-btn__top;
        right: $m-search-small-btn__right;
        width: $m-search-small-btn__width;
        height: $m-search-small-btn__height;
        color: $m-search-small-btn__color;
        font-size: $m-search-small-btn__font-size;
        background: $m-search-small-btn__background;
        border: 1px solid $color-black;
        border-radius: 0 8px 8px 0;

        @media #{$lg} {
          right: auto;
          left: 0;
          height: 42px;
        }

        &:hover,
        &:active,
        &:focus {
          color: $m-search-small-btn__color--hover;
          background: $m-search-small-btn__background--hover;
        }

        &:hover {
          @media #{$lg} {
            background: transparent;
          }
        }

        @media #{$lg} {
          &--opened {
            &::before {
              position: absolute;
              top: 6px;
              left: -7px;
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-right: 15px solid $m-search-placeholder__color;
              border-bottom: 10px solid transparent;
              content: "";
            }
          }
        }

        @media #{$lg} {
          color: $color-black;
          background: transparent;
          border: none;

          &::before {
            display: none;
          }
        }
      }

      #{$this}__input {
        display: inline-block;
        padding: $m-search-small-input__padding;
        border-radius: 8px;
        outline: none;
        box-shadow: inset 0 0 3px $m-search__background;

        @media #{$lg} {
          padding: 8px 10px;
          text-indent: 40px;

          &::placeholder {
            font-style: normal;
          }
        }

        @media #{$md} {
          &-wrp {
            right: $m-search-small__right;
            width: $m-search-small__width;
          }
        }
      }
    }
  }

  &__input {
    width: 100%;
    padding: $m-search-input__padding;
    font-size: $m-search-input__font-size;
    background: $m-search-input__background;
    border: $m-search__border;
    pointer-events: auto;

    @if $m-search-submit__position == right {
      text-indent: $m-search-input__text-indent;
    } @else if $m-search-submit__position == left {
      text-indent: $m-search-submit__width + $m-search-submit__indent + $m-search-input__text-indent;
    }

    &::-webkit-search-cancel-button {
      display: none;
    }

    &::-ms-clear {
      display: none;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: inset 0 0 3px $m-search__background;
    }
  }

  &__submit {
    position: absolute;
    top: $m-search-submit__top;
    width: $m-search-submit__width;
    height: $m-search-submit__height;
    padding: 0;
    color: $m-search-submit__color;
    font-size: $m-search-submit__font-size;
    line-height: 1;
    background: $m-search-submit__background;
    border: none;
    border-radius: $m-search-submit__border-radius;
    cursor: pointer;

    @if $m-search-submit__position == right {
      right: $m-search-submit__indent;
    } @else if $m-search-submit__position == left {
      left: $m-search-submit__indent;
    }

    &:hover {
      background: darken($m-search-submit__background, 10%);
    }
  }

  &__popular {
    display: flex;
    flex-flow: row wrap;
    justify-content: $m-search-popular__justify;
    margin: $m-search-popular__margin;
    padding: $m-search-popular__padding;
    list-style-type: none;

    @if $m-search-popular__uppercase {
      text-transform: uppercase;
    }

    li {
      display: inline-block;
      margin: $m-search-popular-item__margin;

      a {
        color: $m-search-popular__color;
        font-size: $m-search-popular__font-size;
      }
    }
  }
}

$search-header-results__bg: $color-white !default;
$search-results-media__width: 55px !default;
$search-results-media__height: $search-results-media__width;
$search-results-media__border: none !default;
$search-results-media__bg: $color-white !default;
$search-result-link__padding: 0 !default;
$search-result-link__color: $body__color !default;
$search-result-link__color--hover: $body__color !default;
$search-result-text-first-line__font-size: $body__font-size !default;
$search-result-text-second-line__font-size: 14px !default;
$search-result-hr__color: $body__color !default;
$search-result-hr__margin: 8px 0 !default;
$search-result-text__justify-content: center !default;
$search-result-text__padding: 0 0 0 15px !default;

.search-header-results {
  position: absolute;
  z-index: 100;
  width: 100%;
  margin-top: 8px;
  background-color: $search-header-results__bg;
  border: 1px solid $color-black;
  border-radius: 8px 8px 0 0;
  box-shadow: $su-box-shadow-light-2;

  @media #{$min-lg} {
    max-width: 300px;
  }

  @media #{$lg} {
    top: 13px;
    width: calc(100% - 30px);
  }
}

.search-result {
  margin: 0;
  padding: 15px;
  list-style-type: none;
  border: 1px solid $color-su-light-green;

  @media #{$lg} {
    padding: 42px 10px 15px;
    border-radius: 8px 8px 0 0;
    box-shadow: $su-box-shadow-neo-brutalism;
  }

  &__link {
    display: flex;
    padding: $search-result-link__padding;
    color: $search-result-link__color;

    &:hover {
      color: $search-result-link__color--hover;
    }
  }

  &__media {
    flex-shrink: 0;
    width: $search-results-media__width;
    height: $search-results-media__height;
    background: $search-results-media__bg;
    border: $search-results-media__border;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: $search-result-text__justify-content;
    padding: $search-result-text__padding;
  }

  .first-line {
    font-size: $search-result-text-first-line__font-size;
  }

  .second-line {
    font-size: $search-result-text-second-line__font-size;

    &::before {
      @include svg-icon-base(14px, 10px, $icon-coupon-percent);

      background-size: contain;
    }
  }

  hr {
    border: 0;
    border-top: 1px solid $color-black;
  }
}
