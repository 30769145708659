$topbar-newsletter-btn__font-weight: 700 !default;
$topbar-newsletter-btn__font-size: 12px !default;
$topbar-newsletter-btn__line-height: 12px !default;
$topbar-newsletter-btn-before__icon: $icon-envelope-pink--small !default;
$topbar-newsletter-btn-before__margin-left: 5px !default;
$topbar-newsletter__padding: 40px 70px !default;
$topbar-newsletter__bg-color: $color-white !default;
$topbar-newsletter__border: 1px solid $color-black !default;
$topbar-newsletter__box-shadow: $su-box-shadow-neo-brutalism !default;
$topbar-newsletter-title__display: grid !default;
$topbar-newsletter-title__grid-flow: roe !default;
$topbar-newsletter-title__align-items: center !default;
$topbar-newsletter-title__margin: 0 0 26px !default;
$topbar-newsletter-title__color: $color-black !default;
$topbar-newsletter-title__font-weight: 700 !default;
$topbar-newsletter-title__font-size: 22px !default;
$topbar-newsletter-title__line-height: 26px !default;
$topbar-newsletter-form__display: grid !default;
$topbar-newsletter-form__grid-template-areas:
  "input input button"
  "privacy-note privacy-note privacy-note" !default;
$topbar-newsletter-form__gap: 20px 8px !default;
$topbar-newsletter-input__padding: 18px !default;
$topbar-newsletter-input__color: $color-su-grey !default;
$topbar-newsletter-input__font-weight: 400 !default;
$topbar-newsletter-input__font-size: 16px !default;
$topbar-newsletter-input__line-height: 20px !default;
$topbar-newsletter-input__bg-color: $color-ivory-mist !default;
$topbar-newsletter-input__border: 1px solid $color-black !default;
$topbar-newsletter-input__border-radius: 8px !default;
$topbar-newsletter-button__padding: 18px 8px !default;
$topbar-newsletter-button__color: $color-white !default;
$topbar-newsletter-button__font-weight: 700 !default;
$topbar-newsletter-button__font-size: 16px !default;
$topbar-newsletter-button__line-height: 20px !default;
$topbar-newsletter-button__bg-color: $color-su-bright-green !default;
$topbar-newsletter-button__border: 1px solid $color-black !default;
$topbar-newsletter-button__border-radius: 8px !default;
$topbar-newsletter-button__box-shadow: $su-box-shadow-neo-brutalism !default;
$topbar-newsletter-button__color--hover: $color-white !default;
$topbar-newsletter-button__bg-color--hover: $color-su-pink !default;
$topbar-newsletter-button__box-shadow--hover: none !default;
$topbar-newsletter-privacy-policy__line-clamp--small: 1 !default;
$topbar-newsletter-toggle__icon: $icon-toggle-arrow !default;
$topbar-newsletter-toggle__margin: 0 0 0 5px !default;
$topbar-newsletter-toggle__transition: transform ease-in-out 0.3s !default;
$topbar-newsletter-toggle__transform--opened: rotate(-90deg) !default;

.topbar {
  z-index: 1041;
  display: flex;
  grid-area: topbar;
  align-items: center;
  justify-content: center;
  padding: 6px 6px 10px;
  column-gap: 12px;
  background-color: $color-su-pink;

  .modal-open & {
    z-index: 0;
  }

  &__newsletter {
    &-title {
      margin: 0;
      color: $color-black;
      font-weight: 700;
      font-size: 14px;
      line-height: 28px;
    }

    &-btn {
      display: flex;
      align-items: center;
      padding: 4px 30px;
      color: $color-white;
      font-weight: $topbar-newsletter-btn__font-weight;
      font-size: $topbar-newsletter-btn__font-size;
      line-height: $topbar-newsletter-btn__line-height;
      text-transform: uppercase;
      background-color: $color-su-bright-green;
      border: 1px solid $color-black;
      border-radius: 8px;
      box-shadow: $su-box-shadow-neo-brutalism;
      cursor: pointer;

      &:hover {
        background-color: $color-su-pink;
      }

      &::after {
        @include svg-icon-base(14px, 14px, $topbar-newsletter-btn-before__icon);

        margin-left: $topbar-newsletter-btn-before__margin-left;
      }

      @media (max-width: 320px) {
        padding-inline: 20px;
      }
    }
  }
}

.topbar-newsletter {
  padding: $topbar-newsletter__padding;
  text-align: center;
  background-color: $topbar-newsletter__bg-color;
  border: $topbar-newsletter__border;
  box-shadow: $topbar-newsletter__box-shadow;

  @media #{$md} {
    padding: 40px 15px;
    row-gap: 18px;
  }

  &__images {
    position: relative;
    display: inline-block;
    margin-bottom: 44px;

    &::after {
      @include svg-icon-base(120px, 76px, $icons-for-newsletter);

      position: absolute;
      right: 0;
      bottom: -33px;
      background-size: contain;
    }
  }

  &__image {
    width: 140px;
    height: 130px;
  }

  &__title {
    margin: $topbar-newsletter-title__margin;
    color: $topbar-newsletter-title__color;
    font-weight: $topbar-newsletter-title__font-weight;
    font-size: $topbar-newsletter-title__font-size;
    line-height: $topbar-newsletter-title__line-height;
  }

  &__form {
    display: $topbar-newsletter-form__display;
    grid-template-areas: $topbar-newsletter-form__grid-template-areas;
    gap: $topbar-newsletter-form__gap;

    @media #{$md} {
      grid-template-areas:
        "input"
        "button"
        "privacy-note";
    }
  }

  &__input {
    grid-area: input;
    padding: $topbar-newsletter-input__padding;
    color: $topbar-newsletter-input__color;
    font-weight: $topbar-newsletter-input__font-weight;
    font-size: $topbar-newsletter-input__font-size;
    line-height: $topbar-newsletter-input__line-height;
    background-color: $topbar-newsletter-input__bg-color;
    border: $topbar-newsletter-input__border;
    border-radius: $topbar-newsletter-input__border-radius;
  }

  &__button {
    grid-area: button;
    padding: $topbar-newsletter-button__padding;
    color: $topbar-newsletter-button__color;
    font-weight: $topbar-newsletter-button__font-weight;
    font-size: $topbar-newsletter-button__font-size;
    line-height: $topbar-newsletter-button__line-height;
    text-transform: uppercase;
    background-color: $topbar-newsletter-button__bg-color;
    border: $topbar-newsletter-button__border;
    border-radius: $topbar-newsletter-button__border-radius;
    box-shadow: $topbar-newsletter-button__box-shadow;

    &:hover {
      color: $topbar-newsletter-button__color--hover;
      background-color: $topbar-newsletter-button__bg-color--hover;
      box-shadow: $topbar-newsletter-button__box-shadow--hover;
    }
  }

  &__privacy-note {
    grid-area: privacy-note;
    text-align: left;

    .privacy_policy {
      &--small {
        display: -webkit-box;
        -webkit-line-clamp: $topbar-newsletter-privacy-policy__line-clamp--small;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }

      input[type="checkbox"] {
        position: relative;
        width: 15px;
        height: 15px;
        background-color: $color-su-bright-green;
        border: 1px solid $color-black;
        border-radius: 4px;
        box-shadow: 2px 2px $color-black;
        appearance: none;

        &:checked {
          &::before {
            position: absolute;
            top: 1px;
            left: 1px;
            color: $color-white;
            font-size: 9px;
            font-family: simple, sans-serif !important;
            content: "\e904";
          }
        }
      }
    }
  }

  &__toggle {
    cursor: pointer;

    &::after {
      @include svg-icon-base(6px, 9px, $topbar-newsletter-toggle__icon);

      margin: $topbar-newsletter-toggle__margin;
      transition: $topbar-newsletter-toggle__transition;
    }

    &--opened {
      &::after {
        transform: $topbar-newsletter-toggle__transform--opened;
      }
    }
  }
}
