$coupons-grid__padding: 0 !default;
$coupons-grid-item-wrapper__padding: 0.5rem !default;
$coupons-grid-item-wrapper__padding--mobile: 0.5rem 15px !default;
$coupons-grid-item__justify-content: flex-start !default;
$coupons-grid-item__height: 100% !default;
$coupons-grid-item__padding: 40px 15px 15px !default;
$coupons-grid-item__padding--mobile: 15px !default;
$coupons-grid-item__background-color: $color-white !default;
$coupons-grid-item__border: 1px solid $color-black !default;
$coupons-grid-item__box-shadow: $su-box-shadow-light-2 !default;
$coupons-grid-title__margin: 0 !default;
$coupons-grid-title__font-weight: 700 !default;
$coupons-grid-title__font-size: 16px !default;
$coupons-grid-title__line-height: 22px !default;
$coupons-grid-title__line-height--mobile: 20px !default;
$coupons-grid-label__position: absolute !default;
$coupons-grid-label__top: 15px !default;
$coupons-grid-label__left: 15px !default;
$coupons-grid-logo__width: 120px !default;
$coupons-grid-logo__height: 120px !default;
$coupons-grid-btn__max-width: 100% !default;
$coupons-grid-btn__width: 220px !default;
$coupons-grid-btn__margin: 0 auto !default;
$coupons-grid-btn__padding: 0 !default;
$coupons-grid-btn__color: $color-white !default;
$coupons-grid-btn__font-weight: 700 !default;
$coupons-grid-btn__font-size: 14px !default;
$coupons-grid-btn__text-decoration: none !default;
$coupons-grid-btn__background-color: $color-su-saturated-green !default;
$coupons-grid-btn__border: 1px solid $color-black !default;
$coupons-grid-btn__border-radius: 8px !default;
$coupons-grid-clickout__margin: 20px auto 0 !default;
$coupons-grid-show-more-coupons__max-width: 175px !default;
$coupons-grid-show-more-coupons__margin: auto !default;
$coupons-grid-related-shops-list__margin: 0 !default;
$coupons-grid-related-shops-list__padding: 0 !default;
$coupons-grid-related-shops-li__max-width: 148px !default;
$coupons-grid-related-shops-li__max-width--mobile: 148px !default;
$coupons-grid-related-shops-li__margin: 11px !default;
$coupons-grid-related-shops-li__border: 1px solid $color-su-light-green--50-opacity !default;

.coupons-grid {
  margin-bottom: 30px;

  @media #{$min-sm} {
    padding: $coupons-grid__padding;
  }

  .p-2 {
    padding: $coupons-grid-item-wrapper__padding;

    @media #{$sm} {
      padding: $coupons-grid-item-wrapper__padding--mobile;
    }
  }

  &__item {
    @include default-box;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: $coupons-grid-item__justify-content;
    height: $coupons-grid-item__height;
    padding: $coupons-grid-item__padding;
    text-align: center;

    @media #{$md} {
      padding: $coupons-grid-item__padding--mobile;
    }
  }

  &__title {
    min-height: 66px;
    margin: $coupons-grid-title__margin;
    color: $color-black;
    font-weight: $coupons-grid-title__font-weight;
    font-size: $coupons-grid-title__font-size;
    line-height: $coupons-grid-title__line-height;

    @media #{$md} {
      min-height: 60px;
      line-height: $coupons-grid-title__line-height--mobile;
    }
  }

  .coupon {
    $this: ".coupon";

    &__label {
      @extend %coupon-label;

      &--exclusive {
        @extend %coupon-label--exclusive;

        position: $coupons-grid-label__position;
        top: $coupons-grid-label__top;
        left: $coupons-grid-label__left;
        text-transform: capitalize;
      }
    }

    &__logo {
      width: $coupons-grid-logo__width;
      height: $coupons-grid-logo__height;
      margin-bottom: 12px;

      a,
      img {
        min-width: 100%;
        min-height: 100%;
      }
    }

    &__action-btn {
      width: $coupons-grid-btn__width;
      max-width: $coupons-grid-btn__max-width;
      margin: $coupons-grid-clickout__margin;
      padding: $coupons-grid-btn__padding;
      color: $coupons-grid-btn__color;
      font-weight: $coupons-grid-btn__font-weight;
      font-size: $coupons-grid-btn__font-size;
      text-decoration: $coupons-grid-btn__text-decoration;
      background-color: $coupons-grid-btn__background-color;
      border: $coupons-grid-btn__border;
      border-radius: $coupons-grid-btn__border-radius;

      @media #{$min-md} {
        &:hover {
          background-color: $color-su-pink;
          box-shadow: none;

          #{$this}__action-btn-text {
            color: $color-black;
          }
        }
      }

      &--code {
        @include unleashed-code;

        display: flex;
        background: transparent;
        border: none;

        @media #{$min-md} {
          &:hover {
            #{$this}__action-btn-text {
              width: calc(100% - 38px);
              color: $color-black;
              background-color: $color-su-pink;
            }
          }
        }

        #{$this}__action-btn-text {
          z-index: 1;
          width: calc(100% - 32px);
          color: $coupons-grid-btn__color;
          background-color: $coupons-grid-btn__background-color;
          border: $coupons-grid-btn__border;
          border-radius: $coupons-grid-btn__border-radius;
          transition: width 0.1s ease-in;
        }
      }
    }
  }

  .show-more-coupons {
    max-width: $coupons-grid-show-more-coupons__max-width;
    margin: $coupons-grid-show-more-coupons__margin;
    padding-top: 8px;
    text-align: center;
    cursor: pointer;

    &::after {
      position: relative;
      top: -1px;
      margin-left: 5px;
      content: $icon-toggle-arrow;
    }
  }
}

#campaign-related-shops {
  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  .list-unstyled {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: $coupons-grid-related-shops-list__margin;
    padding: $coupons-grid-related-shops-list__padding;
    list-style-type: none;

    li {
      max-width: $coupons-grid-related-shops-li__max-width;
      margin: $coupons-grid-related-shops-li__margin;
      padding: 19px;
      border: $coupons-grid-related-shops-li__border;
      border-radius: 4px;
      box-shadow: $su-box-shadow-light-2;

      @media #{$sm} {
        max-width: $coupons-grid-related-shops-li__max-width--mobile;
      }
    }
  }
}
