$m-nav__padding: 0 !default;
$m-nav__bg: $color-ivory-mist !default;
$m-nav__justify: flex-start !default;
$m-nav__align-items: center !default;
$m-nav__height: auto !default;
$m-nav__height--mobile: auto !default;
$m-nav-link__padding: 9px 15px !default;
$m-nav-link__padding--mobile: 8px 15px !default;
$m-nav-link__color--hover: $color-su-saturated-green !default;
$m-nav-link__font-weight: 600 !default;
$m-nav-link__font-size: 14px !default;
$m-nav-link__uppercase: false !default;
$m-nav-submenu__top: 128px !default;
$m-nav-submenu__top--mobile: 82px !default;
$m-nav-submenu__padding: 50px 135px !default;
$m-nav-submenu__padding--mobile: 21px 0 10px !default;
$m-nav-submenu__border-top: 2px solid $color-black !default;
$m-nav-submenu__background: $m-nav__bg !default;
$m-nav-submenu-link__padding: 0 !default;
$m-nav-submenu__font-weight: 600 !default;
$m-nav-submenu__font-size: 18px !default;
$m-nav-submenu__color--hover: $color-black !default;
$m-nav-submenu__text-decoration--hover: underline 2px !default;
$m-nav-submenu__background--hover: transparent !default;
$m-nav-arrow__width: 29px !default;
$m-nav-arrow__height: 24px !default;
$m-nav-arrow__color: $color-white !default;
$m-nav-arrow__background: transparent !default;

.header-wrapper {
  position: relative;
  z-index: 1041;
  display: grid;
  grid-area: m-navigation;
  padding: 10px 87px 0 135px;
  background-color: $color-ivory-mist;
  border-bottom: 2px solid $color-black;

  @media #{$min-lg} {
    grid-template:
      "topbar . search"
      "m-navigation m-navigation m-navigation";
    grid-template-rows: 44px auto auto;
    grid-template-columns: auto auto 300px;
    column-gap: 15px;
  }

  @media #{$xl} {
    padding-left: 20px;
  }

  @media #{$lg} {
    grid-template:
      "topbar topbar"
      "search search"
      "m-navigation m-navigation" auto / auto;
    padding: 5px 0 5px 5px;
  }

  .sub-nav-bar {
    position: relative;
    grid-area: search;

    @media #{$lg} {
      position: absolute;
      top: 1px;
      display: none;
      width: 100%;
      padding: 20px 15px 12px;
      background-color: $color-ivory-mist;

      &--opened {
        display: block;
      }
    }
  }

  .modal-open & {
    z-index: 1040;
  }
}

.m-navigation {
  $this: &;

  grid-area: m-navigation;
  margin: 20px 0 0;
  padding: 0;
  background-color: $m-nav__bg;

  @media #{$lg} {
    position: absolute;
    top: 74px;
    display: none;
    width: 100%;
    margin: 0;
    padding: 0 15px;

    &--opened {
      display: block;
    }
  }

  &__list {
    display: flex;
    align-items: $m-nav__align-items;
    justify-content: $m-nav__justify;
    height: $m-nav__height;
    margin: 0;
    padding: $m-nav__padding;
    list-style-type: none;
    background-color: $m-nav__bg;

    @media #{$lg} {
      flex-direction: column;
      height: $m-nav__height--mobile;
      margin: 0 -15px;
      overflow: hidden;
    }
  }

  &__arrow {
    position: absolute;
    top: 7px;
    display: none;
    width: $m-nav-arrow__width;
    height: $m-nav-arrow__height;
    color: $m-nav-arrow__color;
    font-size: 20px;
    text-align: center;
    background-color: $m-nav-arrow__background;

    &--left {
      left: 0;
      transform: rotate(180deg);
    }

    &--right {
      right: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    height: 100%;
    padding-bottom: 20px;
    white-space: nowrap;

    &[class*="has-sub-menu"] {
      #{$this}__link {
        &::after {
          @include svg-icon-base(6px, 10px, $icon-right-arrow--black);

          margin-left: 2px;
        }
      }
    }

    &:hover {
      .sub-menu {
        @media #{$min-lg} {
          display: grid;
        }
      }
    }

    @media #{$min-lg} {
      &[class*="has-sub-menu"] {
        &:hover {
          #{$this}__link {
            color: $color-white;
            text-decoration: none;
            background-color: $color-su-saturated-green;
            border-radius: 8px;
            box-shadow: $su-box-shadow-neo-brutalism;

            &::after {
              background-image: $icon-right-arrow--white;
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    @media #{$lg} {
      flex-direction: column;
      width: 100%;
      padding: 0;

      &--active {
        &[class*="has-sub-menu"] {
          .sub-menu {
            display: block;
          }
        }
      }
    }
  }

  &__link {
    padding: $m-nav-link__padding;
    font-weight: $m-nav-link__font-weight;
    font-size: $m-nav-link__font-size;
    line-height: 16px;

    @if $m-nav-link__uppercase {
      text-transform: uppercase;
    }

    @media #{$min-lg} {
      &:hover {
        color: $m-nav-link__color--hover;
        text-decoration: underline 2px;
      }
    }

    @media #{$lg} {
      position: relative;
      width: 100%;
      padding: $m-nav-link__padding--mobile;
      font-size: 20px;
      line-height: 24px;

      &::after {
        position: absolute;
        top: 16px;
        right: 15px;
      }
    }
  }
}

.sub-menu {
  display: none;
  width: 100%;
  padding: 7px 30px;
  white-space: normal;
  background-color: $m-nav-submenu__background;

  @media #{$min-lg} {
    position: absolute;
    top: $m-nav-submenu__top;
    left: 0;
    z-index: 1041;
    grid-template:
      "sub-menu-title see-all sub-nav-image" 36px
      "sub-menu-list see-all sub-nav-image" 1fr / 1fr 1fr 1fr;
    padding: $m-nav-submenu__padding;
    border-top: $m-nav-submenu__border-top;
  }

  @media #{$xl} and #{$min-lg} {
    padding: 50px;
  }

  @media #{$lg} {
    position: relative;
    min-height: 295px;
    margin: 0 -15px;
  }

  &__image {
    grid-area: sub-nav-image;
    margin: auto;

    @media #{$lg} {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0;
      transform: rotate(15deg) translateX(38%) translateY(-53px);
    }
  }

  .has-sub-menu & {
    background-color: $color-su-pink;

    &__see-all {
      display: none;
    }

    @media #{$min-lg} {
      grid-template:
        "sub-menu-title sub-nav-image" 36px
        "sub-menu-list sub-nav-image" 1fr / 1fr 322px;

      &__list {
        grid-template-columns: 1fr 1fr 1fr;
      }

      &__link {
        &:hover {
          color: $color-su-saturated-green;

          &::before {
            background-image: $icon-arrow-green;
          }
        }
      }
    }
  }

  .has-sub-menu__categories & {
    background-color: $color-su-deep-blue;

    &__link {
      &:hover {
        color: $color-su-pink;
      }
    }
  }

  .has-sub-menu__specials & {
    background-color: $color-su-saturated-green;

    @media #{$lg} {
      min-height: auto;
    }

    &__link,
    &__title {
      color: $color-white;
    }
  }

  &__title {
    grid-area: sub-menu-title;
    margin: 0 0 12px;
    color: $color-black;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    @media #{$lg} {
      display: none;
    }
  }

  &__list {
    position: relative;
    z-index: 1;
    grid-area: sub-menu-list;
    max-width: map-get($container-max-widths, xl);
    padding: 0;
    list-style-type: none;

    @media #{$min-lg} {
      display: grid;
    }
  }

  &__item {
    display: inline-block;
    width: 100%;
    height: fit-content;
    margin-bottom: 6px;

    @media #{$sm} {
      width: 100%;
    }
  }

  &__link {
    display: inline-block;
    padding: $m-nav-submenu-link__padding;
    font-weight: $m-nav-submenu__font-weight;
    font-size: $m-nav-submenu__font-size;
    line-height: 26px;

    @include c4r-arrow-link;

    &:hover {
      color: $m-nav-submenu__color--hover;
      text-decoration: $m-nav-submenu__text-decoration--hover;
      background-color: $m-nav-submenu__background--hover;
    }
  }

  &__see-all {
    position: relative;
    z-index: 1;
    display: inline-block;
    grid-area: see-all;
    align-self: flex-end;
    max-width: 220px;
    margin: 0 auto;
    padding: 3px 21px;
    color: $color-white;
    font-weight: 700;
    line-height: 14;
    line-height: 30px;
    text-transform: uppercase;
    background-color: $color-su-saturated-green;
    border: 1px solid $color-black;
    border-radius: 8px;
    box-shadow: $su-box-shadow-neo-brutalism;

    @media #{$lg} {
      margin: 16px 0 10px;
    }

    &:hover {
      color: $color-black;
      text-decoration: none;
      background-color: $color-su-pink;
    }
  }
}
