.card-static-header {
  .card-static-header {
    margin-top: 20px;
    overflow: hidden;

    &__text {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      &--full {
        display: block;
      }
    }
  }
}
