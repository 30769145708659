/* Star rating */
$star-rating__color: unset !default;
$star-rating__color--active: unset !default;
$star-rating__font-size: 14px !default;
$star-rating-star__font-size: 23px !default;
$star-rating__align-items: center !default;
$star-rating-count__font-weight: 600 !default;

.star-rating {
  $this: &;

  display: flex;
  flex-flow: wrap;
  align-items: $star-rating__align-items;
  justify-content: center;

  &.load {
    opacity: 0.6;
  }

  &--voted {
    #{$this}__list {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &__list {
    display: inline-block;
    margin-bottom: 0;
    padding: 15px 0 0;
    padding: 5px 0 15px;
    list-style-type: none;
    background: transparent;
    cursor: pointer;
  }

  &__item {
    float: right;
    margin: 0 5px;
    color: $star-rating__color;
    font-size: $star-rating-star__font-size;
    font-family: simple, serif;
    line-height: 1;
    box-shadow: none;

    &::before {
      color: $color-su-pink;
      font-size: 30px;
      content: $icon-star-rating--empty;
    }

    &:focus,
    &:active {
      margin-top: 0;
      box-shadow: none;
    }

    &--active,
    &--active ~ li,
    &:hover,
    &:hover ~ li {
      &::before {
        content: $icon-star-rating--bg-green;
      }
    }
  }

  &__star {
    color: $star-rating__color;
  }

  &__value,
  &__count {
    font-weight: $star-rating-count__font-weight;
  }

  p {
    margin-top: 14px;
    font-size: $star-rating__font-size;
  }

  + p {
    font-size: $star-rating__font-size;
  }
}

.star-rating__count::after {
  content: attr(data-reviewCount);
}

.star-rating__value::after {
  content: attr(data-ratingValue);
}
