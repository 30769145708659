/* Coupon filter */
$coupon-filter__text-transform: none !default;
$coupon-filter-shops__margin: 30px 0 20px !default;
$coupon-filter-shops__margin--mobile: 20px 0 !default;
$coupon-filter-campaign__margin: 8px 0 !default;
$coupon-filter__padding: 0 !default;
$coupon-filter-list-item__margin: 0 20px 0 0 !default;
$coupon-filter-list-item__padding: 9px 10px !default;
$coupon-filter__color: $color-black !default;
$coupon-filter__color--active: $color-white !default;
$coupon-filter__font-size: 14px !default;
$coupon-filter__line-height: 14px !default;
$coupon-filter__bg-color: $color-white !default;
$coupon-filter__bg-color--hover: $color-ivory-mist !default;
$coupon-filter__bg-color--active: $color-black !default;

.coupon-filter {
  display: none;
}

.shops,
.campaigns {
  .coupon-filter {
    display: block;
    padding: $coupon-filter__padding;
    list-style-type: none;

    &__item {
      display: inline-block;
      margin: $coupon-filter-list-item__margin;
      padding: $coupon-filter-list-item__padding;
      color: $coupon-filter__color;
      font-size: $coupon-filter__font-size;
      line-height: $coupon-filter__line-height;
      text-transform: $coupon-filter__text-transform;
      background-color: $coupon-filter__bg-color;
      border: 1px solid $color-black;
      border-radius: 100px;
      cursor: pointer;

      &:last-of-type {
        margin: 0;
      }

      &.active {
        color: $coupon-filter__color--active;
        background-color: $coupon-filter__bg-color--active;
      }

      &:hover:not(.active) {
        @if $coupon-filter__bg-color--hover != $coupon-filter__bg-color {
          background-color: $coupon-filter__bg-color--hover;
        }
      }
    }
  }
}

.shops {
  .coupon-filter {
    margin: $coupon-filter-shops__margin;

    @media #{$lg} {
      display: flex;
      justify-content: space-around;
      margin: $coupon-filter-shops__margin--mobile;
    }

    @media #{$min-lg} {
      margin-left: calc(33.3% + var(--bs-gutter-x) * 0.5);
    }
  }
}

.campaigns {
  .coupon-filter {
    margin: $coupon-filter-campaign__margin;
    text-align: center;
  }
}
