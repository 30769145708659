$w-rotated__margin: 26px !default;
$w-rotated-frame__margin: 0 0 15px !default;
$w-rotated__background: $color-white !default;
$w-rotated-shop-title__color: $color-black !default;
$w-rotated-shop-title__uppercase: false !default;
$w-rotated-logo__size: 80px !default;
$w-rotated-content__padding: 4px 5px 24px 10px !default;
$w-rotated-content__color: $color-black !default;
$w-rotated-content__font-size: 14px !default;

.w-rotated {
  $this: &;

  &__list {
    margin-top: $w-rotated__margin;
  }

  &__item {
    display: flex;
    height: 100%;

    &--small {
      flex-direction: column;
    }
  }

  &__frame {
    display: block;
    height: 50%;
    min-height: 112px;
    margin: $w-rotated-frame__margin;

    @include default-box($w-rotated__background);

    &:hover {
      text-decoration: none;
    }
  }

  &__headline {
    float: left;
  }

  &__shop-title {
    display: block;
    color: $w-rotated-shop-title__color;

    @if $w-rotated-shop-title__uppercase {
      text-transform: uppercase;
    }
  }

  &__footer {
    display: flex;
  }

  &__logo {
    max-width: $w-rotated-logo__size;
    max-height: $w-rotated-logo__size;
  }

  &__content {
    padding: $w-rotated-content__padding;
    color: $w-rotated-content__color;
    font-size: $w-rotated-content__font-size;
  }
}
