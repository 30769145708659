$offer-carousel-main-color: $color-su-bright-green !default;
$offer-carousel-secondary-color: $color-black !default;
$offer-carousel-brand-color: $color-su-bright-green !default;
$offer-carousel__margin-block: 20px !default;
$offer-carousel__padding-inline: 0 !default;
$offer-carousel-container__margin: 0 0 32px !default;
$offer-carousel-container__padding: 0 0 4px !default;
$offer-carousel-container__margin--mobile: 0 !default;
$offer-carousel-container-scrollbar__border: 1px solid $color-black !default;
$offer-carousel-container-scrollbar__border-radius: 6px !default;
$offer-carousel-container-scrollbar__width: 99% !default;
$offer-carousel-container-scrollbar__width--mobile: 99% !default;
$offer-carousel-container-scrollbar__height: 6px !default;
$offer-carousel-container-scrollbar__height--mobile: 6px !default;
$offer-carousel-arrow__top: 25% !default;
$offer-carousel-arrow__side: 0 !default;
$offer-carousel-arrow__padding: 46px 18px;
$offer-carousel-arrow__background-color: $offer-carousel-main-color !default;
$offer-carousel-arrow__box-shadow--left: false !default;
$offer-carousel-arrow__box-shadow--left-hover: false !default;
$offer-carousel-arrow__box-shadow--right: false !default;
$offer-carousel-arrow__box-shadow--right-hover: false !default;
$offer-carousel-arrow__border-radius--left: 0 4px 4px 0 !default;
$offer-carousel-arrow__border-radius--right: 4px 0 0 4px !default;
$offer-carousel-item-wrapper__margin-left: 30px !default;
$offer-carousel-card__margin: 0 #{$offer-carousel-item-wrapper__margin-left / 2} 15px !default;
$offer-carousel-item-wrapper__max-width: calc(25% - #{$offer-carousel-item-wrapper__margin-left / 4 * 3}) !default;
$offer-carousel-item__height: 380px !default;
$offer-carousel-item__width--mobile: 255px !default;
$offer-carousel-item__padding: 15px 0 0 !default;
$offer-carousel-item__border: 1px solid $color-black !default;
$offer-carousel-item__border-radius: 4px !default;
$offer-carousel-item__box-shadow: false !default;
$offer-carousel-img__max-height: 225px !default;
$offer-carousel-img__margin: 0 15px !default;
$offer-carousel-title__margin: 11px 15px 0 !default;
$offer-carousel-title__webkit-line-clamp: 3 !default;
$offer-carousel-title__font-weight: 700 !default;
$offer-carousel-title__font-weight--mobile: 500 !default;
$offer-carousel-title__font-size: 16px !default;
$offer-carousel-title__font-size--mobile: 16px !default;
$offer-carousel-title__line-height: 22px !default;
$offer-carousel-title__line-height--mobile: 20px !default;
$offer-carousel-btn__width: 100% !default;
$offer-carousel-btn__margin: auto 0 0 !default;
$offer-carousel-btn__padding: 4px 0 !default;
$offer-carousel-btn__color: $color-white !default;
$offer-carousel-btn__color--hover: $color-black !default;
$offer-carousel-btn__font-weight: 700 !default;
$offer-carousel-btn__font-size: 14px !default;
$offer-carousel-btn__line-height: 30px !default;
$offer-carousel-btn__text-align: center !default;
$offer-carousel-btn__text-transform: uppercase !default;
$offer-carousel-btn__bg-color: $color-su-bright-green !default;
$offer-carousel-btn__bg-color--hover: $color-su-pink !default;
$offer-carousel-btn__border: solid $color-black !default;
$offer-carousel-btn__border-color--hover: $color-black !default;
$offer-carousel-btn__border-width: 1px 0 0 !default;
$offer-carousel-navigation__padding: 0 !default;
$offer-carousel-navigation__grid-template-areas:
  "scroll-bar scroll-bar"
  "arrow-left arrow-right" !default;
$offer-carousel-navigation-arrow__margin: 0 16px 0 0 !default;
$offer-carousel-navigation-arrow__content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='26' fill='none'%3E%3Cmask id='a' fill='%23fff'%3E%3Cpath d='M11 0 0 13l11 13'/%3E%3C/mask%3E%3Cpath fill='%23000' d='M11 0 0 13l11 13'/%3E%3Cpath fill='%23000' d='M13.29 1.938a3 3 0 0 0-4.58-3.876l4.58 3.876ZM0 13l-2.29-1.938a3 3 0 0 0 0 3.876L0 13Zm8.71 14.938a3 3 0 0 0 4.58-3.876l-4.58 3.876Zm0-29.876-11 13 4.58 3.876 11-13-4.58-3.876Zm-11 16.876 11 13 4.58-3.876-11-13-4.58 3.876Z' mask='url(%23a)'/%3E%3C/svg%3E") !default;
$offer-carousel-navigation-arrow__content--hover: $triangle-right--hover !default;
$offer-carousel-navigation-indicator__bg-color: $offer-carousel-secondary-color !default;
$offer-carousel-navigation-indicator__margin: 0 0 0 16px !default;
$offer-carousel-navigation-indicator__margin--mobile: 0 0 16px !default;
$offer-carousel-navigation-indicator__bg-color: $offer-carousel-brand-color !default;
$offer-carousel-navigation-tracker__bg-color: $color-su-pink !default;

.offer-carousel {
  $this: &;

  margin-block: $offer-carousel__margin-block;

  &__col {
    position: relative;
    padding-inline: $offer-carousel__padding-inline;
  }

  &__container {
    display: flex;
    margin: $offer-carousel-container__margin;
    padding: $offer-carousel-container__padding;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;

    @media #{$md} {
      margin: $offer-carousel-container__margin--mobile;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .col-lg-3.col-md-4 {
      margin-left: $offer-carousel-item-wrapper__margin-left;

      @media #{$min-md} {
        max-width: $offer-carousel-item-wrapper__max-width;
      }

      @media #{$md} {
        margin: $offer-carousel-card__margin;
        scroll-snap-align: center;
      }

      &:first-of-type {
        margin-left: 0;
      }
    }

    &-arrow {
      @include default-box(
        $background: false,
        $box-shadow: $default-box__box-shadow--hover
      );

      position: absolute;
      top: $offer-carousel-arrow__top;
      padding: $offer-carousel-arrow__padding;
      background-color: $offer-carousel-arrow__background-color;
      cursor: pointer;

      &--left {
        left: $offer-carousel-arrow__side;
        border-radius: $offer-carousel-arrow__border-radius--left;

        @if $offer-carousel-arrow__box-shadow--left != false {
          box-shadow: $offer-carousel-arrow__box-shadow--left;
        }

        &:hover {
          @if $offer-carousel-arrow__box-shadow--left-hover != false {
            box-shadow: $offer-carousel-arrow__box-shadow--left-hover;
          }
        }
      }

      &--right {
        right: $offer-carousel-arrow__side;
        border-radius: $offer-carousel-arrow__border-radius--right;

        @if $offer-carousel-arrow__box-shadow--right != false {
          box-shadow: $offer-carousel-arrow__box-shadow--right;
        }

        &:hover {
          @if $offer-carousel-arrow__box-shadow--right-hover != false {
            box-shadow: $offer-carousel-arrow__box-shadow--right-hover;
          }
        }
      }
    }
  }

  &__header {
    margin: 0 -12px;

    .section-header {
      font-weight: 600;

      @media #{$md} {
        font-weight: 500;
      }
    }
  }

  &__item {
    @include default-box(
      $background: false,
      $border: $offer-carousel-item__border,
      $border-radius: $offer-carousel-item__border-radius,
      $box-shadow: $offer-carousel-item__box-shadow
    );

    display: flex;
    flex-direction: column;
    width: 100%;
    height: $offer-carousel-item__height;
    padding: $offer-carousel-item__padding;
    overflow: hidden;

    @media #{$md} {
      width: $offer-carousel-item__width--mobile;
    }

    @at-root #{selector-unify(&, "a")} {
      cursor: pointer;
    }
  }

  &__img {
    width: auto;
    max-height: $offer-carousel-img__max-height;
    margin: $offer-carousel-img__margin;
    object-fit: contain;
  }

  &__shop-title {
    display: none;
  }

  &__title {
    display: -webkit-box;
    margin: $offer-carousel-title__margin;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $offer-carousel-title__webkit-line-clamp;
    font-weight: $offer-carousel-title__font-weight;
    font-size: $offer-carousel-title__font-size;
    line-height: $offer-carousel-title__line-height;

    @media #{$md} {
      font-weight: $offer-carousel-title__font-weight--mobile;
      font-size: $offer-carousel-title__font-size--mobile;
      line-height: $offer-carousel-title__line-height--mobile;
    }
  }

  &__btn {
    display: inline-block;
    width: $offer-carousel-btn__width;
    margin: $offer-carousel-btn__margin;
    padding: $offer-carousel-btn__padding;
    color: $offer-carousel-btn__color;
    font-weight: $offer-carousel-btn__font-weight;
    font-size: $offer-carousel-btn__font-size;
    line-height: $offer-carousel-btn__line-height;
    text-align: $offer-carousel-btn__text-align;
    text-transform: $offer-carousel-btn__text-transform;
    background-color: $offer-carousel-btn__bg-color;
    border: $offer-carousel-btn__border;
    border-width: $offer-carousel-btn__border-width;
    cursor: pointer;

    &:hover {
      color: $offer-carousel-btn__color--hover;
      text-decoration: none;
      background-color: $offer-carousel-btn__bg-color--hover;
      border-color: $offer-carousel-btn__border-color--hover;
    }
  }

  &__navigation {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media #{$min-md} {
      padding: $offer-carousel-navigation__padding;
    }

    @media #{$md} {
      display: grid;
      grid-template-areas: $offer-carousel-navigation__grid-template-areas;
    }

    &-arrow {
      display: inline-block;
      width: 49px;
      height: 48px;
      margin: $offer-carousel-navigation-arrow__margin;
      padding: 11px 18px;
      content: $offer-carousel-navigation-arrow__content;
      pointer-events: none;

      &--left {
        grid-area: arrow-left;

        @media #{$md} {
          margin-left: auto;
        }

        &:hover {
          transform: rotate(180deg);
        }
      }

      &--right {
        grid-area: arrow-right;
        transform: rotate(180deg);

        @media #{$md} {
          margin-right: auto;
        }

        &:hover {
          transform: rotate(0);
        }
      }

      &--active {
        cursor: pointer;
        pointer-events: auto;

        &:hover {
          content: $offer-carousel-navigation-arrow__content--hover;
        }
      }

      &.hidden-xs:not(.hidden) {
        display: block;
      }
    }

    &-indicator,
    &-tracker {
      height: $offer-carousel-container-scrollbar__height;

      @media #{$md} {
        height: $offer-carousel-container-scrollbar__height--mobile;
      }
    }

    &-indicator {
      position: relative;
      grid-area: scroll-bar;
      width: $offer-carousel-container-scrollbar__width;
      margin: $offer-carousel-navigation-indicator__margin;
      padding: 0;
      line-height: 0;
      list-style-type: none;
      background-color: $offer-carousel-navigation-indicator__bg-color;
      border-radius: $offer-carousel-container-scrollbar__border-radius;

      @media #{$md} {
        width: $offer-carousel-container-scrollbar__width--mobile;
        margin: $offer-carousel-navigation-indicator__margin--mobile;
      }
    }

    &-tracker {
      position: absolute;
      display: inline-block;
      background-color: $offer-carousel-navigation-tracker__bg-color;
      border: $offer-carousel-container-scrollbar__border;
      border-radius: $offer-carousel-container-scrollbar__border-radius;
    }
  }
}
