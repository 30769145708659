body {
  &.campaigns {
    &.show {
      &.no-topbar {
        --campaigns-template-areas:
          "head-disclaimer"
          "m-navigation"
          "featured-shops"
          "headline"
          "hero"
          "content"
          "footer";
        --campaigns-template-areas-mobile:
          "head-disclaimer"
          "m-navigation"
          "featured-shops"
          "headline"
          "hero"
          "content"
          "footer";
      }

      --campaigns-template-areas:
        "head-disclaimer"
        "topbar"
        "m-navigation"
        "featured-shops"
        "headline"
        "hero"
        "content"
        "footer";
      --campaigns-template-areas-mobile:
        "head-disclaimer"
        "topbar"
        "m-navigation"
        "featured-shops"
        "headline"
        "hero"
        "content"
        "footer";

      grid-template-areas: var(--campaigns-template-areas);

      @media #{$sm} {
        grid-template-areas: var(--campaigns-template-areas-mobile);
      }
    }

    &.sem {
      &.no-topbar {
        --sem-template-areas:
          "head-disclaimer"
          "header"
          "content"
          "footer";
        --sem-template-areas-mobile:
          "head-disclaimer"
          "header"
          "content"
          "footer";
      }

      --sem-template-areas:
        "head-disclaimer"
        "topbar"
        "header"
        "content"
        "footer";
      --sem-template-areas-mobile:
        "head-disclaimer"
        "topbar"
        "header"
        "content"
        "footer";

      grid-template-areas: var(--sem-template-areas);

      @media #{$sm} {
        grid-template-areas: var(--sem-template-areas-mobile);
      }
    }
  }
}
