$author-show__grid-template-area:
  "head-disclaimer"
  "m-navigation"
  "author-container"
  "author-supervision"
  "author-content"
  "footer" !default;
$author-container__margin: 0 0 40px;
$author-container__margin--mobile: 0 0 30px;
$author-container__padding: 65px 0 !default;
$author-container__bg-color: $color-su-deep-blue !default;
$author-container__padding--mobile: 20px 0 !default;
$author-image__width: 240px !default;
$author-image__width--mobile: 120px !default;
$author-image__height: 240px !default;
$author-image__height--mobile: 120px !default;
$author-image__border-radius: 4px !default;
$author-image__border: 1px solid $color-black !default;
$author-name__margin: 0 !default;
$author-name__font-size: 70px !default;
$author-name__line-height: 76px !default;
$author-name__font-size--mobile: 22px !default;
$author-name__line-height--mobile: 26px !default;
$author-job__margin: 0 !default;
$author-job__font-weight: 500 !default;
$author-job__font-size: 26px !default;
$author-job__line-height: 34px !default;
$author-job__font-size--mobile: 16px !default;
$author-job__line-height--mobile: 20px !default;
$author-description__margin: 0 !default;
$author-description__font-size: 14px !default;
$author-description__line-height: 22px !default;
$author-description__line-height--mobile: 20px !default;
$author-description__text-align--mobile: center !default;
$author-content__padding: 0 !default !default;
$author-content__padding--mobile: 0 15px !default;
$author-content-description__color: $color-black !default;
$author-content-description__font-size: 14px !default;
$author-content-description__line-height: 22px !default;
$author-content-description__line-height--mobile: 20px !default;
$author-supervision__margin: 0 0 50px !default;
$author-supervision__margin--mobile: 0 0 30px !default;
$author-supervision__padding: 0 !default;
$author-supervision__padding--mobile: 0 !default;
$author-supervision-list__gap: 36px !default;
$author-supervision-list__gap--mobile: 16px !default;
$author-supervision-title__font-weight: 500 !default;
$author-supervision-logo__width--mobile: 105px !default;
$author-supervision-logo__height--mobile: 105px !default;
$author-section__gap: 25px !default;
$author-section__areas:
  "img name"
  "img job"
  "img info-desc"
  "img desc" !default;
$author-section__areas--mobile:
  "img"
  "name"
  "job"
  "desc"
  "info-desc" !default;
$author-section__columns: 240px auto !default;
$author-section__gap--mobile: 10px !default;
$author-section__columns--mobile: 1fr !default;
$author-section__justify-items--mobile: center !default;
$author-titles__margin: 0 0 10px !default;
$author-titles__color: $color-black !default;
$author-titles__font-weight: 600 !default;
$author-titles__font-size: 20px !default;
$author-titles__font-size--mobile: 16px !default;
$author-titles__line-height: 24px !default;
$author-titles__line-height--mobile: 20px !default;

.authors.show {
  grid-template-areas: $author-show__grid-template-area;
}

.authors.show {
  .author {
    &-container {
      grid-area: author-container;
      margin: $author-container__margin;
      padding: $author-container__padding;
      background-color: $author-container__bg-color;

      @media #{$md} {
        margin: $author-container__margin--mobile;
        padding: $author-container__padding--mobile;
      }
    }

    &__section {
      display: grid;
      grid-gap: 10px 30px;
      grid-template-areas: $author-section__areas;
      grid-template-columns: $author-section__columns;

      @media #{$md} {
        grid-gap: $author-section__gap--mobile;
        grid-template-areas: $author-section__areas--mobile;
        grid-template-columns: $author-section__columns--mobile;
        justify-items: $author-section__justify-items--mobile;
      }
    }

    &__image {
      grid-area: img;
      width: $author-image__width;
      height: $author-image__height;
      border: $author-image__border;
      border-radius: $author-image__border-radius;

      @media #{$md} {
        width: $author-image__width--mobile;
        height: $author-image__height--mobile;
      }
    }

    &__name {
      grid-area: name;
      margin: $author-name__margin;
      font-size: $author-name__font-size;
      line-height: $author-name__line-height;

      @media #{$md} {
        font-size: $author-name__font-size--mobile;
        line-height: $author-name__line-height--mobile;
      }
    }

    &__job {
      grid-area: job;
      margin: $author-job__margin;
      font-weight: $author-job__font-weight;
      font-size: $author-job__font-size;
      line-height: $author-job__line-height;

      @media #{$md} {
        font-size: $author-job__font-size--mobile;
        line-height: $author-job__line-height--mobile;
        text-align: center;
      }
    }

    &__description {
      grid-area: desc;
      margin: $author-description__margin;
      font-size: $author-description__font-size;
      line-height: $author-description__line-height;

      @media #{$md} {
        line-height: $author-description__line-height--mobile;
        text-align: $author-description__text-align--mobile;
      }
    }

    &-info__description {
      grid-area: info-desc;

      @media #{$md} {
        text-align: center;
      }
    }

    &-content {
      grid-area: author-content;
      padding: $author-content__padding;

      @media #{$md} {
        padding: $author-content__padding--mobile;
      }

      &__description {
        color: $author-content-description__color;
        font-size: $author-content-description__font-size;
        line-height: $author-content-description__line-height;

        @media #{$md} {
          line-height: $author-content-description__line-height--mobile;
        }
      }

      h3 {
        @media #{$md} {
          font-size: $author-titles__font-size--mobile;
          line-height: $author-titles__line-height--mobile;
        }
      }

      p {
        line-height: 22px;

        @media #{$md} {
          line-height: 20px;
        }
      }
    }

    &-supervision {
      grid-area: author-supervision;
      margin: $author-supervision__margin;
      padding: $author-supervision__padding;

      @media #{$md} {
        margin: $author-supervision__margin--mobile;
        padding: $author-supervision__padding--mobile;
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        gap: $author-supervision-list__gap;
        justify-content: center;
        padding: 0;
        list-style-type: none;

        @media #{$md} {
          gap: $author-supervision-list__gap--mobile;
        }
      }

      &__logo {
        @include default-box;

        @media #{$md} {
          width: $author-supervision-logo__width--mobile;
          height: $author-supervision-logo__height--mobile;
        }
      }
    }
  }
}

.author {
  &-supervision__title,
  &-content__title {
    display: flex;
    align-items: center;
    margin: $author-titles__margin;
    color: $author-titles__color;
    font-weight: $author-titles__font-weight;
    font-size: $author-titles__font-size;
    line-height: $author-titles__line-height;

    @media #{$md} {
      font-size: $author-titles__font-size--mobile;
      line-height: $author-titles__line-height--mobile;
    }
  }
}

.authors.show {
  .breadcrumbs {
    &__item {
      a,
      span,
      &::before {
        color: $color-white;
      }
    }
  }
}
