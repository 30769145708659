@mixin svg-icon-base($width, $height, $icon-url: none) {
  display: inline-block;
  width: $width;
  height: $height;
  background-image: $icon-url;
  background-repeat: no-repeat;
  content: "";
}

@mixin default-box(
  $background: $default-box__background,
  $border: $default-box__border,
  $border-radius: $default-box__border-radius,
  $box-shadow: $default-box__box-shadow,
  $box-shadow--hover: $default-box__box-shadow--hover,
  $hover: true
) {
  @if $background {
    background: $background;
  }

  border: $border;
  border-radius: $border-radius;

  @if $box-shadow {
    box-shadow: $box-shadow;
  }

  @if $hover {
    @media #{$min-md} {
      &:hover {
        @if $box-shadow--hover != false {
          box-shadow: $box-shadow--hover;
        }
      }
    }
  }
}

@mixin btn(
  $min-width: $default-btn__min-width,
  $padding: $default-btn__padding,
  $color: $default-btn__color,
  $font-weight: $default-btn__font-weight,
  $font-size: $default-btn__font-size,
  $line-height: $default-btn__line-height,
  $text-transform: $default-btn__text-transform,
  $background: $default-btn__background-color,
  $border: $default-btn__border,
  $border-radius: $default-btn__border-radius,
  $color--hover: $default-btn__color--hover,
  $text-decaration--hover: $default-btn__text-decoration--hover,
  $background--hover: $default-btn__background-color--hover,
  $border--hover: $default-btn__border--hover,
  $box-shadow: $default-btn__box-shadow,
  $box-shadow--hover: $default-btn__box-shadow--hover
) {
  display: inline-block;
  min-width: $min-width;
  padding: $padding;
  color: $color;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  text-align: center;
  text-transform: $text-transform;
  text-decoration: none;
  background-color: $background;
  border: $border;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  cursor: pointer;

  &:hover {
    color: $color;

    @media #{$min-md} {
      color: $color--hover;
      text-decoration: $text-decaration--hover;
      background-color: if($background--hover == $background, darken($background, 10%), $background--hover);
      border: $border--hover;
      box-shadow: $box-shadow--hover;
    }
  }

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }

  @at-root #{selector-unify(&, "a")} {
    &:not([href]) {
      color: $color;
    }
  }
}

@mixin default-list(
  $icon: $icon-arrow-green,
  $icon-width: 14px,
  $icon-height: 14px,
  $icon-margin-right: 7px,
  $margin: 15px,
  $margin--mobile: 10px,
  $font-size: 18px,
  $font-size--mobile: 14px,
  $line-height: 26px,
  $line-height--mobile: 20px
) {
  display: flex;
  align-items: baseline;
  margin-bottom: $margin;
  font-size: $font-size;
  line-height: $line-height;

  @media #{$md} {
    margin-bottom: $margin--mobile;
    font-size: $font-size--mobile;
    line-height: $line-height--mobile;
  }

  @if $icon != false {
    &::before {
      display: block;
      min-width: $icon-width;
      height: $icon-height;
      margin-right: $icon-margin-right;
      background-image: $icon;
      content: "";
    }
  }
}

@function str-replace(
  $string,
  $substring-to-search,
  $replacement: ""
) {
  $index: str-index($string, $substring-to-search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replacement + str-replace(
      str-slice($string, $index + str-length($substring-to-search)),
      $substring-to-search,
      $replacement
    );
  }

  @return $string;
}

/* stylelint-disable max-line-length */

/**
 * this function can help you ease change color for SVG
 * helpful urls:
 * svg optimizer - https://jakearchibald.github.io/svgomg/
 * svg to inline url - https://yoksel.github.io/url-encoder/
 * original SVG
 * <svg xmlns="http://www.w3.org/2000/svg"><circle cx="17" cy="17" r="15" fill="#d8d8d8" stroke="#000"/></svg>
 *
 * use case
 *
 * input
 * background-image: url-with-icon(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17' cy='17' r='15' fill='placeholder-for-color' stroke='green'/%3E%3C/svg%3E"), $color-black);
 * result
 * background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17' cy='17' r='15' fill='%23000' stroke='green'/%3E%3C/svg%3E");
 *
 * input
 * content: url-with-icon("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17' cy='17' r='15' fill='%23d8d8d8' stroke='placeholder-for-color'/%3E%3C/svg%3E", red);
 * result
 * content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17' cy='17' r='15' fill='%23d8d8d8' stroke='red'/%3E%3C/svg%3E");
 */
/* stylelint-enable max-line-length */

@function url-with-icon(
  $svg,
  $color
) {
  /* convert lattice sign  if color given in hex */
  @if str-index(#{$color}, "#") {
    $color: "%23" + str-slice("#{$color}", 2);
  }

  /* add url() if given only string with svg */
  @if not str-index($svg, "url") {
    $svg: url($svg);
  }

  @return str-replace($svg, "placeholder-for-color", $color);
}

%coupon-tag {
  color: $coupon-tag__color;
  font-size: $coupon-tag__font-size;

  &::before {
    margin-right: 3px;
    color: $coupon-tag__color;
    font-family: simple, serif;
    content: $coupon-tag__content;
  }
}

%coupon-label {
  margin: $coupon-label__margin;
  color: $coupon-label__color;
  font-weight: $coupon-label__font-weight;
  font-size: $coupon-label__font-size;
  text-align: $coupon-label__text-align;

  &::before {
    font-weight: normal;
    font-family: simple, serif !important;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    content: $coupon-label__content;
  }

  @media #{$md} {
    font-size: $coupon-label__font-size--mobile;
  }
}

%coupon-label--exclusive {
  color: $coupon-label__color--exclusive;

  &::before {
    padding-right: 5px;
    font-weight: normal;
    font-family: simple, serif !important;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    content: $coupon-label-exclusive__content;
  }
}

%coupon-label-code {
  margin: $coupon-code__margin;
  padding: $coupon-code__padding;
  color: $coupon-code__color;
  font-weight: $coupon-code__font-weight;
  font-size: $coupon-code__font-size;
  line-height: $coupon-code__line-height;
  text-transform: $coupon-code__text-transform;
  background-color: $coupon-code__bg-color;
}

@mixin line-clamp(
  $min-height: inherit,
  $line-clamp: 1
) {
  display: -webkit-box;
  min-height: $min-height;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line-clamp;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin unleashed-code {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 55px;
    padding-right: 12px;
    overflow: hidden;
    color: $color-su-saturated-green;
    font-size: 16px;
    line-height: 16px;
    background-color: white;
    border: 1px solid $color-black;
    border-left: 0;
    border-radius: 8px;
    transition: width 0.1s ease;
    content: "023";
  }
}

@mixin unleashed-code-old {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 36px;
    padding-right: 16px;
    overflow: hidden;
    color: $color-su-bright-green;
    font-size: 16px;
    line-height: 16px;
    background-color: white;
    border: 1px solid $color-su-bright-green;
    border-left: none;
    border-radius: 0 30px 30px 0;
    transition: width 0.1s ease;
    content: "023";
  }

  &::after {
    position: absolute;
    top: 0;
    right: 19px;
    display: inline-block;
    width: 30px;
    height: 40px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='40' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cpath fill='%23fff' d='M29.904 36c-8.44 5.708-19.908 3.478-25.594-4.977l-.525-.782c-5.9-8.773-3.675-20.66 5-26.705L13.858 0l16.045 36Z'/%3E%3C/g%3E%3Cpath stroke='%2300843B' d='m13.648.756 15.627 35.06c-8.176 5.26-19.096 3.037-24.55-5.073l-.526-.78C-1.548 21.415.62 9.834 9.07 3.946L13.65.756Z'/%3E%3Cdefs%3E%3Cfilter id='a' width='32.418' height='43.163' x='-2.514' y='0' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='-3' dy='5'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeComposite in2='hardAlpha' k2='-1' k3='1' operator='arithmetic'/%3E%3CfeColorMatrix values='0 0 0 0 0.0117647 0 0 0 0 0.109804 0 0 0 0 0.0823529 0 0 0 0.15 0'/%3E%3CfeBlend in2='shape' result='effect1_innerShadow_1066_819'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transition: right 0.1s ease;
    content: "";
    pointer-events: none;
  }

  &:hover {
    &::before {
      width: 40px;
      color: $color-su-pink;
      border-color: $color-su-pink;
    }

    &::after {
      right: 23px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='40' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cpath fill='%23fff' d='M29.904 36c-8.44 5.708-19.908 3.478-25.594-4.977l-.525-.782c-5.9-8.773-3.675-20.66 5-26.705L13.858 0l16.045 36Z'/%3E%3C/g%3E%3Cpath stroke='%23F3A7CA' d='m13.648.756 15.627 35.06c-8.176 5.26-19.096 3.037-24.55-5.073l-.526-.78C-1.548 21.415.62 9.834 9.07 3.946L13.65.756Z'/%3E%3Cdefs%3E%3Cfilter id='a' width='32.418' height='43.163' x='-2.514' y='0' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='-3' dy='5'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeComposite in2='hardAlpha' k2='-1' k3='1' operator='arithmetic'/%3E%3CfeColorMatrix values='0 0 0 0 0.0117647 0 0 0 0 0.109804 0 0 0 0 0.0823529 0 0 0 0.15 0'/%3E%3CfeBlend in2='shape' result='effect1_innerShadow_1066_819'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
    }
  }
}

@mixin c4r-item-box(
  $border: 1px solid $color-su-light-green,
  $border-radius: 4px,
  $box-shadow: $su-box-shadow-light-2,
  $box-shadow--hover: none
) {
  border: $border;
  border-radius: $border-radius;

  @if $box-shadow {
    box-shadow: $box-shadow;
  }

  &:hover {
    @if $box-shadow--hover {
      box-shadow: $box-shadow--hover;
    }
  }
}

@mixin image-bg-white-gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: inline-block;
  background: linear-gradient(0deg, $color-white 0%, #FFF0 40%);
  opacity: 1;
  content: "";
}

@mixin c4r-arrow-link($top: 2px) {
  &::before {
    @include svg-icon-base(14px, 14px, $icon-arrow-green);

    position: relative;
    top: $top;
    margin-right: 7px;
  }

  &:hover {
    color: $color-su-saturated-green;
    text-decoration: underline $color-su-saturated-green;

    &::before {
      background-image: $icon-arrow-pink;
    }
  }
}
