$shop-info-mobile__background: $color-white !default;
$shop-info-mobile__align-items: center !default;
$shop-info-mobile__justify-content: space-between !default;
$shop-info-mobile__margin: 39px 0 0 !default;
$shop-info-mobile__padding: 20px 17px !default;
$shop-info-mobile-title__margin: 0 !default;
$shop-info-mobile-title__font-weight: 600 !default;
$shop-info-mobile-title__font-size: 24px !default;
$shop-info-mobile-title__line-height: 1.1 !default;
$shop-info-mobile-logo__border: 1px solid $color-light-grey-2 !default;

.shop-info-mobile {
  @media #{$md} {
    @include default-box($shop-info-mobile__background);

    display: flex;
    align-items: $shop-info-mobile__align-items;
    justify-content: $shop-info-mobile__justify-content;
    margin: $shop-info-mobile__margin;
    padding: $shop-info-mobile__padding;

    &__title {
      display: inline-block;
      margin: $shop-info-mobile-title__margin;
      font-weight: $shop-info-mobile-title__font-weight;
      font-size: $shop-info-mobile-title__font-size;
      line-height: $shop-info-mobile-title__line-height;
    }

    &__logo {
      border: $shop-info-mobile-logo__border;
    }
  }
}
