$top-similar-shops-h2__font-weight: 600 !default;
$top-similar-shops-h2__font-weight--mobile: 700 !default;
$top-similar-shops-h2__font-size: 18px !default;
$top-similar-shops-h2__font-size--mobile: 14px !default;
$top-similar-shops-h2__line-height: 26px !default;
$top-similar-shops-h2__line-height--mobile: 18px !default;
$top-similar-shops-row__padding: 0 15px !default;
$top-similar-shops-col__padding: 0 !default;
$top-similar-shops-col__padding--mobile: 0 !default;
$top-similar-shops-list-columns__padding: 0 !default;
$top-similar-shops-list-columns__font-size: 10px !default;
$top-similar-shops-list-columns__gap: 15px !default;
$top-similar-shops-list-columns-item__margin: 0 0 6px !default;
$top-similar-shops-list-columns-item__padding: 0 !default;
$top-similar-shops-list-columns-item__text-align: left !default;
$top-similar-shops-list-columns-item__bg-color: transparent !default;
$top-similar-shops-list-columns-item-a__padding: 2px 0 !default;
$top-similar-shops-list-columns-item-a__font-weight: 600 !default;
$top-similar-shops-list-columns-item-a__font-size: 18px !default;
$top-similar-shops-list-columns-item-a__line-height: 26px !default;

@mixin small-size-columns {
  @media #{$lg} {
    columns: 3;
  }

  @media #{$md} {
    columns: 2;
  }
}

.top-similar-shops {
  margin-bottom: 40px;

  @media #{$md} {
    margin-bottom: 20px;
  }

  &.row {
    display: block;
    flex-wrap: initial;
    padding: $top-similar-shops-row__padding;

    & > .col {
      padding: $top-similar-shops-col__padding;

      @media #{$xs} {
        padding: $top-similar-shops-col__padding--mobile;
      }
    }
  }

  h2 {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    @media #{$md} {
      font-weight: 500;
      font-size: 18px;
    }
  }
}

.shop-list-columns {
  gap: $top-similar-shops-list-columns__gap;
  padding: $top-similar-shops-list-columns__padding;
  font-size: $top-similar-shops-list-columns__font-size;
  list-style-type: none;

  &__item {
    margin: $top-similar-shops-list-columns-item__margin;
    text-align: $top-similar-shops-list-columns-item__text-align;

    @media #{$sm} {
      margin-bottom: 5px;
    }

    a {
      @include c4r-arrow-link($top: 0);

      display: inline-flex;
      align-items: center;
      width: 100%;
      height: fit-content;
      padding: $top-similar-shops-list-columns-item-a__padding;
      font-weight: $top-similar-shops-list-columns-item-a__font-weight;
      font-size: $top-similar-shops-list-columns-item-a__font-size;
      line-height: $top-similar-shops-list-columns-item-a__line-height;
      text-decoration: none;

      &:hover {
        text-decoration: underline 2px;
        text-underline-offset: 2px;
      }
    }
  }

  &-6 {
    columns: 6;

    @include small-size-columns;
  }

  &-5 {
    columns: 5;

    @include small-size-columns;
  }

  &-4 {
    columns: 4;

    @include small-size-columns;
  }

  &-3 {
    columns: 3;

    @media #{$md} {
      columns: 2;
    }
  }

  &-2 {
    columns: 2;
  }
}
