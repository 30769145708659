@import "shop-filter";
@import "./see-more-categories";

$category-index-title__margin: 30px 0 4px !default;
$category-index-title__margin--mobile: 10px 0 4px !default;
$category-index-title__font-weight: 800 !default;
$category-index-title__line-height: 76px !default;
$category-index-titles__align: center !default;
$category-index-titles__align--mobile: left !default;
$category-index-list__margin-top: 30px !default;
$category-index-list__margin-top--mobile: 20px !default;
$category-index-item__margin-bottom: 18px !default;
$category-index-link__padding: 34px 5px 14px !default;
$category-index-link__color: $color-black !default;
$category-index-link__font-size: 18px !default;
$category-index-link__align: center !default;
$category-index-link__decoration: none !default;
$category-index-link__decoration--hover: none !default;
$category-index-link__bg-color: $color-white !default;
$category-index-link__border: 1px solid $color-black !default;
$category-index-link__shadow: $su-box-shadow-light !default;
$category-index-link__shadow--hover: $su-box-shadow-neo-brutalism !default;
$category-index-icon__width: 64px !default;
$category-index-icon__height: 64px !default;
$category-index-icon__font-size: 31px !default;
$category-index-icon__border: 1px solid $color-black !default;
$category-index-icon__border-radius: 50% !default;
$category-index-name__height: 68px !default;
$category-index-name__font-weight: 500 !default;
$category-index-name__line-height: 1.1 !default;
$category-index-seo-text__padding: 15px !default;
$category-index-seo-text__border-radius: 4px !default;
$category-index-seo-text-p__margin: 0 0 0 66px !default;
$category-index-seo-text-p__font-size: 14px !default;
$category-index-seo-text-p__line-height: 22px !default;
$category-index-seo-icon__width: 48px !default;
$category-index-seo-icon__height: 48px !default;

.categories {
  $this: &;

  &.index {
    .page-title {
      margin: $category-index-title__margin--mobile;

      @media #{$min-lg} {
        margin: $category-index-title__margin;
        font-weight: $category-index-title__font-weight;
        line-height: $category-index-title__line-height;
      }
    }

    .page-title,
    .page-subtitle {
      text-align: $category-index-titles__align;

      @media #{$md} {
        text-align: $category-index-titles__align--mobile;
      }
    }
  }

  &.show {
    .page-title {
      @media #{$min-lg} {
        margin: 25px 0 10px;
        line-height: 90px;
      }
    }

    @media #{$md} {
      .sidebar {
        display: none;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: $category-index-list__margin-top;
    padding-left: 0;
    list-style-type: none;

    @media #{$md} {
      margin-top: $category-index-list__margin-top--mobile;
    }
  }

  &__item {
    margin-bottom: $category-index-item__margin-bottom;
  }

  &__link {
    display: block;
    padding: $category-index-link__padding;
    color: $category-index-link__color;
    font-size: $category-index-link__font-size;
    text-align: $category-index-link__align;
    text-decoration: $category-index-link__decoration;
    background-color: $category-index-link__bg-color;
    border: $category-index-link__border;
    border-radius: 4px;
    box-shadow: $category-index-link__shadow;

    &:hover {
      text-decoration: $category-index-link__decoration--hover;
      box-shadow: $category-index-link__shadow--hover;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $category-index-icon__width;
    height: $category-index-icon__height;
    margin: 0 auto;
    font-size: $category-index-icon__font-size;
    border: $category-index-icon__border;
    border-radius: $category-index-icon__border-radius;

    &::before {
      width: 40px;
      height: 40px;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $category-index-name__height;
    font-weight: $category-index-name__font-weight;
    line-height: $category-index-name__line-height;
  }

  &__seo-text {
    padding: $category-index-seo-text__padding;
    background-color: $color-ivory-mist;
    border-radius: $category-index-seo-text__border-radius;

    p {
      margin: $category-index-seo-text-p__margin;
      color: $color-black;
      font-size: $category-index-seo-text-p__font-size;
      line-height: $category-index-seo-text-p__line-height;

      @media #{$sm} {
        margin-left: 0;
      }
    }
  }

  &__seo-icon {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    @media #{$min-md} {
      float: left;
    }

    &::before {
      width: $category-index-seo-icon__width;
      height: $category-index-seo-icon__height;
      content: $icon-exclamation-mark;
    }
  }
}
